import TextPopup from "components/popups/TextPopup"
import React from "react"

const InputFieldWithButton = ({
  title,
  value,
  setValue,
  handler,
  handlerText,
  placeholderText,
  infoText,
  hideTitle
}:{
  title:string,
  value: string,
  setValue: any,
  handler: any,
  handlerText:string,
  placeholderText: string,
  infoText?: string
  hideTitle?: boolean
}) => {
  return (
    <div>
      <div className="flex justify-start items-center gap-10 mb-12">
        {!hideTitle && <div className="text-black dark:text-white text-16 sm:text-18">{title}</div>}
        {infoText && <TextPopup value={infoText}/>}
      </div>
      <div className="w-full grid grid-cols-[1fr,auto] gap-12 items-center">
        <div className="w-full h-48 flex items-center bg-grey-light-1 dark:bg-grey-border rounded-6 px-16">
          <input
            type="text"
            className="w-full placeholder-grey-light text-black dark:text-white text-16"
            value={value}
            placeholder={placeholderText}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <button
          onClick={() => handler()}
          className="w-full h-48 flex items-center xl:w-auto px-20 rounded-6 text-black dark:text-white text-16 bg-purple"
        >
          {handlerText}
        </button>
      </div>
    </div>
  )
}

export default InputFieldWithButton
