import React from "react"

const GenericButton = ({
    value,
    handler,
    text,
    color,
    fontSize,
    disabled,
}:{
    value: any
    handler: any
    text: string
    color?: string
    fontSize?: number
    disabled?: boolean
}) => {
  return (
    <button
        onClick={() => handler(value)}
        disabled={disabled}
        className={`w-full px-12 rounded-6 h-52 flex items-center justify-center text-black dark:text-white text-${fontSize || "16"} font-bold ${color || "bg-purple"} disabled:opacity-50`}
    >
        {text}
    </button>
  )
}

export default GenericButton
