import Modal from "components/modals/Modal"
import ModalClose from "components/modals/ModalClose"
import React from "react"
import { MdCheck, MdOutlineMarkEmailRead } from "react-icons/md"

const ModalSent = ({ open, handler }: { open: boolean; handler: any }) => {
  return (
    <>
      <Modal open={open} handler={handler}>
        <div className="bg-grey-light-3 dark:bg-grey-med rounded-10 p-24 grid grid-cols-1 gap-24 w-[450px] max-w-full relative">
          <div className="w-full flex justify-center">
            <div className="w-60 h-60 rounded-full bg-green-10 flex items-center justify-center">
              <MdOutlineMarkEmailRead className="text-green text-24" />
            </div>
          </div>
          <div className="w-full grid grid-cols-1 gap-12">
            <div className="w-full text-16 sm:text-18 text-black dark:text-white text-center">
              We&apos;ve sent you an email!
            </div>
            <div className="w-full text-14 text-grey-light text-center">
              If you change your password, you will be unable to withdraw for 48
              hours due to security reasons.
            </div>
          </div>
          <button className="w-full h-48 bg-green-10 rounded-6 flex items-center justify-center text-green text-14 sm:text-16 gap-6">
            <MdCheck className="text-18 text-green" />
            Request password reset
          </button>
          <ModalClose open={open} handler={handler} />
        </div>
      </Modal>
    </>
  )
}

export default ModalSent
