import ModalChanged from "components/modals/ModalChanged"
import ModalForgot from "components/modals/ModalForgot"
import Auth from "components/nav/Auth"
import React, { useState } from "react"
import Home from "views/Home"

const Reset = () => {
  const [openLogin, setOpenLogin] = useState(false)
  const [openRegister, setOpenRegister] = useState(false)

  const [openChanged, setOpenChanged] = useState(false)
  const [openForgot, setOpenForgot] = useState(true)

  return (
    <>
      <Home />
      <Auth
        openLogin={openLogin}
        setOpenLogin={setOpenLogin}
        openRegister={openRegister}
        setOpenRegister={setOpenRegister}
      />
      <ModalForgot
        open={openForgot}
        handler={setOpenForgot}
        handlerChanged={setOpenChanged}
      />
      <ModalChanged
        open={openChanged}
        handler={setOpenChanged}
        handlerLogin={setOpenLogin}
      />
    </>
  )
}

export default Reset
