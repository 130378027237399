import { ReactComponent as Star } from "assets/img/categories/star.svg"
import Bets from "components/bets/Bets"
import Footer from "components/footer/Footer"
import HomeSection from "components/home/HomeSection"
import Nav from "components/nav/Nav"
import WrapperPage from "components/wrapper/WrapperPage"
import { useProps } from "contexts/PropsContext"
import { debounce } from "lodash"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdSearch } from "react-icons/md"
import { useHistory } from "react-router-dom"

const Favourite = () => {
  const { t } = useTranslation(['landing'])
  const { authenticated } = useProps()
  const history = useHistory()

  const [search, setSearch] = useState("")

  const handleSearchInput = useCallback(
    (text) => setSearch(text),
    [],
  )

  const debouncedSearchHandler = useCallback(
    debounce(handleSearchInput, 800),
    [],
  )

  useEffect(() => {
  if (!authenticated) {
    history.push("/")
  }
  }, [])

  return (
    <>
      <Nav />
      <WrapperPage>
        <div className="w-full grid grid-cols-1 gap-32">
          <div className="w-full grid grid-cols-1 gap-16">
            <div className="w-full grid grid-cols-[auto,1fr] bg-grey-light-3 dark:bg-grey-med rounded-6">
              <button className="w-52 h-52 rounded-6 flex items-center justify-center group dark:hover:bg-grey-med-2">
                <MdSearch className="text-22 text-grey-light group-hover:text-black dark:group-hover:text-white" />
              </button>
              <input
                type="text"
                className="w-full pl-14 pr-24 placeholder-grey-light text-black dark:text-white text-16 font-bold"
                placeholder={t("search_game")}
                onChange={(e) => debouncedSearchHandler(e.target.value)}
              />
            </div>
          </div>
          <HomeSection
            title={t("favourite_games")}
            icon={<Star className="text-24 fill-current text-grey-light" />}
            search={search}
            category={"favourite"}
          />
          <div className="w-full mt-20">
            <Bets />
          </div>
        </div>
      </WrapperPage>
      <Footer />
    </>
  )
}

export default Favourite
