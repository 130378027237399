import { parseBalance } from "api/balance"
import { parseError } from "api/error"
import axios from "axios"
import Wrapper from "components/wrapper/Wrapper"
import { useProps } from "contexts/PropsContext"
import { DATE, REQ } from "libs/constants"
import { betCategories, tableCount } from "libs/data"
import { BetCategoryType } from "libs/enums"
import { onSortBets } from "libs/functions"
import {
  BetCategoryInterface,
  BetInterface,
  TableCountInterface
} from "libs/interfaces"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaBtc } from "react-icons/fa"
import { MdKeyboardArrowDown } from "react-icons/md"
import { SiEthereum, SiLitecoin } from "react-icons/si"
import NumberFormat from "react-number-format"

const Bets = () => {
  const { fiat, socket, user } = useProps()
  const { t } = useTranslation(['landing'])

  const [loading, setLoading] = useState<boolean>(true)
  const [bets, setBets] = useState<BetInterface[]>([])
  const [betsHigh, setBetsHigh] = useState<BetInterface[]>([])
  const [myBets, setMyBets] = useState<BetInterface[]>([])
  const [betsFilter, setBetsFilter] = useState<BetInterface[]>([])

  const [open, setOpen] = useState(false)

  const [active, setActive] = useState(BetCategoryType.All)
  const [count, setCount] = useState(10)

  const [categories] = useState<BetCategoryInterface[]>(betCategories)

  const [countCategories] = useState<TableCountInterface[]>(tableCount)

  const [mounted, setMounted] = useState(true)

  const parseBet = (res: any) => {
    return {
      ...res,
      crypto_amount: parseBalance(parseInt(res.crypto_amount)),
      fiat_amount: parseInt(res.fiat_amount) / 100,
      win_crypto_amount: parseBalance(parseInt(res.win_crypto_amount)),
      win_fiat_amount: parseInt(res.win_fiat_amount) / 100
    }
  }

  useEffect(() => {
    setMounted(true)
    onLoad()

    return () => {
      setMounted(false)
    }
  }, [])

  useEffect(() => {
    socket.on("bet.new", (res: any) => {
      const newBet = parseBet(res)
      bets.push(newBet)
      setBets([...onSortBets(bets)].slice(0, 20))
      if (newBet.highRoller) {
        betsHigh.push(newBet)
        setBetsHigh([...onSortBets(betsHigh)].slice(0, 20))
      }
      if (newBet.user_id === user?.id) {
        myBets.push(newBet)
        setMyBets([...onSortBets(myBets)].slice(0, 20))
      }
    })

    return () => {
      if (socket) {
        socket.off("bet.new")
      }
    }
  }, [loading, socket])

  useEffect(() => {
    switch (active) {
      case BetCategoryType.All:
        setBetsFilter(bets)
        break
      case BetCategoryType.High:
        setBetsFilter(betsHigh)
        break
      case BetCategoryType.My:
        setBetsFilter(myBets)
        break
      default:
        break
    }
  }, [bets, active])

  const onLoad = async () => {
    const res = await axios({
      url: REQ.SITE.BETS,
      method: "get",
      data: {},
      withCredentials: true
    })
      .then((res: any) => res.data.response)
      .catch((error) => {
        parseError(error)
      })

    if (mounted && res) {
      const typedAll: BetInterface[] = res.allBets.map((bet: any) =>
        parseBet(bet)
      )
      const sortedAll = onSortBets(typedAll)

      const sortedMyBets = sortedAll.filter(b => b.user_id === user?.id)

      setBets(sortedAll)
      setMyBets(sortedMyBets)

      const typedHigh: BetInterface[] = res.highRollerBets.map((bet: any) =>
        parseBet(bet)
      )
      const sortedHigh = onSortBets(typedHigh)

      setBetsHigh(sortedHigh)
    }

    setLoading(false)
  }

  return (
    <>
      <div className="w-full grid grid-cols-1 gap-24">
        <div className="w-full grid md:flex grid-cols-1 items-center justify-between gap-24">
          <div className="grid md:flex grid-cols-1 sm:grid-cols-3 items-center gap-12">
            {categories.map((category: BetCategoryInterface, key: number) => (
              <button
                onClick={() => setActive(category.type)}
                className={`px-16 text-16 h-48 rounded-6 transition-all duration-300 ${
                  active === category.type
                    ? "text-black dark:text-white bg-grey-light-2 dark:bg-grey-med-2 font-black"
                    : "bg-grey-light-3 dark:bg-grey-med text-grey-light font-bold"
                }`}
                key={key}
              >
                {t(category.title)}
              </button>
            ))}
          </div>
          <div className="relative flex justify-end">
            <button
              onClick={() => setOpen(!open)}
              className="px-16 h-48 flex gap-12 items-center rounded-6 transition-all group duration-300 bg-grey-light-3 dark:bg-grey-med dark:hover:bg-grey-med-2"
            >
              <div className="text-grey-light font-bold group-hover:text-black dark:group-hover:text-white text-16">
                {count}
              </div>
              <MdKeyboardArrowDown className="text-grey-light group-hover:text-black dark:group-hover:text-white" />
            </button>
            <Wrapper open={open}>
              <div
                onClick={() => setOpen(!open)}
                className="fixed top-0 left-0 w-screen h-[100vh]"
              ></div>
              <div className="absolute w-full grid grid-cols-1 gap-4 top-[60px] left-0 rounded-6 bg-grey-light-3 dark:bg-grey-med py-8">
                {countCategories.map(
                  (category: TableCountInterface, key: number) => (
                    <button
                      onClick={() => {
                        setCount(category.count)
                        setOpen(!open)
                      }}
                      className="text-grey-light hover:text-black dark:hover:text-white text-16 py-2 px-16 text-left"
                      key={key}
                    >
                      {category.title}
                    </button>
                  )
                )}
              </div>
            </Wrapper>
          </div>
        </div>
        <div className="w-full overflow-x-scroll">
          <div className="w-full grid grid-cols-1 gap-16">
            {betsFilter.length > 0 ? (
              <>
                <div className="w-max min-w-full px-18 flex items-center justify-between">
                  <div className="w-[200px] text-grey text-14">{t("game")}</div>
                  <div className="w-[120px] text-grey text-14">{t("user")}</div>
                  <div className="w-[140px] text-grey text-14">{t("time")}</div>
                  <div className="w-[120px] text-grey text-14">{t("bet")}</div>
                  <div className="w-[100px] text-grey text-14">{t("multiplier")}</div>
                  <div className="w-[100px] text-grey text-right text-14">
                    {t("payout")}
                  </div>
                </div>

                <div className="w-full">
                  {betsFilter
                    .filter((bet: BetInterface, key: number) => key < count)
                    .map((bet: BetInterface, key: number) => (
                      <div
                        className={`w-max min-w-full justify-between px-18 h-48 items-center rounded-6 flex gap-24 ${
                          key % 2 === 0 ? "bg-grey-light-3 dark:bg-grey-med" : ""
                        }`}
                        key={key}
                      >
                        <div className="w-[200px] flex items-center gap-14 overflow-ellipsis truncate">
                          <div className="text-black dark:text-white text-14">
                            {bet.game_name}
                          </div>
                        </div>
                        <div className="w-[120px] text-black dark:text-white text-14 overflow-ellipsis truncate">
                          {bet.user && bet.user.display_name
                            ? bet.user.display_name
                            : "Anonymous user"}
                        </div>
                        <div className="w-[140px] text-grey-light text-14 overflow-ellipsis truncate">
                          {moment(bet.updated_at).format(DATE.DATETIME)}
                        </div>
                        <div className="w-[120px] flex items-center gap-14 overflow-ellipsis truncate">
                          <Wrapper open={!fiat}>
                            {
                              {
                                ["ETH"]: (
                                  <SiEthereum className="text-purple text-16" />
                                ),
                                ["BTC"]: (
                                  <FaBtc className="text-orange-2 text-16" />
                                ),
                                ["LTC"]: (
                                  <SiLitecoin className="text-grey-light text-16" />
                                )
                              }[bet.coin]
                            }
                          </Wrapper>
                          <NumberFormat
                            value={fiat ? bet.fiat_amount : bet.crypto_amount}
                            prefix={fiat ? "$" : ""}
                            displayType={"text"}
                            thousandSeparator={true}
                            className="text-grey-light text-14"
                          />
                        </div>
                        <div className="w-[100px] text-grey-light text-14 overflow-ellipsis truncate">
                          {Math.round(
                            (bet.win_crypto_amount / bet.crypto_amount) * 100
                          ) / 100}
                          x
                        </div>
                        <div
                          className={`w-[100px] text-14 overflow-ellipsis truncate text-right ${
                            bet.win_crypto_amount > 0
                              ? "text-green"
                              : "text-grey-light"
                          }`}
                        >
                          {bet.win_crypto_amount > 0 ? "+" : ""}
                          {fiat
                            ? `$${bet.win_fiat_amount}`
                            : Number(bet.win_crypto_amount).toLocaleString('de-DE', { useGrouping: false, maximumFractionDigits: 20 })}
                        </div>
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <div className="w-max min-w-full rounded-6 h-48 flex items-center justify-center text-black dark:text-white text-14 bg-grey-light-3 dark:bg-grey-med">
                {t("no_bets")}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Bets
