import AccountTransactions from "components/account/elements/AccountTransactions"
import React from "react"

const AccountWithdrawals = () => {
  return (
    <>
      <AccountTransactions type={"WITHDRAWAL"} />
    </>
  )
}

export default AccountWithdrawals
