import { parseError } from "api/error"
import Reward from "assets/img/reward.png"
import axios from "axios"
import { REQ } from "libs/constants"
import React, { useState } from "react"
import { toast } from "react-toastify"
import InputForm from "utils/inputs/InputForm"

const RewardsPromoCode = () => {
  const [code, setCode] = useState("")

  const onEnter = (key: string) => {
    if (key === "Enter") {
      onSubmit()
    }
  }

  const onSubmit = async () => {
    if (!code) {
      toast.error("Enter a promo code")
      return
    }

    axios({
      url: REQ.PROMO.USE,
      method: "post",
      data: { code },
      withCredentials: true
    })
      .then((res: any) => res.data.response.payload)
      .then(() => toast.success("Successfully used promo code"))
      .catch((error) => {
        parseError(error)
      })
  }

  return (
    <>
      <div className="w-full mb-12 text-16 sm:text-18 text-black dark:text-white">
        Promo Code
      </div>
      <div className="mb-30 w-full text-14 text-grey-light">
        From time to time we give away limited Promo Codes, that you can use to
        get balance or other great stuff. Check regualy our Twitter, Instagram,
        Discord and Telegram out. You have to be fast to have a chance!
      </div>
      <img src={Reward} alt="" className="w-full mb-30" />
      <div className="w-full mb-24">
        <InputForm
          title={"Promo code"}
          placeholder={"Enter promo code"}
          value={code}
          handler={setCode}
          onEnter={onEnter}
        />
      </div>
      <button
        onClick={() => onSubmit()}
        className="w-full bg-purple rounded-6 h-52 flex items-center justify-center text-black dark:text-white text-16 font-bold"
      >
        Submit
      </button>
    </>
  )
}

export default RewardsPromoCode
