import ModalLogin from "components/modals/ModalLogin"
import ModalRegister from "components/modals/ModalRegister"
import ModalReset from "components/modals/ModalReset"
import ModalSent from "components/modals/ModalSent"
import React, { useState } from "react"

const Auth = ({
  openLogin,
  setOpenLogin,
  openRegister,
  setOpenRegister,
  affiliateCode
}: {
  openLogin: boolean
  setOpenLogin: any
  openRegister: boolean
  setOpenRegister: any
  affiliateCode?: string | null
}) => {
  const [openReset, setOpenReset] = useState(false)
  const [openSent, setOpenSent] = useState(false)

  return (
    <>
      <ModalLogin
        open={openLogin}
        handler={setOpenLogin}
        handlerReset={setOpenReset}
      />
      <ModalRegister open={openRegister} handler={setOpenRegister} affiliateCode={affiliateCode} />
      <ModalReset
        open={openReset}
        handler={setOpenReset}
        handlerSent={setOpenSent}
      />
      <ModalSent open={openSent} handler={setOpenSent} />
    </>
  )
}

export default Auth
