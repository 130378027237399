import { onGetGames } from "api/gamesApi"
import HomeSectionTop from "components/home/HomeSectionTop"
import { GameInterface } from "libs/interfaces"
import React, { useEffect, useState } from "react"

import HomeSectionGrid from "./HomeSectionGrid"

const HomeSection = ({
  title,
  category,
  icon,
  provider,
  search,
  sizeValue,
  isLive,
  isGameShow,
  setGameResults
}: {
  title: string
  category?: string
  icon: JSX.Element
  provider?: string
  search?: string
  sizeValue?: number
  isLive?: boolean
  isGameShow?: boolean,
  setGameResults?: any
}) => {
  const [moreGames, setMoreGames] = useState(false)

  const [games, setGames] = useState<GameInterface[]>([])
  const [_, setTotalGames] = useState(0)

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [pageSize, setPageSize] = useState(sizeValue || 6)

  useEffect(() => {
    onLoadGames(1, pageSize)
  }, [category, provider, search])

  const onLoadGames = async (pageValue: number, pageSizeValue: number) => {
    const response = await onGetGames(pageValue, pageSizeValue, category, provider, search, isLive, isGameShow)
    setPageSize(pageSizeValue)
    if (response) {
      setPage(response.page)
      setGames(response.games)
      setTotalPages(response.total_pages)
      setTotalGames(response.total)
      setGameResults && setGameResults(response.total)
    } else {
      setPage(1)
      setGames([])
      setTotalPages(0)
      setTotalGames(0)
      setGameResults && setGameResults(0)
    }
  }

  const onShowMoreGames = (value: boolean) => {
    setMoreGames(value)
    onLoadGames(1, value ? 24 : sizeValue || 6)
  }

  const onPrevious = () => {
    onLoadGames(page > 1 ? page - 1 : 1, pageSize)
  }

  const onNext = () => {
    onLoadGames(page < totalPages ? page + 1 : totalPages, pageSize)
  }

  return (
    <div className="w-full grid grid-cols-1 gap-24">
      <HomeSectionTop
        title={title}
        icon={icon}
        moreGames={moreGames}
        slides={totalPages}
        handleMoreGames={onShowMoreGames}
        handlerNext={onNext}
        handlerPrevious={onPrevious}
      />
      <HomeSectionGrid
        games={games}
      />
    </div>
  )
}

export default HomeSection
