import { URL } from "libs/constants"
import React from "react"
import { Route } from "react-router-dom"
import AboutUs from "views/AboutUs"

export default [
  {
    component: (
      <Route path={URL.ABOUTUS.TAB} key={0}>
        <AboutUs />
      </Route>
    )
  },
]
