import { onGetGames } from "api/gamesApi"
import Bets from "components/bets/Bets"
import HomeCategory from "components/home/HomeCategory"
import HomeSectionGrid from "components/home/HomeSectionGrid"
import ProvidersPopup from "components/popups/ProvidersPopup"
import Wrapper from "components/wrapper/Wrapper"
import {
  GameInterface,
} from "libs/interfaces"
import { debounce } from "lodash"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdSearch } from "react-icons/md"
import { useParams } from "react-router-dom"

interface Params {
  tab: string
}

const HomeTab = () => {
  const { tab } = useParams<Params>()
  const { t } = useTranslation(['landing'])

  const [providers, setProviders] = useState<string []>([])

  const [games, setGames] = useState<GameInterface[]>([])
  const [totalGames, setTotalGames] = useState(0)
  const [page, setPage] = useState(1)

  const [pageSize, setPageSize] = useState(12)
  const [totalPages, setTotalPages] = useState(0)

  const [search, setSearch] = useState("")


  useEffect(() => {
    setProviders([])
    setSearch("")
    setPageSize(12)
    onLoad()

    return () => {
      debouncedSearchHandler.cancel()
    }
  }, [tab])

  useEffect(() => {
    onLoad()

    return () => {
      debouncedSearchHandler.cancel()
    }
  }, [providers, search])

  const onLoad = async () => {
    const response = await onGetGames(1, pageSize, tab, providers.join(", "), search)
    if (response) {
      setPage(response.page)
      setGames(response.games)
      setTotalPages(response.total_pages)
      setTotalGames(response.total)
    }
  }

  const onNextPage = async () => {
    const response = await onGetGames(page + 1, pageSize, tab, providers.join(", "), search)
    if (response) {
      setGames([
        ...games,
        ...response.games
      ])
      setPage(page + 1)
      setTotalPages(response.total_pages)
      setTotalGames(response.total)
    }
  }

  const handleSearchInput = useCallback(
    (text) => setSearch(text),
    [],
  )

  const debouncedSearchHandler = useCallback(
    debounce(handleSearchInput, 800),
    [],
  )

  return (
    <>
      <HomeCategory tab={tab} />
      <div className="w-full grid grid-cols-1 gap-16">
        <div className="w-full grid grid-cols-[auto,1fr] bg-grey-light-3 dark:bg-grey-med rounded-6">
          <button className="w-52 h-52 rounded-6 flex items-center justify-center group dark:hover:bg-grey-med-2">
            <MdSearch className="text-22 text-grey-light group-hover:text-black dark:group-hover:text-white" />
          </button>
          <input
            type="text"
            className="w-full pl-14 pr-24 placeholder-grey-light text-black dark:text-white text-16 font-bold"
            placeholder={t("search_game")}
            onChange={(e) => debouncedSearchHandler(e.target.value)}
          />
        </div>
        <div className="w-full flex items-center justify-between gap-12">
          <div className="flex items-center gap-12">
            <div className="text-grey text-14">{t("filter_by")}</div>
            <ProvidersPopup providers={providers} setProviders={setProviders}/>
          </div>
        </div>
      </div>
      <HomeSectionGrid
        games={games}
      />
      <div className="w-full flex justify-center">
        <div className="w-[200px] grid grid-cols-1 gap-16">
          <div className="w-full grid grid-cols-1 gap-4">
            <div className="w-full flex items-center justify-between">
              <div className="text-grey-light text-14">
                {Math.min(games.length, page * 12)}{" "}
                <span className="text-14 text-grey">/ {totalGames}</span>
              </div>
              <div className="text-grey-light text-14">
                {totalGames &&
                  Math.round(
                    (Math.min(games.length, page * 12) / totalGames)
                    * 10000
                  ) / 100}
                %
              </div>
            </div>
            <div className="w-[200px] h-4 bg-grey-light-1 dark:bg-grey-border">
              <div
                style={{
                  width: `${
                    (totalGames && Math.min(totalGames, page * 12) / totalGames) * 100
                  }%`
                }}
                className="h-4 bg-grey-light-0 transition-all duration-300"
              ></div>
            </div>
          </div>
          <Wrapper open={page < totalPages}>
            <div className="w-full flex justify-center">
              <button
                onClick={() => onNextPage()}
                className="bg-grey-light-2 dark:bg-grey-med-2 flex items-center text-grey-light h-48 px-16 rounded-6 hover:text-black dark:hover:text-white font-semibold text-16"
              >
                {t('load_more')}
              </button>
            </div>
          </Wrapper>
        </div>
      </div>
      <div className="w-full mt-20">
        <Bets />
      </div>
    </>
  )
}

export default HomeTab
