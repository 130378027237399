import React from "react"
import { GoScreenFull, GoScreenNormal } from "react-icons/go"

const CinemaModeButton = ({
    value,
    handler
}:{
    value: boolean
    handler: any
}) => {
  return (
    <button
        onClick={() => handler(!value)}
        className="h-28 w-28 flex items-center justify-center group"
    >
        {value ? (
            <GoScreenNormal className="text-24 text-purple" />
        ) : (
            <GoScreenFull className="text-24 text-grey-light group-hover:text-black dark:group-hover:text-white" />
        )}
    </button>
  )
}

export default CinemaModeButton
