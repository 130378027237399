import AccountTitle from "components/account/elements/AccountTitle"
import ProvablyText from "components/provably/elements/ProvablyText"
import React from "react"

const ProvablyOverview = () => {
  return (
    <>
      <div className="w-full">
        <AccountTitle title={"Provably Fair"} />
        <ProvablyText
          text={
            <>
              We employ a provably fair system to prove that all game results on
              our website are random and verifiably fair. We encourage every
              user to verify their games for themselves wherever they may play.
              Any questions or concerns, please contact one of our staff
              members.
            </>
          }
        />
      </div>
    </>
  )
}

export default ProvablyOverview
