import { parseError } from 'api/error'
import axios from 'axios'
import { useProps } from 'contexts/PropsContext'
import { AVATAR_MAX_SIZE, FILE_TOO_LARGE_CODE, REQ } from 'libs/constants'
import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const ImageDrop = () => {
  const { user, onGetUser } = useProps()
  const { t } = useTranslation(['landing'])

  const getFormData = (fileData: File): FormData => {
    const formData = new FormData()
    formData.append('file', fileData)
    return formData
  }

  const postFileData = (fileUrl: string, formData: FormData) => {
    return axios.post(fileUrl, formData, { withCredentials: true })
  }

  const handleUpload = useCallback(
    async (fileData: File) => {
      const formData = getFormData(fileData)
      try {
        const fileUrl = `${REQ.UPLOAD.AVATAR}/?key=avatar`
        await postFileData(fileUrl, formData)
        toast.success(t('success_avatar'))

        await onGetUser()
      } catch (e) {
        parseError(e)
      }
    }, [])

  const onDrop = useCallback(
    async (acceptedFiles, fileRejections) => {
      const rejectedFile = fileRejections.pop()
      if (rejectedFile) {
        const message = rejectedFile.errors?.[0]?.code === FILE_TOO_LARGE_CODE ? t('error_file_too_large') : rejectedFile.errors?.[0]?.message
        toast.error(message)
        return
      }

      const file: File = acceptedFiles.pop()

      await handleUpload(file)
    },
    [handleUpload]
  )

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: ['image/png', 'image/jpeg'],
    multiple: false,
    maxSize: AVATAR_MAX_SIZE,
  })

  return (
    <div className="rounded-full" {...getRootProps()}>
      <input {...getInputProps()} />
      {
        user?.avatar_url ?
          <label className='cursor-pointer'>
            <img src={user?.avatar_url} className="h-72 w-80 rounded-full" />
          </label>
          :
          <div className="h-72 w-72 rounded-full cursor-pointer bg-grey-light-0 flex items-center justify-center text-black dark:text-white text-32 font-bold uppercase">
            {user?.username && user.username[0]}
          </div>
      }
    </div>
  )
}

export default ImageDrop
