import { onAffiliateUse, onAuthSocial, onChangeDiscordName, onIntegrateLooksRare } from "api/user"
import AccountButtons from "components/account/elements/AccountButtons"
import AccountLine from "components/account/elements/AccountLine"
import AccountText from "components/account/elements/AccountText"
import AccountTitle from "components/account/elements/AccountTitle"
import InputFieldWithButton from "components/input/InputFieldWithButton"
import { useProps } from "contexts/PropsContext"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { FaGoogle, FaSteam } from "react-icons/fa"
import { toast } from "react-toastify"

import AccountInput from "./elements/AccountInput"
import ImageDrop from "./elements/ImageDrop"

const AccountGeneral = () => {
  const { user, onGetUser } = useProps()
  const { t } = useTranslation(['landing'])

  const [looksrareToken, setLooksrareToken] = useState("")
  const [code, setCode] = useState("")
  const [referrer, setReferrer] = useState("")
  const [discordName, setDiscordName] = useState(user?.discord_name || "")

  const [google] = useState(false)
  const [steam] = useState(false)

  const onCode = async () => {
    if (!code) {
      toast.error(t("enter_referral_code"))
      return
    }

    const response = await onAffiliateUse(code)

    if (response) {
      setReferrer(code)
      toast.success(t("successfully_claimed_referral_code"))
    }
  }

  const onLooksrareToken = async () => {
    if (!looksrareToken) {
      toast.error(t('no_token_id'))
      return
    }

    const response = await onIntegrateLooksRare(looksrareToken)
    if (response) {
      await onGetUser()
      toast.success(t('successful_looksrare_integration'))
    }
  }

  const onDiscordNameChange = async () => {
    const response = await onChangeDiscordName(discordName)

    if (response) {
      toast.success(t('successful_discord_name_change'))
    }
  }

  const onLink = async (provider: string) => {
    if (!provider || !user) {
      toast.error(t("please_try_again"))
      return
    }

    const response = await onAuthSocial(user?.email_address, provider)
    if (response) {
    }
  }

  return (
    <>
      <div className="w-full">
        <div>
          <AccountTitle title={"Profile"} />
          <div className="w-full flex items-center gap-10">
            <ImageDrop />
            <AccountInput
                title={t("username")}
                value={user?.display_name}
                handler={() => {}}
                readonly={true}
              />
          </div>
        </div>
        <div className="w-full grid grid-cols-1 gap-10">
          <InputFieldWithButton
            title={t("looksrare_avatar")}
            value={looksrareToken}
            setValue={setLooksrareToken}
            handler={onLooksrareToken}
            handlerText={t("integrate")}
            placeholderText={t("token_id")}
          />
          <InputFieldWithButton
            title={t("discord_name")}
            value={discordName}
            setValue={setDiscordName}
            handler={onDiscordNameChange}
            handlerText={t("save")}
            placeholderText={t("discord_name")}
            infoText={t("info_discord_name")}
          />
        </div>
        <AccountLine />
        <AccountTitle title={t("referrer")} />
        {referrer ? (
          <div className="w-full rounded-6 h-48 text-16 bg-grey-light-2 dark:bg-grey-med-2 text-grey-light flex items-center px-16 truncate overflow-ellipsis gap-4">
            {t("referred_by")}
            <span className="text-16 text-black dark:text-white">{referrer}</span>
          </div>
        ) : (
          <InputFieldWithButton
            title={t("referrer")}
            value={code}
            setValue={setCode}
            handler={onCode}
            handlerText={t("use_code")}
            placeholderText={t("referral_code")}
            hideTitle
          />
        )}
        <div className="hidden">
          <AccountLine />
          <AccountTitle title={t("alternate_login_methods")} />
          <AccountText
            text={t("login_even_faster")}
          />
          <AccountButtons>
            <button
              onClick={() => onLink("google")}
              className="w-full items-center xl:w-auto rounded-6 px-18 py-10 bg-grey-light-2 dark:bg-grey-med-2 grid grid-cols-[auto,auto,1fr] gap-18"
            >
              <FaGoogle className="text-grey-light text-20" />
              <div className="h-32 border-l-1 border-grey-light opacity-10"></div>
              <div className="grid grid-cols-1">
                <div className="text-black dark:text-white text-left text-16">
                  Google {google ? "linked" : ""}
                </div>
                <div className="text-grey-light text-14 text-left">
                  {google ? t("unlink_account") : t("link_google_account")}
                </div>
              </div>
            </button>
            <button
              onClick={() => onLink("steam")}
              className="w-full items-center xl:w-auto rounded-6 px-18 py-10 bg-grey-light-2 dark:bg-grey-med-2 grid grid-cols-[auto,auto,1fr] gap-18"
            >
              <FaSteam className="text-grey-light text-20" />
              <div className="h-32 border-l-1 border-grey-light opacity-10"></div>
              <div className="grid grid-cols-1">
                <div className="text-black dark:text-white text-left text-16">
                  Steam {steam ? "linked" : ""}
                </div>
                <div className="text-grey-light text-14 text-left">
                  {steam ? t("unlink_account") : t("link_steam_account")}
                </div>
              </div>
            </button>
          </AccountButtons>
        </div>
      </div>
    </>
  )
}

export default AccountGeneral
