import { parseError } from "api/error"
import axios from "axios"
import ModalClose from "components/modals/ModalClose"
import Wrapper from "components/wrapper/Wrapper"
import { useProps } from "contexts/PropsContext"
import { REQ } from "libs/constants"
import { authTabs } from "libs/data"
import { AuthType } from "libs/enums"
import { AuthTabInterface } from "libs/interfaces"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdCheck } from "react-icons/md"
import { toast } from "react-toastify"
import InputForm from "utils/inputs/InputForm"
import InputFormDropdown from "utils/inputs/InputFormDropdown"
import InputFormPassword from "utils/inputs/InputFormPassword"

const ModalAuth = ({
  type,
  open,
  handler,
  handlerReset,
  affiliateCode
}: {
  type: AuthType
  open: boolean
  handler: any
  handlerReset?: any,
  affiliateCode?: string | null
}) => {
  const { setLoading, setOpenLogin } = useProps()

  const { t } = useTranslation(['landing'])

  const [side, setSide] = useState(type)

  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")
  const [affiliate, setAffiliateCode] = useState(affiliateCode || "")
  const [username, setUsername] = useState("")

  const [terms, setTerms] = useState(false)

  const [resend, setResend] = useState(false)

  const [mfa, setMfa] = useState(false)
  const [code, setCode] = useState("")

  const [tabs] = useState<AuthTabInterface[]>(authTabs)

  useEffect(() => {
    setTerms(false)
    setResend(false)
  }, [side])

  const onEnter = (key: string) => {
    if (key === "Enter") {
      if (type === AuthType.Login) {
        mfa ? onMFA() : onLogin()
      } else if (type === AuthType.Register) {
        onRegister()
      }
    }
  }

  const onLogin = () => {
    if (!name) {
      toast.error(t("enter_email_or_username"))
      return
    }

    if (!password) {
      toast.error(t("enter_a_password"))
      return
    }

    axios({
      url: REQ.AUTH.LOGIN,
      method: "post",
      data: { username: name, password: password },
      withCredentials: true
    })
      .then((res: any) => res.data)
      .then((res) => {
        const mfa = res.response.payload.mfa

        if (mfa) {
          setMfa(true)
          toast.success(t("enter_2fa_code_continue"))
        } else {
          toast.success(t("success_login"))

          setLoading(true)
          setOpenLogin(false)
        }
      })
      .catch((error) => {
        parseError(error)

        if (error?.response?.message === "Account not activated") {
          setResend(true)
        }
      })
  }

  const onMFA = () => {
    if (!code) {
      toast.error(t("enter_2fa_code"))
      return
    }

    axios({
      url: REQ.AUTH.MFA,
      method: "post",
      data: { code: code },
      withCredentials: true
    })
      .then((res: any) => res.data)
      .then(() => {
        toast.success(t("success_login"))
        setLoading(true)
        setOpenLogin(false)
      })
      .catch((error) => {
        parseError(error)
      })
  }

  const onResend = () => {
    if (!name) {
      toast.error(t("enter_email_or_username"))
      return
    }

    axios({
      url: REQ.AUTH.RESEND,
      method: "post",
      data: { username: name },
      withCredentials: true
    })
      .then((res: any) => res.data)
      .then(() => {
        toast.success(t("success_resent_activation_email"))
      })
      .catch((error) => {
        parseError(error)
      })
  }

  const onRegister = () => {
    if (!username) {
      toast.error(t("enter_username_to_register"))
      return
    }

    if (!email) {
      toast.error(t("enter_email_to_register"))
      return
    }

    if (!password || !passwordConfirm) {
      toast.error(t("enter_confirm_password"))
      return
    }

    if (password !== passwordConfirm) {
      toast.error(t("both_passwords_same"))
      return
    }

    if (!terms) {
      toast.error(t("agree_terms"))
      return
    }

    axios({
      url: REQ.AUTH.REGISTER,
      method: "post",
      data: {
        username,
        email,
        password,
        passwordr: passwordConfirm,
        affiliate
      },
      withCredentials: true
    })
      .then((res: any) => res.data)
      .then(() => {
        toast.success(t("success_register"))

        setUsername("")
        setEmail("")
        setPassword("")
        setPasswordConfirm("")

        setSide(AuthType.Login)
      })
      .catch((error) => {
        parseError(error)
      })
  }

  const onReset = () => {
    handler(false)
    handlerReset(true)
  }

  return (
    <>
      <div className="w-[600px] max-w-full rounded-10 overflow-hidden bg-grey-light-3 dark:bg-grey-med grid grid-cols-1">
        <div className="w-full grid grid-cols-1">
          <div className="w-full p-24 grid grid-cols-1 gap-18">
            <div className="w-full border-b-1 border-grey-border flex justify-start gap-24">
              {tabs.map((tab: AuthTabInterface, key: number) => (
                <button
                  onClick={() => setSide(tab.type)}
                  className={`pb-14 border-b-2 text-16 font-bold hover:text-black dark:hover:text-white ${
                    side === tab.type
                      ? "border-purple text-black dark:text-white"
                      : "border-transparent text-grey-light"
                  }`}
                  key={key}
                >
                  {t(tab.title)}
                </button>
              ))}
            </div>
            <Wrapper open={mfa}>
              <InputForm
                title={t("2fa_code")}
                placeholder={t("enter_2fa_code")}
                value={code}
                handler={setCode}
                onEnter={onEnter}
              />
            </Wrapper>
            <Wrapper open={side === AuthType.Login && !mfa}>
              <InputForm
                title={t("email_or_username")}
                placeholder={t("enter_email_or_username")}
                value={name}
                handler={setName}
                onEnter={onEnter}
              />
            </Wrapper>
            <Wrapper open={side === AuthType.Register}>
              <InputForm
                title={t("username")}
                placeholder={t("enter_username")}
                value={username}
                handler={setUsername}
                onEnter={onEnter}
              />
              <InputForm
                title={t("email")}
                placeholder={t("enter_email")}
                value={email}
                handler={setEmail}
                onEnter={onEnter}
              />
            </Wrapper>
            <Wrapper open={!mfa}>
              <InputFormPassword
                title={t("password")}
                placeholder={t("enter_password")}
                value={password}
                handler={setPassword}
                handlerReset={onReset}
                password={side === AuthType.Login ? t("forgot_password") : ""}
                onEnter={onEnter}
              />
            </Wrapper>
            <Wrapper open={side === AuthType.Register}>
              <InputFormPassword
                title={t("confirm_password")}
                placeholder={t("enter_password_again")}
                value={passwordConfirm}
                handler={setPasswordConfirm}
                onEnter={onEnter}
              />
              <InputFormDropdown
                title={t("affiliate_code_optional")}
                placeholder={t("affiliate_code")}
                value={affiliate}
                handler={setAffiliateCode}
                onEnter={onEnter}
                initialState={false}
              />
            </Wrapper>
          </div>
          <div className="w-full p-24 border-t-1 border-grey-border grid grid-cols-1 gap-24">
            <Wrapper open={side === AuthType.Register}>
              <button
                onClick={() => setTerms(!terms)}
                className="w-full grid grid-cols-[auto,1fr] gap-24 items-start"
              >
                <div
                  className={`w-24 h-24 rounded-6 border-1 flex items-center justify-center ${
                    terms ? "border-purple bg-purple" : "border-grey-med-2"
                  }`}
                >
                  <Wrapper open={terms}>
                    <MdCheck className="text-16 text-black dark:text-white" />
                  </Wrapper>
                </div>
                <div
                  className={`w-full text-16 font-bold text-left leading-[22px] ${
                    terms ? "text-black dark:text-white" : "text-grey-light"
                  }`}
                >
                  {t("terms_and_conditions")}
                </div>
              </button>
            </Wrapper>
            <button
              onClick={() =>
                mfa
                  ? onMFA()
                  : side === AuthType.Login
                  ? onLogin()
                  : onRegister()
              }
              className="w-full h-48 rounded-6 bg-purple flex items-center justify-center text-black dark:text-white font-bold text-16"
            >
              {mfa
                ? "Login with 2FA"
                : side === AuthType.Login
                ? t("login")
                : t("register")}
            </button>
            <Wrapper open={side === AuthType.Login && resend}>
              <button
                onClick={() => onResend()}
                className="w-full text-16 font-bold text-grey-light hover:text-black dark:hover:text-white"
              >
                {t("resend_activation_email")}
              </button>
            </Wrapper>
          </div>
        </div>
        <ModalClose open={open} handler={handler} />
      </div>
    </>
  )
}

export default ModalAuth
