import { onGetGames } from "api/gamesApi"
import { GameInterface } from "libs/interfaces"
import React, { useEffect, useState } from "react"

import HomeSectionGame from "./HomeSectionGame"

const HomeSectionColumn = ({
  title,
  category,
  icon,
  sizeValue,
}: {
  title: string
  category?: string
  icon: JSX.Element
  sizeValue: number
}) => {
  const [games, setGames] = useState<GameInterface[]>([])

  const [pageSize, setPageSize] = useState(sizeValue)

  useEffect(() => {
    onLoadGames(1, pageSize)
  }, [])

  const onLoadGames = async (pageValue: number, pageSizeValue: number) => {
    const response = await onGetGames(pageValue, pageSizeValue, category)
    setPageSize(pageSizeValue)
    if (response) {
      setGames(response.games)
    }
  }

  return (
    <>
      {games.length > 0 ? (
        <div className="grid grid-cols-1 gap-12">
          <div className="w-full flex items-center gap-12 pb-12">
            {icon}
            <div className="text-16 text-black dark:text-white font-bold">{title}</div>
          </div>
          {games.map((game: GameInterface, key: number) => (
            <div className={"w-full"} key={key}>
              <HomeSectionGame game={game} small />
            </div>
          ))}
        </div>
      ) : ""}
    </>
  )
}

export default HomeSectionColumn
