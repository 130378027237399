export const clearStorage = () => {
  localStorage.clear()
}

export const removeStorage = (name: string) => {
  localStorage.removeItem(name)
}

export const getterFiat = () => {
  return localStorage.getItem("ga-fiat") === "true" || false
}

export const setterFiat = (bool: boolean) => {
  localStorage.setItem("ga-fiat", `${bool}`)

  return Promise.resolve()
}

export const getterMask = () => {
  return localStorage.getItem("ga-mask") === "true" || false
}

export const setterMask = (bool: boolean) => {
  localStorage.setItem("ga-mask", `${bool}`)

  return Promise.resolve()
}

export const getterCoin = () => {
  return localStorage.getItem("ga-coin") || ""
}

export const setterCoin = (token: string) => {
  localStorage.setItem("ga-coin", token)

  return Promise.resolve()
}

export const getterLanguage = () => {
  return localStorage.getItem("ga-language") || ""
}

export const setterLanguage = (token: string) => {
  localStorage.setItem("ga-language", token)

  return Promise.resolve()
}

export const setterRecent = (token: string) => {
  localStorage.setItem("ga-recent", token)

  return Promise.resolve()
}

export const setterFavourite = (token: string) => {
  localStorage.setItem("ga-favourite", token)

  return Promise.resolve()
}

export const getterDarkMode = () => {
  return localStorage.getItem("ga-darkmode") === null ? true : localStorage.getItem("ga-darkmode") === "true"
}

export const setterDarkMode = (bool: boolean) => {
  localStorage.setItem("ga-darkmode", `${bool}`)

  return Promise.resolve()
}

export const getterCinemaMode = () => {
  return localStorage.getItem("ga-cinemamode") === "true" || false
}

export const setterCinemaMode = (bool: boolean) => {
  localStorage.setItem("ga-cinemamode", `${bool}`)

  return Promise.resolve()
}
