import { useProps } from "contexts/PropsContext"
import { AccountTabInterface } from "libs/interfaces"
import React from "react"
import { Link } from "react-router-dom"

const AccountMenuLink = ({ link }: { link: AccountTabInterface }) => {
  const { path } = useProps()

  return (
    <>
      <Link
        to={link.link}
        className={`w-full px-20 h-48 items-center hover:bg-purple-5 sm:px-24 relative group grid grid-cols-[auto,1fr] gap-16 ${
          path === link.link ? "bg-purple-5" : ""
        }`}
      >
        <div
          className={`flex first:text-24 group-hover:first:text-purple ${
            path === link.link ? "first:text-purple" : "first:text-grey"
          }`}
        >
          {link.icon}
        </div>
        <div
          className={`w-full text-left group-hover:text-black dark:group-hover:text-white text-16 ${
            path === link.link ? "text-black dark:text-white" : "text-grey"
          }`}
        >
          {link.title}
        </div>

        <div
          className={`absolute right-0 top-0 bottom-0 w-2 bg-purple group-hover:opacity-100 ${
            path === link.link ? "opacity-100" : "opacity-0"
          }`}
        ></div>
      </Link>
    </>
  )
}

export default AccountMenuLink
