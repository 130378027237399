import { CryptoInterface } from "libs/interfaces"

export const parseBalance = (balance: number) => {
  return balance / 1e8
}

export const parseFiat = (
  crypto: CryptoInterface[],
  coin: string,
  fiat: boolean,
  balance: number
) => {
  let parsedFiat = 0

  if (coin) {
    const rate = crypto.filter((c) => c.coin === coin)[0]

    if (rate) {
      parsedFiat = Math.round(balance * rate.value * 100) / 100
    }
  }

  return fiat ? parsedFiat : balance
}
