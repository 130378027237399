import React, { useEffect } from "react"

const ModalBg = ({ handler, showNavigation }: { handler: any, showNavigation?: boolean }) => {
  useEffect(() => {
    const escHandler = (event: any) => {
      if (event.key === "Escape") {
        handler(!open)
      }
    }

    document.addEventListener("keydown", escHandler, false)

    return () => {
      document.removeEventListener("keydown", escHandler, false)
    }
  }, [])

  return (
    <>
      <button
        onClick={() => handler(false)}
        className={`bg-grey-dark fixed left-0 z-10 h-[calc(100vh+200px)] w-screen opacity-50 ${showNavigation ? "top-70" : "top-[-100px]"}`}
      ></button>
    </>
  )
}

export default ModalBg
