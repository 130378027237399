import { URL } from "libs/constants"
import { AboutUsInterface, SiteInterface } from "libs/interfaces"

import { AuthType, BetCategoryType } from "./enums"

export const siteSample: SiteInterface = {
  affiliate: { enabled: false, percentBack: 0 },
  crypto: {
    BTC: {
      enabled: false,
      depositEnabled: false,
      maxWithdraw: 0,
      minWithdraw: 0,
      withdrawEnabled: false
    },
    ETH: {
      enabled: false,
      depositEnabled: false,
      maxWithdraw: 0,
      minWithdraw: 0,
      withdrawEnabled: false
    }
  },
  gift: {
    enabled: false,
    maxSendAmount: 0,
    minSendAmount: 0,
    requiredReceiveLevel: 0,
    requireSendLevel: 0
  },
  main: { maintenance: false, freeCoinsAmount: 0 },
  rakeback: { enabled: false, percent: 0, withAffiliatePercent: 0 }
}

export const userSample = {
  id: 0,
  avatar: "",
  display_name: "",
  username: "",
  email_address: "",
  email_verified: false,
  phone_number: "",
  phone_number_verified: false,
  two_fa_code: "",
  use_two_fa: false,
  created_at: "",
  rakeback_balance: 0,
  rakeback_earned: 0,
  user_settings: {
    incognito: false
  }
}

export const walletSample = {
  balance: 0,
  coin: "",
  address: ""
}

export const notificationSample = [{
  title: "Deposit",
  text: "We've instant-credited your deposit of $248.22. Please note, you'll need to wait 1 confirmation to withdraw.",
  link: URL.HOME,
  date: "11:31 AM"
}]

export const betCategories = [
  { title: "all_bets", type: BetCategoryType.All },
  { title: "my_bets", type: BetCategoryType.My },
  { title: "high_rollers", type: BetCategoryType.High }
]

export const tableCount = [
  { title: "5", count: 5 },
  { title: "10", count: 10 },
  { title: "20", count: 20 }
]

export const authTabs = [
  { title: "login", type: AuthType.Login },
  { title: "register", type: AuthType.Register }
]

export const percentage = [
  { title: "25%", value: 25 },
  { title: "50%", value: 50 },
  { title: "100%", value: 100 }
]

export const transactionCoinFee = {
  ETH: "6.00$",
  BTC: "3.00$"
}

export const tableColumnsBets = [
  { title: "Game", width: 200 },
  { title: "Bet", width: 120 },
  { title: "Payout", width: 120 },
  { title: "Date", width: 140 }
]

export const tableColumnsTransactions = [
  { title: "Method", width: 200 },
  { title: "Amount", width: 120 },
  { title: "Status", width: 100 },
  { title: "Date", width: 140 }
]

export const tableSorting = [
  { title: "Game Asc", index: 0, asc: true },
  { title: "Game Desc", index: 0, asc: false },
  { title: "Bet Asc", index: 1, asc: true },
  { title: "Bet Desc", index: 1, asc: false },
  { title: "Payout Asc", index: 2, asc: true },
  { title: "Payout Desc", index: 2, asc: false },
  { title: "Date Asc", index: 3, asc: true },
  { title: "Date Desc", index: 3, asc: false }
]

export const privacyPolicy: AboutUsInterface = {
  title: "privacy_policy",
  data: [
    { title: "pp_general", text: "pp_general_text" },
    { title: "pp_information_collect", text: "pp_information_collect_text" },
    { title: "pp_use_information", text: "pp_use_information_text" },
    { title: "pp_marketing", text: "pp_marketing_text" },
    { title: "pp_personal_information", text: "pp_personal_information_text" },
    { title: "pp_data_recipients", text: "pp_data_recipients_text" },
    { title: "pp_releasing_data", text: "pp_releasing_data_text" },
    { title: "pp_data_retention", text: "pp_data_retention_text" },
    { title: "pp_security_data", text: "pp_security_data_text" },
    { title: "pp_contacting_us", text: "pp_contacting_us_text" },
    { title: "pp_cookie_privacy", text: "pp_cookie_privacy_text" }
  ]
}

export const selfExclusion: AboutUsInterface = {
  title: "self_exclusion",
  data: [
    { title: "", text: "se_general_text" },
    { title: "se_overview", text: "se_overview_text" },
    { title: "se_responsible_gambling", text: "se_responsible_gambling_text" },
    { title: "se_problem_gambling", text: "se_problem_gambling_text" },
    { title: "se_gambling_policy", text: "se_gambling_policy_text" },
    { title: "se_minors", text: "se_minors_text" },
    { title: "se_filtering_options", text: "se_filtering_options_text" },
    { title: "se_additional_help", text: "se_additional_help_text" },
    { title: "se_conditions_processes", text: "se_conditions_processes_text" }
  ]
}

export const termsOfService: AboutUsInterface = {
  title: "terms_of_service",
  data: [
    { title: "tos_general", text: "tos_general_text" },
    { title: "tos_accepted_players", text: "tos_accepted_players_text" },
    { title: "tos_fees_taxes", text: "tos_fees_taxes_text" },
    { title: "tos_game_rules", text: "tos_game_rules_text" },
    { title: "tos_disclaimer_liabilities", text: "tos_disclaimer_liabilities_text" },
    { title: "tos_player_account", text: "tos_player_account_text" },
    { title: "tos_depositing_data", text: "tos_depositing_data_text" },
    { title: "tos_withdrawing", text: "tos_withdrawing_text" },
    { title: "tos_refund", text: "tos_refund_text" },
    { title: "tos_anti_fraud", text: "tos_anti_fraud_text" },
    { title: "tos_dormant_accounts", text: "tos_dormant_accounts_text" },
    { title: "tos_cookie_security", text: "tos_cookie_security_text" },
    { title: "tos_responsible_gambling", text: "tos_responsible_gambling_text" },
    { title: "tos_personal_limits", text: "tos_personal_limits_text" },
    { title: "tos_self_exclusion", text: "tos_self_exclusion_text" },
    { title: "tos_minority_protection", text: "tos_minority_protection_text" },
    { title: "tos_complaints", text: "tos_complaints_text" },
    { title: "tos_miscarried_games", text: "tos_miscarried_games_text" },
    { title: "tos_limitation", text: "tos_limitation_text" },
    { title: "tos_community", text: "tos_community_text" },
  ]
}

export const homeTabs = {
  LOBBY: "lobby",
  LIVE_CASINO: "live_casino",
  SLOTS: "slots",
  GAME_SHOWS: "game_shows",
  TABLE_GAMES: "table_games",
}

export const tableColumnsRankings = [
  { title: "#", width: 50 },
  { title: "User", width: 500 },
  { title: "Current Wager", width: 100 }
]

export const categories = [
  {
    title: "Slots",
    identifier: "slots"
  },
  {
    title: "Poker",
    identifier: "poker"
  },
  {
    title: "Blackjack",
    identifier: "card"
  },
  {
    title: "Roulette",
    identifier: "roulette"
  }
]

export const rakebacksByLevel = {
  "1": 3,
  "2": 4,
  "3": 5,
  "4": 6,
  "5": 7,
  "6": 8,
  "7": 9,
  "8": 10,
  "9": 11,
  "10": 12,
  "11": 13,
  "12": 14,
  "13": 15,
}

export const forbiddenCountryCodes = [
  "US",
  "USA",
  "GB",
  "GBR",
  "NL",
  "NLD",
  "CW",
  "CUW"
]
