import { parseBalance, parseFiat } from "api/balance"
import { parseError } from "api/error"
import axios from "axios"
import AccountTable from "components/account/AccountTable"
import { useProps } from "contexts/PropsContext"
import { DATE, REQ } from "libs/constants"
import { tableColumnsBets, tableSorting } from "libs/data"
import {
  BetInterface,
  TableColumnInterface,
  TableDataInterface,
  TableSortingInterface
} from "libs/interfaces"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { FaBtc } from "react-icons/fa"
import { SiEthereum, SiLitecoin } from "react-icons/si"

const AccountBets = () => {
  const { crypto, fiat } = useProps()

  const [count, setCount] = useState(10)

  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)

  const [data, setData] = useState<TableDataInterface[][]>([])

  const [columns] = useState<TableColumnInterface[]>(tableColumnsBets)

  useEffect(() => {
    onLoad(count, page)
  }, [fiat])

  const onCountChange = (countValue: number) => {
    setCount(countValue)
    setPage(1)
    onLoad(countValue, 1)
  }

  const onPageChange = (pageValue: number) => {
    setPage(pageValue)
    onLoad(count, pageValue)
  }

  const onCryptoIcon = (coin: string) => {
    switch (coin) {
      case "ETH":
        return <SiEthereum className="text-purple text-16" />
      case "BTC":
        return <FaBtc className="text-orange-2 text-16" />
      case "LTC":
        return <SiLitecoin className="text-grey-light text-16" />
      default:
        return <></>
    }
  }

  const onLoad = async (countValue: number, pageValue: number) => {
    const res = await axios({
      url: REQ.USER.BETS + `?limit=${countValue}&page=${pageValue}`,
      method: "get",
      data: {},
      withCredentials: true
    })
      .then((res: any) => res.data.response)
      .catch((error) => {
        parseError(error)
      })

    if (res) {
      setPages(res.totalPages)

      const typed: BetInterface[] = res.data.map((bet: any) => ({
        ...bet,
        crypto_amount: parseBalance(parseInt(bet.crypto_amount)),
        fiat_amount: parseInt(bet.fiat_amount),
        win_crypto_amount: parseBalance(parseInt(bet.win_crypto_amount)),
        win_fiat_amount: parseInt(bet.win_fiat_amount)
      }))

      setData(
        typed.map((bet: BetInterface) => [
          {
            text: bet.game_name,
            color: "text-black dark:text-white"
          },
          {
            text: `${fiat ? "$" : ""}${parseFiat(
              crypto,
              bet.coin,
              fiat,
              bet.crypto_amount
            )}`,
            icon: fiat ? <></> : onCryptoIcon(bet.coin)
          },
          {
            text: `${fiat ? "$" : ""}${parseFiat(
              crypto,
              bet.coin,
              fiat,
              bet.win_crypto_amount
            )}`,
            color: bet.win_crypto_amount > 0 ? "text-green" : "text-grey-light",
            prefix: `${bet.win_crypto_amount > 0 ? "+" : ""}`,
            icon: fiat ? <></> : onCryptoIcon(bet.coin)
          },
          {
            text: moment(bet.created_at).format(DATE.DATETIME)
          }
        ])
      )
    }
  }

  const [sorted, setSorted] = useState<TableSortingInterface>()

  const [sorting] = useState<TableSortingInterface[]>(tableSorting)

  return (
    <>
      <AccountTable
        columns={columns}
        data={data}
        sorted={sorted}
        sorting={sorting}
        handlerSort={setSorted}
        count={count}
        handlerCount={onCountChange}
        page={page}
        handlerPage={onPageChange}
        pages={pages}
      />
    </>
  )
}

export default AccountBets
