import { onGetProviders } from "api/providersApi"
import Wrapper from "components/wrapper/Wrapper"
import { useProps } from "contexts/PropsContext"
import { parseList } from "libs/functions"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdKeyboardArrowDown } from "react-icons/md"

const ProvidersPopup = ({ providers, setProviders }:{providers: string[], setProviders: any}) => {
    const { darkMode } = useProps()
    const { t } = useTranslation(['landing'])

    const [openProvider, setOpenProvider] = useState(false)
    const [providersList, setProvidersList] = useState<any[]>([])

    useEffect(() => {
        onLoad()
    }, [darkMode])

    const onLoad = async () => {
        const providerResponse = await onGetProviders(darkMode)
        if (providerResponse) {
        setProvidersList(providerResponse.providers)
        }
    }

    const onToggleProvider = (entry: string) => {
        setProviders(parseList(providers, entry))
    }

    const onCLear = () => {
        setProviders([])
    }

  return (
    <>
        <div className="relative">
            <button
                onClick={() => setOpenProvider(!openProvider)}
                className="px-16 h-48 flex gap-12 items-center rounded-6 transition-all group duration-300 bg-grey-light-1 dark:bg-grey-border"
            >
                <div className="text-grey-light font-bold group-hover:text-black dark:group-hover:text-white text-16">
                    {t("providers")}
                </div>
                <Wrapper open={providers.length > 0}>
                    <div className="px-6 rounded-4 text-black dark:text-white text-16 bg-purple font-bold">
                        {providers.length}
                    </div>
                </Wrapper>
                <MdKeyboardArrowDown className="text-grey-light group-hover:text-black dark:group-hover:text-white" />
            </button>
            <Wrapper open={openProvider}>
                <div
                    onClick={() => setOpenProvider(!openProvider)}
                    className="fixed z-10 top-0 left-0 w-screen h-[100vh]"
                ></div>
                <div className="absolute z-20 w-[150px] h-[500px] overflow-x-auto grid grid-cols-1 top-[60px] left-0 rounded-6">
                    {providersList.map(
                        (provider: any, key: number) => (
                            <button
                                onClick={() =>
                                    onToggleProvider(provider.identifier)
                                }
                                className={`p-12 text-left ${
                                    providers.includes(provider.identifier)
                                    ? "bg-grey-light"
                                    : "bg-grey-light-1 dark:bg-grey-border"
                                }`}
                                key={key}
                            >
                            <div className="text-black dark:text-white text-15">{provider.title}</div>
                            </button>
                        )
                    )}
                </div>
            </Wrapper>
        </div>
        {providers.length > 0 && (<div>
            <button
                onClick={() => onCLear()}
                className="bg-red flex items-center text-black dark:text-white h-36 px-16 rounded-6 hover:text-black dark:hover:text-white font-semibold text-16"
            >
                X
            </button>
        </div>)}
    </>
  )
}

export default ProvidersPopup
