import Modal from "components/modals/Modal"
import ModalClose from "components/modals/ModalClose"
import { URL } from "libs/constants"
import React from "react"
import { MdCheck } from "react-icons/md"
import { Link } from "react-router-dom"

const ModalActivate = ({ open, handler }: { open: boolean; handler: any }) => {
  return (
    <>
      <Modal open={open} handler={handler}>
        <div className="bg-grey-light-3 dark:bg-grey-med rounded-10 p-24 grid grid-cols-1 gap-24 w-[448px] max-w-full relative">
          <div className="w-full flex justify-center">
            <div className="w-60 h-60 rounded-full bg-green-10 flex items-center justify-center">
              <MdCheck className="text-green text-24" />
            </div>
          </div>
          <div className="w-full grid grid-cols-1 gap-12">
            <div className="w-full text-16 sm:text-18 text-black dark:text-white text-center">
              Email activated!
            </div>
            <div className="w-full text-14 text-grey-light text-center">
              Thanks for activating your account!
            </div>
          </div>
          <Link
            to={URL.HOME}
            className="w-full h-48 bg-green rounded-6 flex items-center justify-center text-black dark:text-white text-14 sm:text-16"
          >
            Back to home
          </Link>
          <ModalClose open={open} handler={handler} />
        </div>
      </Modal>
    </>
  )
}

export default ModalActivate
