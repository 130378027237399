import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import RewardsFreeGame from "components/rewards/RewardsFreeGame"
import RewardsMenu from "components/rewards/RewardsMenu"
import RewardsPromoCode from "components/rewards/RewardsPromoCode"
import RewardsRakeback from "components/rewards/RewardsRakeback"
import RewardsVIP from "components/rewards/RewardsVIP"
import WrapperPage from "components/wrapper/WrapperPage"
import { RewardType } from "libs/enums"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdPages } from "react-icons/md"
import { useLocation } from "react-router-dom"

const Rewards = () => {
  const { t } = useTranslation(['landing'])
  const location = useLocation<{ tabToShow: RewardType }>()
  const [tab, setTab] = useState(RewardType.Rakeback)

  useEffect(() => {
    if (location?.state?.tabToShow !== undefined) {
      setTab(location?.state?.tabToShow)
    }
  }, [location])

  return (
    <>
      <Nav />
      <WrapperPage>
        <div className="w-full grid grid-cols-1 gap-32">
          <div className="w-full grid grid-cols-[auto,1fr] gap-14 items-center">
            <MdPages className="text-20 text-purple" />
            <div className="text-black dark:text-white text-24">{t("rewards")}</div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-[300px,1fr] w-full gap-24">
            <RewardsMenu tab={tab} handler={setTab} />
            <div className="w-full bg-grey-light-3 dark:bg-grey-med rounded-10 p-20 sm:p-24">
              {
                {
                  [RewardType.Promo]: <RewardsPromoCode />,
                  [RewardType.Rakeback]: <RewardsRakeback />,
                  [RewardType.VIP]: <RewardsVIP handler={() => setTab(RewardType.Freegame)} />,
                  [RewardType.Freegame]: <RewardsFreeGame />
                }[tab]
              }
            </div>
          </div>
        </div>
      </WrapperPage>
      <Footer />
    </>
  )
}

export default Rewards
