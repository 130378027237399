import { parseError } from "api/error"
import axios from "axios"
import Modal from "components/modals/Modal"
import ModalClose from "components/modals/ModalClose"
import { REQ } from "libs/constants"
import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import InputFormPassword from "utils/inputs/InputFormPassword"

interface Params {
  token: string
}

const ModalForgot = ({
  open,
  handler,
  handlerChanged
}: {
  open: boolean
  handler: any
  handlerChanged: any
}) => {
  const { token } = useParams<Params>()

  const [passwordNew, setPasswordNew] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")

  const onEnter = (key: string) => {
    if (key === "Enter") {
      onConfirm()
    }
  }

  const onConfirm = async () => {
    if (!(passwordNew && passwordConfirm)) {
      toast.error("Enter a password")
      return
    }

    if (passwordNew !== passwordConfirm) {
      toast.error("Passwords do not match")
      return
    }

    axios({
      url: REQ.AUTH.RESET,
      method: "post",
      data: { token: token, password: passwordNew, passwordr: passwordConfirm },
      withCredentials: true
    })
      .then((res: any) => res.data)
      .then(() => {
        handler(false)
        handlerChanged(true)
      })
      .catch((error) => {
        parseError(error)
      })
  }

  return (
    <>
      <Modal open={open} handler={handler}>
        <div className="bg-grey-light-3 dark:bg-grey-med rounded-10 p-24 grid grid-cols-1 gap-24 w-[450px] max-w-full relative">
          <div className="w-full grid grid-cols-1 gap-12">
            <div className="w-full text-16 sm:text-18 text-black dark:text-white text-center">
              Create new password
            </div>
            <div className="w-full text-14 text-grey-light text-center">
              If you change your password, you will be unable to withdraw for 48
              hours due to security reasons.
            </div>
          </div>
          <InputFormPassword
            title={"New Password"}
            placeholder={"Enter new password"}
            value={passwordNew}
            handler={setPasswordNew}
            onEnter={onEnter}
          />
          <InputFormPassword
            title={"Confirm Password"}
            placeholder={"Enter password again"}
            value={passwordConfirm}
            handler={setPasswordConfirm}
            onEnter={onEnter}
          />
          <button
            onClick={() => onConfirm()}
            className="w-full h-48 bg-green rounded-6 flex items-center justify-center text-black dark:text-white text-14 sm:text-16"
          >
            Create new password
          </button>
          <ModalClose open={open} handler={handler} />
        </div>
      </Modal>
    </>
  )
}

export default ModalForgot
