import { parseError } from "api/error"
import axios from "axios"
import Modal from "components/modals/Modal"
import ModalClose from "components/modals/ModalClose"
import Wrapper from "components/wrapper/Wrapper"
import { REQ } from "libs/constants"
import React from "react"
import { MdInfoOutline } from "react-icons/md"
import { toast } from "react-toastify"

const ModalActivate = ({
  open,
  handler,
  submit,
  resend,
  email
}: {
  open: boolean
  handler: any
  submit: any
  resend: any
  email: string
}) => {
  const onResend = () => {
    if (!email) {
      toast.error("Enter a username or email")
      return
    }

    axios({
      url: REQ.AUTH.RESEND,
      method: "post",
      data: { username: email }
    })
      .then((res: any) => res.data)
      .then(() => {
        toast.success("Successfully resent activation email")
      })
      .catch((error) => {
        parseError(error)
      })
  }

  return (
    <>
      <Modal open={open} handler={handler}>
        <div className="bg-grey-light-3 dark:bg-grey-med rounded-10 p-24 grid grid-cols-1 gap-24 w-[448px] max-w-full relative">
          <div className="w-full flex justify-center">
            <div className="w-60 h-60 rounded-full bg-red-10 flex items-center justify-center">
              <MdInfoOutline className="text-red text-24" />
            </div>
          </div>
          <div className="w-full grid grid-cols-1 gap-12">
            <div className="w-full text-16 sm:text-18 text-black dark:text-white text-center">
              Something went wrong!
            </div>
            <div className="w-full text-14 text-grey-light text-center">
              TSomething went wrong validating your email, please try again
              later or request a new link via email.
            </div>
          </div>
          <button
            onClick={() => submit(true)}
            className="w-full h-48 bg-red rounded-6 flex items-center justify-center text-black dark:text-white text-14 sm:text-16"
          >
            Try again
          </button>
          <ModalClose open={open} handler={handler} />
          <Wrapper open={resend}>
            <button
              onClick={() => onResend()}
              className="w-full text-16 font-bold text-grey-light hover:text-black dark:hover:text-white"
            >
              Resend activation email
            </button>
          </Wrapper>
        </div>
      </Modal>
    </>
  )
}

export default ModalActivate
