import React from "react"

const Toggle = ({ value, handler, toggleColor }: { value: boolean; handler: any, toggleColor?: string }) => {
  return (
    <>
      <button
        onClick={() => handler(!value)}
        className={`rounded-full w-[36px] transition-all duration-300 h-20 relative ${
          value ? (toggleColor || "bg-green") : "bg-white dark:bg-grey-med-2"
        }`}
      >
        <div
          className={`w-16 h-16 rounded-full absolute top-2 transition-all duration-300 ${
            value ? "left-18 bg-grey-light-2 dark:bg-grey-med-2" : "left-2 bg-grey-light"
          }`}
        ></div>
      </button>
    </>
  )
}

export default Toggle
