import AnimationLoading from "components/animations/AnimationLoading"
import Overlays from "components/overlays/Overlays"
import WrapperRouter from "components/wrapper/WrapperRouter"
import { useProps } from "contexts/PropsContext"
import { URL } from "libs/constants"
import { OverlayType } from "libs/enums"
import React, { Suspense } from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"
import RoutesAboutUs from "router/routes/RoutesAboutUs"
import RoutesCasino from "router/routes/RoutesCasino"
import RoutesGames from "router/routes/RoutesGames"
import RoutesLanding from "router/routes/RoutesLanding"
import RoutesUser from "router/routes/RoutesUser"
import ScrollToTop from "utils/elements/ScrollToTop"
import Game from "views/Game"

const App = () => {
  const { loading, overlay, darkMode } = useProps()

  if (overlay !== OverlayType.None) {
    return (
      <Suspense fallback={<AnimationLoading />}>
        <Overlays overlay={overlay} />
      </Suspense>
    )
  }

  if (loading) {
    return <></>
  }

  return (
    <div className={darkMode ? "dark" : ""}>
      <div className="w-full min-w-[300px]">
        <div className="fixed top-0 left-0 h-screen w-full bg-white dark:bg-grey-dark"></div>
        <div className="relative w-full">
          <Router>
            <ScrollToTop />
            <WrapperRouter>
              <Route path={URL.GAME}>
                <Game />
              </Route>
              {RoutesCasino.map((props) => props.component)}
              {RoutesGames.map((props) => props.component)}
              {RoutesUser.map((props) => props.component)}
              {RoutesAboutUs.map((props) => props.component)}
              {RoutesLanding.map((props) => props.component)}
            </WrapperRouter>
          </Router>
        </div>
      </div>
    </div>
  )
}

export default App
