import { useProps } from "contexts/PropsContext"
import { CryptoInterface } from "libs/interfaces"
import React, { useEffect, useState } from "react"
import { BiTransfer } from "react-icons/bi"
import { FaBtc, FaDollarSign } from "react-icons/fa"
import { SiEthereum, SiLitecoin } from "react-icons/si"

const FundsAmount = ({
  cryptoValue,
  setCryptoValue,
  usdValue,
  setUsdValue,
}: {
  cryptoValue?: number,
  setCryptoValue: any,
  usdValue?: number,
  setUsdValue: any,
}) => {
  const { coin, crypto } = useProps()

  const [rate, setRate] = useState<CryptoInterface>({ coin: coin, value: 0 })
  const [enterCrypto, setEnterCrypto] = useState(true)

  useEffect(() => {
    setRate(crypto.filter((c) => c.coin === coin)[0])
  }, [coin])

  useEffect(() => {
    if (rate?.value) {
      if (enterCrypto) {
        setUsdValue(
          cryptoValue && cryptoValue >= 0 ? cryptoValue * rate.value : undefined
        )
      } else {
        setCryptoValue(
          usdValue && usdValue >= 0 ? usdValue / rate.value : undefined
        )
      }
    }
  }, [cryptoValue, usdValue, rate])

  const onAmountChange = (e: any, handler: any) => {
    const amount = e.target.value

    if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {
      handler(amount)
    }
  }

  return (
    <div
      className={`w-full flex flex-wrap gap-6 ${
        enterCrypto ? "" : "flex-col-reverse"
      }`}
    >
      <div className="w-full rounded-6 bg-grey-light-1 dark:bg-grey-med-3 grid grid-cols-[auto,1fr]">
        <div className="w-52 h-52 flex items-center justify-center">
          {
            {
              ["ETH"]: <SiEthereum className="text-purple text-20" />,
              ["BTC"]: <FaBtc className="text-orange-2 text-20" />,
              ["LTC"]: <SiLitecoin className="text-grey-light text-20" />
            }[coin]
          }
        </div>
        <div className="w-full pr-16 flex items-center">
          <input
            type="number"
            min={0}
            className={`w-full text-16 ${
              enterCrypto ? "text-grey-light" : "text-black dark:text-white"
            }`}
            placeholder="Crypto amount"
            value={cryptoValue}
            onChange={(e) => onAmountChange(e, setCryptoValue)}
            readOnly={!enterCrypto}
          />
        </div>
      </div>
      <div className="w-full relative">
        <button
          onClick={() => setEnterCrypto(!enterCrypto)}
          className={`absolute left-[50%] top-[50%] transform translate-x-[-50%] translate-y-[-50%] w-32 md:w-48 h-32 md:h-48 rounded-full flex items-center justify-center bg-grey-light-3 dark:bg-grey-med transition-all duration-300 ${
            enterCrypto ? "rotate-0" : "rotate-180"
          }`}
        >
          <BiTransfer className="transform rotate-[90deg] text-16 md:text-20 text-grey-light" />
        </button>
      </div>
      <div className="w-full rounded-6 bg-grey-light-1 dark:bg-grey-med-3 grid grid-cols-[auto,1fr]">
        <div className="w-52 h-52 flex items-center justify-center">
          <FaDollarSign className="text-green-light text-20" />
        </div>
        <div className="w-full pr-16 flex items-center">
          <input
            type="number"
            min={0}
            className={`w-full text-16 ${
              !enterCrypto ? "text-grey-light" : "text-black dark:text-white"
            }`}
            placeholder="Usd amount"
            value={
              usdValue
                ? Math.round(usdValue * 100) / 100
                : usdValue === 0
                ? 0
                : ""
            }
            onChange={(e) => onAmountChange(e, setUsdValue)}
            readOnly={enterCrypto}
          />
        </div>
      </div>
    </div>
  )
}

export default FundsAmount
