import { ReactComponent as Logo } from "assets/img/logo.svg"
import { useProps } from "contexts/PropsContext"
import React from "react"

const OverlayMaintenance = () => {
  const { darkMode } = useProps()

  return (
    <>
      <div className="w-full flex justify-center">
        <div className="w-[660px] max-w-full grid grid-cols-1">
          <div className="w-full flex justify-start mb-32">
            <Logo fill={darkMode ? "white" : "dark"} className="h-32" />
          </div>
          <div className="w-full mb-24 font-black text-32 sm:text-42 md:text-48 text-black dark:text-white">
            <span className="text-purple font-black text-32 sm:text-42 md:text-48">
              Gambling Apes
            </span>{" "}
            is currently down for maintenance.
          </div>
          <div className="w-full mb-32 text-14 sm:text-16 text-grey-light">
            Site under maintenance at the moment. Check our social media for
            updates.
          </div>
          {/* <div className="w-full flex justify-start">
            <button
              onClick={(e) => openLink(e, SOCIAL.TWITTER)}
              className="h-48 rounded-6 flex items-center justify-center text-black dark:text-white bg-purple w-[160px]"
            >
              Get notified
            </button>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default OverlayMaintenance
