import axios from "axios"
import UserAvatarIcon from "components/partials/UserAvatarIcon"
import { useProps } from "contexts/PropsContext"
import { MAX_CHAT_MESSAGE_LENGTH, REQ } from "libs/constants"
import { onSortChatMessages } from "libs/functions"
import { ChatInterface } from "libs/interfaces"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  MdChatBubbleOutline,
  MdClose,
  MdSend,
} from "react-icons/md"
import NumberFormat from "react-number-format"
import { toast } from "react-toastify"

const Chat = () => {
  const { online, socket } = useProps()

  const { t } = useTranslation(['landing'])
  const messagesEndRef = useRef<null | HTMLDivElement>(null)

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView()
    }
  }
  const [loading, setLoading] = useState<boolean>(true)
  const [open, setOpen] = useState(false)

  const [message, setMessage] = useState("")
  const [disableSend, setDisableSend] = useState(false)
  const [messages, setMessages] = useState<ChatInterface[]>([])
  const [newMessages, setNewMessages] = useState<boolean>(false)

  useEffect(() => {
    getMessages()
  }, [])

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    socket.on("chat.message", (res: any) => {
      messages.push(res)
      setMessages([...onSortChatMessages(messages)].slice(-50))
      !open && setNewMessages(true)
    })

    return () => {
      if (socket) {
        socket.off("chat.message")
      }
    }
  }, [loading, socket])

  useEffect(() => {
    setNewMessages(false)
  }, [open])

  const getMessages = async () => {
    const messagesValue = await axios({
      url: REQ.CHAT.MESSAGES,
      method: "get",
      data: {},
      withCredentials: true
    })
      .then((res: any) => res.data.response)
      .catch((error) => {
        console.log(error.response)
      })

    setMessages([...onSortChatMessages(messagesValue)])
    setLoading(false)
  }

  const onSend = () => {
    const trimmedMessage = message.trim()
    if (!trimmedMessage.length) return

    if (trimmedMessage.length > MAX_CHAT_MESSAGE_LENGTH) {
      toast.error(t('message_too_long'))
      return
    }

    setDisableSend(true)
    axios({
      url: REQ.CHAT.ADD_MESSAGE,
      method: "post",
      data: { message: trimmedMessage, announcement: false },
      withCredentials: true
    })
      .then((res: any) => res.data.response)
      .catch((error) => {
        console.log(error.response)
      })

    setMessage("")
    // Disable send button for 1 seconds to avoid spam
    setTimeout(() => setDisableSend(false), 1000)
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && !disableSend) {
      onSend()
    }
  }

  return (
    <>
      <button
        onClick={() => setOpen(!open)}
        className="z-20 group right-24 rounded-full flex items-center justify-center"
      >
        <MdChatBubbleOutline className="text-30 text-grey-light group-hover:text-black dark:group-hover:text-white" />
        {!open && newMessages ? (<div className="absolute bottom-4 right-2 h-10 w-10 rounded-full bg-red" />) : ""}
      </button>
      <div
        className={`z-20 h-4/5 border-t-1 border-grey-dark fixed top-[110px] right-0 w-[350px] max-w-[100vw] bg-grey-light-3 dark:bg-grey-med transform transition duration-300 ${
          open ? "opacity-100" : "opacity-0 translate-x-[100%]"
        }`}
      >
        <button
          onClick={() => setOpen(!open)}
          className={`absolute top-24 left-0 transform w-48 h-48 rounded-full flex items-center group justify-center bg-grey-light-3 dark:bg-grey-med dark:hover:bg-grey-med-2 ${
            open ? "translate-x-[-50%]" : ""
          }`}
        >
          <MdClose className="text-20 text-grey-light group-hover:text-black dark:group-hover:text-white" />
        </button>
        <div className="h-full p-24 w-full flex flex-col gap-16">
          <div className="w-full grid grid-cols-[1fr,auto] gap-16">
            <div className="w-full grid grid-cols-1 gap-2 pl-16">
              <div className="w-full grid grid-cols-[auto,1fr] items-center gap-8">
                <div className="w-6 h-6 rounded-full bg-green"></div>
                <div className="w-full">
                  <NumberFormat
                    value={online}
                    displayType={"text"}
                    thousandSeparator={true}
                    className="text-black dark:text-white text-14 font-bold"
                  />
                </div>
              </div>
              <div className="w-full text-grey-light text-14 font-bold">
                {t('online_users')}
              </div>
            </div>
          </div>
          <div className="w-full border-b-1 border-grey-med-2 opacity-40"></div>
          <div className="flex-grow w-full rounded-10 overflow-hidden flex items-end">
            <div className="w-full max-h-full overflow-y-scroll">
              {messages && messages.map((mes: ChatInterface, key: number) => (
                <div key={key} className="break-all w-full my-8 px-12 py-8 bg-grey-light-2 dark:bg-grey-med-2 rounded-6 grid grid-cols-[auto,auto,1fr] gap-8">
                  <UserAvatarIcon user={{ avatar_url: mes.user?.avatar_url, name: mes.user?.display_name}} />
                  <div className="grid items-center">
                    <div className="w-full text-14 font-bold text-grey-light">
                      <span className="text-black dark:text-white text-14">{mes.user?.display_name}: </span>
                      {mes.message}
                    </div>
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
          </div>
          <div className="w-full border-b-1 border-grey-med-2 opacity-40"></div>
          <div className="w-full p-6 pl-16 border-1 border-grey-med-2 rounded-6 grid grid-cols-[1fr,auto] gap-20">
            <input
              type="text"
              className="w-full text-black dark:text-white placeholder-text-grey font-bold"
              placeholder="Enter message"
              value={message}
              onKeyDown={handleKeyDown}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button
              disabled={disableSend}
              onClick={() => onSend()}
              className="h-40 px-16 rounded-2 bg-purple flex items-center gap-10"
            >
              <MdSend className="text-black dark:text-white" />
              <div className="text-black dark:text-white font-bold text-16">{t('send')}</div>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Chat
