import Wrapper from "components/wrapper/Wrapper"
import { useProps } from "contexts/PropsContext"
import { CategoryLinkInterface } from "libs/interfaces"
import React, { useEffect, useRef, useState } from "react"
import { MdKeyboardArrowDown } from "react-icons/md"
import { Link } from "react-router-dom"

const NavSideCategory = ({
  icon,
  title,
  categories,
  divider,
  handler,
  hideCategory
}: {
  icon?: JSX.Element
  title: string
  categories: CategoryLinkInterface[]
  divider?: number
  handler: any
  hideCategory?: boolean
}) => {
  const { path } = useProps()

  const ref = useRef<HTMLDivElement>(null)

  const [height, setHeight] = useState(0)
  const [open, setOpen] = useState(true)

  useEffect(() => {
    if (ref.current) {
      setHeight(open ? ref.current.scrollHeight : 0)
    }
  }, [open])

  return (
    <>
      <div className="w-full grid grid-cols-1">
      {!hideCategory && <button
          onClick={() => setOpen(!open)}
          className="w-full flex items-center justify-between"
        >
          <div className="flex items-center">
            {icon && icon}
            <div className="text-16 font-bold text-black dark:text-white">{title}</div>
          </div>
          <MdKeyboardArrowDown
            className={`transform text-grey-light text-18 transition duration-300 ${
              open ? "rotate-[180deg]" : ""
            }`}
          />
        </button>}
        <div
          ref={ref}
          style={{ maxHeight: `${height}px` }}
          className={`w-full overflow-hidden transition-all duration-300 grid grid-cols-1 gap-20 ${
            !hideCategory && (open ? "mt-20 opacity-100" : "opacity-0"
          )}`}
        >
          {categories.map((category: CategoryLinkInterface, key: number) => (
            <div className="grid grid-cols-1 gap-20" key={key}>
              {!category.function ? (
                <Link
                  to={category.link}
                  onClick={() => handler(false)}
                  className="w-full grid group grid-cols-[auto,1fr] gap-12 items-center"
                >
                  <div
                    className={`${
                      path === category.link
                        ? "first:text-black dark:first:text-white"
                        : "first:text-grey-light"
                    }`}
                  >
                    {category.icon}
                  </div>
                  <div className="w-full text-grey-light group-hover:text-black dark:group-hover:text-white text-14 font-bold">
                    {category.title}
                  </div>
                </Link>
                ) : (
                  <button
                    onClick={() => category.function(true)}
                    className="w-full group items-center grid grid-cols-[auto,1fr] gap-12"
                    key={key}
                  >
                    <div className="first:text-grey-light group-hover:first:text-black dark:group-hover:first:text-white">
                        {category.icon}
                    </div>
                    <div className="w-full text-left text-grey-light text-14 font-bold group-hover:text-black dark:group-hover:text-white">
                        {category.title}
                    </div>
                  </button>
              )}
              <Wrapper open={key === divider}>
                <div className="w-full border-b-1 border-grey-med-2"></div>
              </Wrapper>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default NavSideCategory
