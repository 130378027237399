import { parseError } from "api/error"
import axios from "axios"
import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import WrapperPage from "components/wrapper/WrapperPage"
import { useProps } from "contexts/PropsContext"
import { REQ, URL } from "libs/constants"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"

const Logout = () => {
  const { authenticated, onLogout } = useProps()
  const { t } = useTranslation(["landing"])

  const history = useHistory()

  useEffect(() => {
    onLoad()
  }, [])

  const onLoad = async () => {
    if (!authenticated) {
      history.push(URL.HOME)
      return
    }

    const response = await axios({
      url: REQ.AUTH.LOGOUT,
      method: "get",
      data: {},
      withCredentials: true
    })
    .then((res: any) => res.data)
    .catch((error) => {
      parseError(error)
    })

    if (response?.success) {
      toast.success(t("success_logout"))

      onLogout()
    }
  }

  return (
    <>
      <Nav />
      <WrapperPage>
        <div className="w-full grid grid-cols-1 gap-32 text-grey-light py-[180px] text-18 sm:text-24 text-center">
          {t("logging_out")}
        </div>
      </WrapperPage>
      <Footer />
    </>
  )
}

export default Logout
