import React from "react"

const AccountLine = () => {
  return (
    <>
      <div className="w-full my-18 mb-20 sm:mb-24 border-b-1 border-grey-med-2 opacity-50"></div>
    </>
  )
}

export default AccountLine
