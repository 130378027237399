import { animated, useTransition } from "@react-spring/web"
import ModalBg from "components/modals/ModalBg"
import React, { useEffect } from "react"

const Modal = ({
  children,
  open,
  handler,
  showNavigation,
  modalOnTop
}: {
  children: any
  open: boolean
  handler: any
  showNavigation?: boolean
  modalOnTop?: boolean
}) => {
  const transition = useTransition(open, {
    config: {
      mass: 1,
      tension: 600,
      friction: 40,
      clamp: true,
      precision: 0.01,
      velocity: 0
    },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [open])

  return (
    <>
      {transition((style: any, item: boolean) => {
        return item ? (
          <animated.div
            style={style}
            className={`fixed top-0 left-0 z-30 flex h-screen w-screen min-w-[300px] md:p-20 ${!modalOnTop ? "items-center" : "top-75"}`}
          >
            <div className="flex max-h-full w-full justify-center overflow-y-auto md:rounded-4">
              <ModalBg handler={handler} showNavigation={showNavigation}/>
              <div className="relative z-20 max-w-full">{children}</div>
            </div>
          </animated.div>
        ) : (
          <></>
        )
      })}
    </>
  )
}

export default Modal
