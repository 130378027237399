/* eslint-disable react/no-unescaped-entities */
import { getTournamentRanking } from "api/tournament"
import Banner1 from "assets/img/launch-tournament.png"
import { ReactComponent as Rules } from "assets/img/rules.svg"
import { ReactComponent as Trophy } from "assets/img/tournament.svg"
import AccountTable from "components/account/AccountTable"
import TournamentCountdown from "components/countdown/TournamentCountdown"
import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import UserAvatarIcon from "components/partials/UserAvatarIcon"
import WrapperPage from "components/wrapper/WrapperPage"
import { useProps } from "contexts/PropsContext"
import { tableColumnsRankings } from "libs/data"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const Tournament = () => {
  const { user } = useProps()
  const { t } = useTranslation(["landing"])

  const [endTournamentDate, setEndTournamentDate] = useState()
  const [data, setData] = useState<any[][]>([])
  const [columns] = useState<any[]>(tableColumnsRankings)
  const [userKey, setUserKey] = useState<number>(-1)

  useEffect(() => {
    onLoad()
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      if (endTournamentDate) {
        if (new Date(endTournamentDate).getTime() > Date.now()) {
          await onLoad()
        } else {
          clearInterval(interval)
        }
      }
    }, 30000)

    return () => clearInterval(interval)
  }, [endTournamentDate])

  const mapRanking = (ranking: any, key: number, compare?: boolean) => {
    if (compare && (user?.id === ranking?.id)) {
      setUserKey(key)
    }

    return [
      {
        text: key + 1,
        color: "text-black dark:text-white"
      },
      {
        text: ranking?.username,
        icon: <UserAvatarIcon user={
          {
            avatar_url: ranking?.avatar_url,
            name: ranking?.username
          }
        }/>,
        color: "text-black dark:text-white"
      },
      {
        text: ranking?.total_amount,
        color: (user?.id === ranking?.id) && "text-black dark:text-white"
      }
    ]
  }

  const onLoad = async () => {
    const tournamentData = await getTournamentRanking()
    if (tournamentData) {
      setEndTournamentDate(tournamentData?.end_date)
      const mappedRankings = tournamentData?.rankings.map((ranking: any, key: number) => mapRanking(ranking, key, true))
      if (tournamentData?.own_ranking > tournamentData?.rankings?.length) {
        setUserKey(0)
        setData(
          [
            mapRanking(
              {
                avatar_url: user?.avatar_url,
                id: user?.id,
                total_amount: tournamentData?.own_amount,
                username: user?.display_name
              }, tournamentData?.own_ranking, false
            ),
            ...mappedRankings
          ]
        )
      } else {
        setUserKey(tournamentData?.own_ranking)
        setData(mappedRankings)
      }
    }
  }

  const renderTitle = (value: string, icon: JSX.Element) => {
    return (
      <div className="w-full flex justify-start items-center gap-12">
        {icon}
        <div className="text-24 text-black dark:text-white font-bold">{value}</div>
      </div>
    )
  }

  return (
    <>
      <Nav />
        <WrapperPage>
          <div className="w-full grid grid-cols-1 gap-24">
            {renderTitle(t("launch-tournament"), <Trophy className="fill-current" />)}
            <img src={Banner1} alt="" className="w-full rounded-24"/>
            <div className="flex justify-center text-20 text-black dark:text-white font-bold">{t("time_remaining")}</div>
            {endTournamentDate && <TournamentCountdown endDate={endTournamentDate} />}
            {renderTitle(t("rules"), <Rules className="fill-current" />)}
            <div className="bg-grey-light-3 dark:bg-grey-med p-36 rounded-10 text-16 text-grey-light">{t("tournament_rules")}</div>
            <div className="w-full flex items-center justify-center">
              <div className="w-full max-w-screen-lg grid grid-cols-1 gap-24">
                <div className="flex justify-start text-20 text-black dark:text-white font-bold">{t("ranklist")}</div>
                <AccountTable
                  columns={columns}
                  data={data}
                  userKey={userKey}
                  userBgColor="bg-purple"
                />
              </div>
            </div>
          </div>
        </WrapperPage>
      <Footer />
    </>
  )
}

export default Tournament
