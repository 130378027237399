import Wrapper from "components/wrapper/Wrapper"
import React from "react"

const AccountButton = ({
  icon,
  title,
  bg,
  handler
}: {
  icon?: JSX.Element
  title: string
  bg: string
  handler: any
}) => {
  return (
    <>
      <button
        onClick={() => handler()}
        className={`w-full h-48 flex items-center justify-center xl:w-auto gap-8 px-20 rounded-6 text-black dark:text-white text-16 ${bg}`}
      >
        <Wrapper open={icon ? true : false}>{icon}</Wrapper>
        {title}
      </button>
    </>
  )
}

export default AccountButton
