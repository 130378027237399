import React from "react"
import { MdClose } from "react-icons/md"

const ModalClose = ({ open, handler }: { open: boolean; handler: any }) => {
  return (
    <>
      <button
        onClick={() => handler(!open)}
        className="absolute top-12 md:top-24 right-12 md:right-24 w-30 h-30 flex items-center justify-center group"
      >
        <MdClose className="text-grey-light group-hover:text-black dark:group-hover:text-white text-20" />
      </button>
    </>
  )
}

export default ModalClose
