import { parseError } from "api/error"
import axios from "axios"
import AccountLine from "components/account/elements/AccountLine"
import AccountTitle from "components/account/elements/AccountTitle"
import AccountToggle from "components/account/elements/AccountToggle"
import { useProps } from "contexts/PropsContext"
import { REQ } from "libs/constants"
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

const AccountPrivacy = () => {
  const { mask, user, setMask } = useProps()
  const { t } = useTranslation(['landing'])

  const [incognito, setIncognito] = useState(false)

  useEffect(() => {
    user && setIncognito(user.user_settings.incognito)
  }, [])

  const onToggleIncognito = async (toggle: boolean) => {
    await axios({
      url: REQ.USER.TOGGLE.INCOGNITO,
      method: "post",
      data: { toggle },
      withCredentials: true
    })
      .then(() => setIncognito(toggle))
      .catch((error) => {
        parseError(error)
      })
  }

  return (
    <>
      <div className="w-full">
        <AccountTitle title={"Your profile"} />
        <div className="w-full grid grid-cols-1 gap-16">
          <AccountToggle
            title={t("mask_sensitive_info")}
            text={t('mask_sensitive_info_text')}
            value={mask}
            handler={setMask}
          />
        </div>
        <AccountLine />
        <AccountTitle title={t("game_feed")} />
        <AccountToggle
          title={t("incognito_mode")}
          text={t('incognito_mode_text')}
          value={incognito}
          handler={onToggleIncognito}
        />
      </div>
    </>
  )
}

export default AccountPrivacy
