import Background from "assets/img/background.png"
import OverlayMaintenance from "components/overlays/OverlayMaintenance"
import OverlayNotFound from "components/overlays/OverlayNotFound"
import { OverlayType } from "libs/enums"
import React from "react"

import OverlayForbidden from "./OverlayForbidden"
import OverlayForbiddenVpn from "./OverlayForbiddenVpn"

const Overlays = ({ overlay }: { overlay: OverlayType }) => {
  return (
    <>
      <div className="w-full dark:bg-grey-dark fixed z-20 top-0 left-0 h-full p-20 sm:p-30 flex justify-center">
        <div className="absolute top-0 bottom-0 right-0">
          <img src={Background} alt="" className="max-w-[300vw] h-full" />
        </div>
        <div className="w-full h-full relative max-w-screen-lg flex items-center">
          <div className="w-full max-h-full overflow-scroll">
            {
              {
                [OverlayType.None]: <OverlayNotFound />,
                [OverlayType.Forbidden]: <OverlayForbidden />,
                [OverlayType.ForbiddenVpn]: <OverlayForbiddenVpn />,
                [OverlayType.Maintenance]: <OverlayMaintenance />,
                [OverlayType.NotFound]: <OverlayNotFound />
              }[overlay]
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default Overlays
