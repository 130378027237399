import HomeSection from "components/home/HomeSection"
import React from "react"

const HomeSectionWrapper = ({
  title,
  icon,
  category,
  provider,
  search,
  isSearchResult,
  isLive,
  isGameShow
}: {
  title: string
  icon: JSX.Element
  category?: string
  provider?: string
  search?: string
  isSearchResult?: boolean
  isLive?: boolean
  isGameShow?: boolean
}) => {
  return (
    <>
      {((!isSearchResult && !search) || (isSearchResult && search)) && (
        <HomeSection
          title={title}
          icon={icon}
          category={category}
          provider={provider}
          search={search}
          isLive={isLive}
          isGameShow={isGameShow}
        />
      )}
    </>
  )
}

export default HomeSectionWrapper
