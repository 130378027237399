import Auth from "components/nav/Auth"
import { useProps } from "contexts/PropsContext"
import { RewardType } from "libs/enums"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { AiOutlineTrophy } from "react-icons/ai"
import { FiTrendingUp } from "react-icons/fi"
import { MdOutlineAvTimer } from "react-icons/md"

import HomeNewsBanners from "./HomeNewsBanners"
import HomeSectionColumn from "./HomeSectionColumn"
import HomeSectionTabs from "./HomeSectionTabs"
import HomeVIPSystem from "./HomeVIPSystem"

const HomeNoAuth = ({homeTab, setHomeTab}:{homeTab: string, setHomeTab: any}) => {
  const { user, authenticated, onRewardModalOpen } = useProps()
  const { t } = useTranslation(['landing'])

  const search = window.location.search
  const params = new URLSearchParams(search)
  const code = params.get('code')

  const [openLogin, setOpenLogin] = useState(false)
  const [openRegister, setOpenRegister] = useState(code ? true : false)

  const onVipSystemClick = () => {
    onRewardModalOpen(true, RewardType.VIP)
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-40">
        <div
          className={`w-full grid grid-cols-1 lg:grid-cols-2 gap-32 py-20 bg-apes-bg bg-cover bg-no-repeat bg-right`}
          >
          {authenticated ? (
            <div className="w-full grid grid-cols-1 gap-16">
              <div className="w-full text-40 text-black dark:text-white font-black">
                {t("welcome_back")},{" "}
                <span className="text-40 text-purple font-black">
                  {user?.display_name}
                </span>
              </div>
              <HomeVIPSystem />
              <div className="w-full">
                <button
                  onClick={() => onVipSystemClick()}
                  className="bg-purple rounded-6 px-12 text-black dark:text-white h-48 font-bold flex items-center gap-6"
                  >
                  <AiOutlineTrophy className="fill-current text-white text-20" />
                  {t("vip-system")}
                </button>
              </div>
          </div>
          ) : (
            <div className="w-full grid grid-cols-1 gap-16">
              <div className="w-full text-40 text-black dark:text-white font-black">
                {t("only_crypto_casino")}{" "}
                <span className="text-40 text-purple font-black">
                  {t("gamblers")}
                </span>{" "}
                  {t("by")}{" "}
                <span className="text-40 text-purple font-black">
                  {t("gamblers")}
                </span>
              </div>
              <div className="w-full text-black dark:text-white text-20">
                {t("sign_up_for_rewards")}
              </div>
              <div className="w-full">
                <button
                  onClick={() => setOpenRegister(true)}
                  className="bg-purple rounded-6 px-24 text-black dark:text-white h-48 font-bold flex items-center"
                  >
                  {t("create_account")}
                </button>
              </div>
            </div>
          )}
          <div className="w-full flex justify-center lg:justify-end gap-80">
            <HomeSectionColumn
              title={t("trending_games")}
              icon={<FiTrendingUp className="fill-current text-green text-24" />}
              category={"trending"}
              sizeValue={3}
            />
            <HomeSectionColumn
              title={t("recently_added")}
              icon={<MdOutlineAvTimer className="fill-current text-yellow text-24" />}
              category={"new"}
              sizeValue={3}
            />
          </div>
        </div>
        <HomeNewsBanners />
        <HomeSectionTabs value={homeTab} handler={setHomeTab}/>
      </div>
      <Auth
        openLogin={openLogin}
        setOpenLogin={setOpenLogin}
        openRegister={openRegister}
        setOpenRegister={setOpenRegister}
        affiliateCode={code}
      />
    </>
  )
}

export default HomeNoAuth
