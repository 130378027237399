/* eslint-disable react/no-unescaped-entities */
import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import WrapperPage from "components/wrapper/WrapperPage"
import { privacyPolicy, selfExclusion, termsOfService } from "libs/data"
import { Params, AboutUsInterface, AboutUsDataInterface } from "libs/interfaces"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

const AboutUs = () => {
  const { tab } = useParams<Params>()
  const { t } = useTranslation(["landing"])

  const [aboutUsPolicies, setaboutUsPolicies] = useState<AboutUsInterface>(privacyPolicy)

  useEffect(() => {
    switch (tab) {
      case "privacy":
        setaboutUsPolicies(privacyPolicy)
        break
      case "selfexclusion":
        setaboutUsPolicies(selfExclusion)
        break
      case "tos":
        setaboutUsPolicies(termsOfService)
        break
      default:
        setaboutUsPolicies(privacyPolicy)
        break
    }
  }, [tab])

  return (
    <>
      <Nav />
        <WrapperPage>
          <div className="text-24 text-black dark:text-white font-bold mb-9">{t(aboutUsPolicies.title)}</div>
          <div className={`w-full bg-grey-light-1 dark:bg-grey-med-3 p-16 rounded-6`}>
            {aboutUsPolicies.data.map((entry: AboutUsDataInterface, key: number) => (
              <div className="text-14 sm:text-16" key={key}>
                <div className="text-black dark:text-white">
                  {t(entry.title)}
                </div>
                <div className="text-grey-light mb-9 whitespace-pre-line">
                  {t(entry.text)}
                </div>
              </div>
            ))}
          </div>
        </WrapperPage>
      <Footer />
    </>
  )
}

export default AboutUs
