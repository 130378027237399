import React from "react"

const AccountButtons = ({ children }: { children: any }) => {
  return (
    <>
      <div className="w-full grid xl:flex items-center justify-start gap-16 grid-cols-1 md:grid-cols-2">
        {children}
      </div>
    </>
  )
}

export default AccountButtons
