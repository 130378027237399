import { ReactComponent as Blackjack } from "assets/img/categories/blackjack.svg"
import { ReactComponent as Popular } from "assets/img/categories/popular.svg"
import { ReactComponent as Slots } from "assets/img/categories/slot.svg"
import { ReactComponent as New } from "assets/img/new.svg"
import BannerNtfProject from "assets/img/news/nft_project.png"
import Bets from "components/bets/Bets"
import BannerButton from "components/buttons/BannerButton"
import HomeNoAuth from "components/home/HomeNoAuth"
import HomeSectionButtons from "components/home/HomeSectionButtons"
import HomeSectionWrapper from "components/home/HomeSectionWrapper"
import { SOCIAL } from "libs/constants"
import { homeTabs } from "libs/data"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { MdOutlineGamepad } from "react-icons/md"

const HomeFull = () => {
  const { t } = useTranslation(['landing'])

  const [provider, setProvider] = useState("")
  const [homeTab, setHomeTab] = useState(homeTabs.LOBBY)

  return (
    <>
      <HomeNoAuth homeTab={homeTab} setHomeTab={setHomeTab} />
      {(homeTab === homeTabs.LOBBY) && <HomeSectionWrapper
        title={t("popular_games")}
        icon={<Popular className="fill-current text-grey-light text-24" />}
        category={"popular"}
      />}
      <HomeSectionButtons
        title={t("providers")}
        icon={<MdOutlineGamepad className="text-24 text-grey-light" />}
        value={provider}
        handler={setProvider}
        hideSeeMore
      />
      {(homeTab === homeTabs.LOBBY || homeTab === homeTabs.SLOTS) && <HomeSectionWrapper
        title={t("slots")}
        icon={<Slots className="fill-current text-grey-light text-24" />}
        category={"slots"}
        provider={provider}
      />}
      {(homeTab === homeTabs.LOBBY || homeTab === homeTabs.TABLE_GAMES || homeTab === homeTabs.LIVE_CASINO) && <HomeSectionWrapper
        title={homeTab === homeTabs.LIVE_CASINO ? t("live_casino") : t("table_games")}
        icon={<Blackjack className="fill-current text-grey-light text-24" />}
        category={"poker, video_poker, roulette, card, carps"}
        provider={provider}
        isLive={homeTab === homeTabs.LIVE_CASINO}
      />}
      {homeTab === homeTabs.LOBBY && <HomeSectionWrapper
        title={t("new_games")}
        icon={<New className="fill-current text-grey-light text-24" />}
        category={"new"}
      />}
      {(homeTab === homeTabs.GAME_SHOWS) && <HomeSectionWrapper
        title={t("game_shows")}
        icon={<Popular className="fill-current text-grey-light text-24" />}
        category={"popular"}
        isGameShow={homeTab === homeTabs.GAME_SHOWS}
      />}
      <div className="w-full mt-20">
        <Bets />
      </div>
      <BannerButton banner={BannerNtfProject} value={SOCIAL.LOOKSRARE} text={t("buy_on_looksrare")} />
    </>
  )
}

export default HomeFull
