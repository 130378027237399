import Banner1 from "assets/img/new_banners/lottory.png"
import Banner2 from "assets/img/new_banners/rakeback.png"
import Banner3 from "assets/img/new_banners/socials.png"
import BannerLiveCasino from "assets/img/news/live_casino.png"
import BannerTestingPhase from "assets/img/news/testing_phase.png"
import { SOCIAL, URL } from "libs/constants"
import React from "react"
import { useTranslation } from "react-i18next"

const HomeNewsBanners = () => {
  const { t } = useTranslation(["landing"])

  const onBannerClick = (link: string, data?: any) => {
    // history.push(link, data)
  }

  const banners: any[] = [
    {
      banner: Banner1,
      link: URL.TOURNAMENT
    },
    {
      banner: Banner2,
      link: URL.ANNOUNCEMENT,
      data: {
        banner: BannerLiveCasino,
        header: t("live_casino_header"),
        text: t("live_casino_text"),
        handlerRedirect: URL.HOME,
        handlerText: t("start_playing")
      }
    },
    {
      banner: Banner3,
      link: URL.ANNOUNCEMENT,
      data: {
        banner: BannerTestingPhase,
        header: t("testing_phase_header"),
        text: t("testing_phase_text"),
        handlerLink: SOCIAL.DISCORD,
        handlerText: t("discord")
      }
    }
  ]

  return (
    <div className="w-full grid grid-cols-1 overflow-x-auto">
      <div className="w-full flex justify-start items-center lg:grid lg:grid-cols-3 gap-24">
        {banners.map((banner: any, key: number) => (
          <img
            key={key}
            onClick={() => onBannerClick(banner?.link, banner?.data)}
            src={banner?.banner}
            alt=""
            className="w-full h-[100px] lg:h-[150px] cursor-pointer rounded-10"
          />
        ))}
      </div>
    </div>
  )
}

export default HomeNewsBanners
