import React from "react"
import { Link } from "react-router-dom"

const FooterLink = ({ title, link }: { title: string; link: string }) => {
  return (
    <>
      <Link
        to={link}
        className="w-full text-grey-light text-16 font-bold hover:text-black dark:hover:text-white"
      >
        {title}
      </Link>
    </>
  )
}

export default FooterLink
