import Wrapper from "components/wrapper/Wrapper"
import React, { useState } from "react"
import { MdKeyboardArrowDown } from "react-icons/md"

const InputFormDropdown = ({
  title,
  placeholder,
  value,
  handler,
  onEnter,
  initialState
}: {
  title: string
  placeholder: string
  value: string
  handler: any
  onEnter: any
  initialState?: boolean
}) => {
  const [open, setOpen] = useState(initialState || false)

  return (
    <>
      <div className="w-full grid grid-cols-1 gap-10">
        <button
          onClick={() => setOpen(!open)}
          className="w-full flex items-center justify-between gap-4"
        >
          <div className="flex items-center gap-4 text-16 font-bold text-grey-light">
            {title}
          </div>
          <MdKeyboardArrowDown
            className={`text-20 transform transition-all duration-300 ${
              open ? "text-black dark:text-white rotate-[180deg]" : "text-grey-light"
            }`}
          />
        </button>
        <Wrapper open={open}>
          <div className="w-full flex items-center rounded-6 bg-grey-light-2 dark:bg-grey-med-2 px-16 h-[52px]">
            <input
              type="text"
              className="text-black dark:text-white leading-[44px] placeholder-grey-light text-16 font-bold"
              placeholder={placeholder}
              value={value}
              onChange={(e) => handler(e.target.value)}
              onKeyUp={(e) => (onEnter ? onEnter(e.key) : null)}
              disabled={initialState}
            />
          </div>
        </Wrapper>
      </div>
    </>
  )
}

export default InputFormDropdown
