import React, { useState } from "react"
import { FaEye, FaEyeSlash } from "react-icons/fa"

const AccountInputPassword = ({
  title,
  value,
  handler,
  readonly
}: {
  title: string
  value?: string
  handler: any
  readonly?: boolean
}) => {
  const [hidden, setHidden] = useState(true)

  return (
    <>
      <div className="w-full grid grid-cols-1 gap-8 mb-16">
        <div className="w-full text-grey-light text-semibold text-14 sm:text-16">
          {title}
        </div>
        <div className="w-full h-48 grid grid-cols-[1fr,auto] items-center bg-grey-light-1 dark:bg-grey-border rounded-6 pl-16">
          <input
            type={hidden ? "password" : "text"}
            readOnly={readonly ? true : false}
            className="w-full placeholder-grey-light text-black dark:text-white text-16"
            value={value ? value : ""}
            placeholder={title}
            onChange={(e) => handler(e.target.value)}
          />
          <button
            onClick={() => setHidden(!hidden)}
            className="w-48 h-48 flex items-center justify-center"
          >
            {hidden ? (
              <FaEye className="text-grey-light group-hover:text-black dark:group-hover:text-white" />
            ) : (
              <FaEyeSlash className="text-grey-light group-hover:text-black dark:group-hover:text-white" />
            )}
          </button>
        </div>
      </div>
    </>
  )
}

export default AccountInputPassword
