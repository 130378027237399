import Wrapper from "components/wrapper/Wrapper"
import React, { useState } from "react"
import { FaEye, FaEyeSlash } from "react-icons/fa"

const InputFormPassword = ({
  title,
  placeholder,
  value,
  handler,
  handlerReset,
  password,
  background,
  onEnter
}: {
  title: string
  placeholder: string
  value: string
  handler: any
  handlerReset?: any
  password?: string
  background?: string
  onEnter: any
}) => {
  const [hidden, setHidden] = useState(true)

  return (
    <>
      <div className="w-full grid grid-cols-1 gap-10">
        <div className="w-full flex items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <span className="text-16 font-bold text-grey-light">{title}</span>
            <span className="text-red text-16 font-bold">*</span>
          </div>
          <Wrapper open={password ? true : false}>
            <div
              onClick={() => handlerReset()}
              className="cursor-pointer text-16 font-bold text-grey-light hover:text-black dark:hover:text-white"
            >
              {password}
            </div>
          </Wrapper>
        </div>
        <div
          className={`w-full grid grid-cols-[1fr,auto] items-center rounded-6 h-[52px] ${
            background ? background : "bg-grey-light-2 dark:bg-grey-med-2"
          }`}
        >
          <input
            type={hidden ? "password" : "text"}
            className="w-full text-black dark:text-white leading-[52px] pl-16 pr-4 rounded-l-6 placeholder-grey-light text-16 font-bold"
            placeholder={placeholder}
            value={value}
            onChange={(e) => handler(e.target.value)}
            onKeyUp={(e) => (onEnter ? onEnter(e.key) : null)}
          />
          <div
            onClick={() => setHidden(!hidden)}
            className="cursor-pointer w-52 h-52 flex items-center justify-center"
          >
            {hidden ? (
              <FaEye className="text-grey-light group-hover:text-black dark:group-hover:text-white" />
            ) : (
              <FaEyeSlash className="text-grey-light group-hover:text-black dark:group-hover:text-white" />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default InputFormPassword
