import GameInfoPopup from "components/popups/GameInfoPopup"
import { GameInterface } from "libs/interfaces"
import React from "react"

const GameButton = ({
  game,
  handler
}: {
  game: GameInterface
  handler?: any
}) => {
  return (
    <button
        onClick={() => handler()}
        className="w-full grid grid-cols-1 gap-16"
        disabled={!handler}
    >
        <img src={game?.image} alt="" className="w-full rounded-10" />
        <div className="w-full grid grid-cols-1 gap-4">
          <div className="w-full grid grid-cols-[1fr,auto] gap-12 items-center">
              <div className="w-full text-16 text-black dark:text-white text-left overflow-ellipsis truncate">
              {game?.title}
              </div>
              <GameInfoPopup game={game} />
          </div>
          <div className="w-full text-14 text-left text-grey-light capitalize">
              {game?.gameProvider?.title}
          </div>
        </div>
    </button>
  )
}

export default GameButton
