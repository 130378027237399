import HomeSectionGame from "components/home/HomeSectionGame"
import { GameInterface } from "libs/interfaces"
import React from "react"
import { useTranslation } from "react-i18next"

const HomeSectionGrid = ({
  games,
}: {
  games: GameInterface[]
}) => {
  const { t } = useTranslation(['landing'])

  return (
    <>
      <div className="w-full grid grid-cols-1 gap-24">
        <div className="w-full">
          <div
            className={`${
              games.length > 0
                ? `w-full grid gap-24 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6`
                : "w-full"
            }`}
          >
            {games.length > 0 ? (
              <>
                {games.map((game: GameInterface, key: number) => (
                  <div className={"w-full"} key={key}>
                    <HomeSectionGame game={game} />
                  </div>
                ))}
              </>
            ) : (
              <div
                className={`w-full text-14 sm:text-16 text-grey-light bg-grey-light-1 dark:bg-grey-med-3 p-16 rounded-6 flex items-center justify-center`}
              >
                {t("no_games")}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeSectionGrid
