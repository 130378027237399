import { onGetProviders } from "api/providersApi"
import HomeSectionTop from "components/home/HomeSectionTop"
import { useProps } from "contexts/PropsContext"
import { ProviderInterface } from "libs/interfaces"
import React, { useEffect, useState } from "react"

const HomeSectionButtons = ({
  title,
  icon,
  value,
  handler,
  hideSeeMore
}: {
  title: string
  icon: JSX.Element
  value: string
  handler: any
  hideSeeMore?: boolean
}) => {
  const { darkMode } = useProps()

  const [open, setOpen] = useState(false)
  const [providersList, setProvidersList] = useState<ProviderInterface[]>([])
  const [_, setTotalProviders] = useState(0)

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [pageSize, setPageSize] = useState(6)

  useEffect(() => {
    onLoad(page, pageSize)
  }, [darkMode])

  const onLoad = async (pageValue: number, pageSizeValue: number) => {
    const providerResponse = await onGetProviders(darkMode, pageValue, pageSizeValue)
    setPageSize(pageSizeValue)
    if (providerResponse) {
      setPage(providerResponse.page)
      setProvidersList(providerResponse.providers)
      setTotalPages(providerResponse.total_pages)
      setTotalProviders(providerResponse.total)
    }
  }

  const onShowMoreProviders = (value: boolean) => {
    setOpen(value)
    onLoad(1, value ? 24 : 6)
  }

  const onPrevious = () => {
    onLoad(page > 1 ? page - 1 : 1, pageSize)
  }

  const onNext = () => {
    onLoad(page < totalPages ? page + 1 : totalPages, pageSize)
  }

  return (
    <>
      <div className="w-full grid grid-cols-1 gap-24">
        <HomeSectionTop
          title={title}
          icon={icon}
          moreGames={open}
          slides={totalPages}
          handleMoreGames={onShowMoreProviders}
          handlerNext={onNext}
          handlerPrevious={onPrevious}
          hideSeeMore={hideSeeMore}
        />
        <div
            className={`${
              providersList.length > 0
                ? `w-full grid gap-24 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6`
                : "w-full"
            }`}
          >
            {providersList.map((provider: any, key: number) => (
                <button
                  onClick={() =>
                    handler(value === provider.identifier ? "" : provider.identifier)
                  }
                  className={`flex justify-center items-center gap-16 p-10 dark:hover:bg-grey-med-2 rounded-10 ${
                    provider.identifier === value ? "bg-grey-light-2 dark:bg-grey-med-2" : "bg-grey-light-3 dark:bg-grey-med"
                  }`}
                  key={key}
                >
                  <img src={provider.image} alt="" className="h-48" />
                </button>
            ))}
          </div>
        </div>
    </>
  )
}

export default HomeSectionButtons
