import Modal from "components/modals/Modal"
import ModalClose from "components/modals/ModalClose"
import React from "react"
import { MdOutlineMarkEmailRead } from "react-icons/md"

const ModalChanged = ({
  open,
  handler,
  handlerLogin
}: {
  open: boolean
  handler: any
  handlerLogin: any
}) => {
  return (
    <>
      <Modal open={open} handler={handler}>
        <div className="bg-grey-light-3 dark:bg-grey-med rounded-10 p-24 grid grid-cols-1 gap-24 w-[450px] max-w-full relative">
          <div className="w-full flex justify-center">
            <div className="w-60 h-60 rounded-full bg-green-10 flex items-center justify-center">
              <MdOutlineMarkEmailRead className="text-green text-24" />
            </div>
          </div>
          <div className="w-full grid grid-cols-1 gap-12">
            <div className="w-full text-16 sm:text-18 text-black dark:text-white text-center">
              Password successfully changed!
            </div>
            <div className="w-full text-14 text-grey-light text-center">
              Thanks to username you are getting 50% additional rakeback You can
              claim your Reload every 21 minutes.
            </div>
          </div>
          <button
            onClick={() => {
              handler(false)
              handlerLogin(true)
            }}
            className="w-full h-48 bg-green rounded-6 flex items-center justify-center text-black dark:text-white text-14 sm:text-16"
          >
            Back to login
          </button>
          <ModalClose open={open} handler={handler} />
        </div>
      </Modal>
    </>
  )
}

export default ModalChanged
