import { parseError } from "api/error"
import axios from "axios"
import Modal from "components/modals/Modal"
import ModalClose from "components/modals/ModalClose"
import { useProps } from "contexts/PropsContext"
import { REQ } from "libs/constants"
import React, { useState } from "react"
import { toast } from "react-toastify"
import InputFormPassword from "utils/inputs/InputFormPassword"

const ModalTwoFactorDisable = ({
  open,
  handler
}: {
  open: boolean
  handler: any
}) => {
  const { setLoading } = useProps()

  const [code, setCode] = useState("")

  const onEnter = (key: string) => {
    if (key === "Enter") {
      onSubmit()
    }
  }

  const onSubmit = async () => {
    if (!code) {
      toast.error("Please enter a code")
      return
    }

    axios({
      url: REQ.USER.MFA.REMOVE,
      method: "post",
      data: { code: code },
      withCredentials: true
    })
      .then(() => {
        toast.success("Two-factor authentication disabled")
        handler(false)

        setLoading(true)
      })
      .catch((error) => {
        parseError(error)
      })
  }

  return (
    <>
      <Modal open={open} handler={handler}>
        <div className="bg-grey-light-3 dark:bg-grey-med rounded-10 grid grid-cols-1 w-[500px] max-w-full relative">
          <div className="w-full grid grid-cols-1 gap-12 p-24 border-b-1 border-grey-med-2">
            <div className="w-full text-16 sm:text-18 text-black dark:text-white">
              Two-Factor Authentication (2FA)
            </div>
            <div className="w-full text-14 text-grey-light">
              Disabling two-factor authentication will require you to enter your
              current two-factor authentication code.
            </div>
          </div>
          <div className="w-full p-24 grid grid-cols-1 gap-24">
            <InputFormPassword
              title={"Two-factor code"}
              placeholder={"Enter two-factor code"}
              value={code}
              handler={setCode}
              onEnter={onEnter}
            />
            <button
              onClick={() => onSubmit()}
              className="w-full h-48 bg-purple rounded-6 flex items-center justify-center text-black dark:text-white text-14 sm:text-16 gap-6"
            >
              Disable 2FA
            </button>
          </div>
          <ModalClose open={open} handler={handler} />
        </div>
      </Modal>
    </>
  )
}

export default ModalTwoFactorDisable
