import { ReactComponent as Logo } from "assets/img/logo.svg"
import { useProps } from "contexts/PropsContext"
import { URL } from "libs/constants"
import React from "react"
import { Link } from "react-router-dom"

const GamblingApesLogo = () => {
    const { darkMode } = useProps()

    return (
        <div>
            <Link to={URL.HOME}>
                <Logo fill={darkMode ? "white" : "dark"} className="h-32" />
            </Link>
        </div>
    )
}

export default GamblingApesLogo
