import { parseError } from "api/error"
import axios from "axios"
import AccountButton from "components/account/elements/AccountButton"
import AccountButtons from "components/account/elements/AccountButtons"
import AccountInput from "components/account/elements/AccountInput"
import AccountInputPassword from "components/account/elements/AccountInputPassword"
import AccountLine from "components/account/elements/AccountLine"
import AccountTitle from "components/account/elements/AccountTitle"
import ModalTwoFactor from "components/modals/ModalTwoFactor"
import ModalTwoFactorDisable from "components/modals/ModalTwoFactorDisable"
import Wrapper from "components/wrapper/Wrapper"
import { useProps } from "contexts/PropsContext"
import { REQ } from "libs/constants"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

import AccountToggle from "./elements/AccountToggle"

const AccountSecurity = () => {
  const { mask, user } = useProps()
  const { t } = useTranslation(["landing"])

  const [code, setCode] = useState("")

  const [twofactor, setTwofactor] = useState(user!.use_two_fa)

  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")

  const [openTwofactor, setOpenTwofactor] = useState(false)
  const [openTwofactorDisable, setOpenTwofactorDisable] = useState(false)

  const [openChangePassword, setOpenChangePassword] = useState(false)

  useEffect(() => {
    if (twofactor && !user?.use_two_fa) {
      setOpenTwofactor(true)
    } else if (!twofactor && user?.use_two_fa) {
      setOpenTwofactorDisable(true)
    }
  }, [twofactor])

  useEffect(() => {
    if (!openTwofactor && !user?.use_two_fa) {
      setTwofactor(false)
    }
  }, [openTwofactor])

  useEffect(() => {
    if (!openTwofactorDisable && user?.use_two_fa) {
      setTwofactor(true)
    }
  }, [openTwofactorDisable])

  const onChange = async () => {
    if (!(password && passwordConfirm)) {
      toast.error(t("enter_password_and_continue"))
    }

    const data: any = { password: password, passwordr: passwordConfirm }

    if (user!.use_two_fa) {
      data.code = code
    }

    axios({
      url: REQ.USER.PASSWORD.CHANGE,
      method: "post",
      data: data,
      withCredentials: true
    })
      .then((res: any) => res.data.response)
      .then(() => {
        toast.success(t("change_password_success"))

        setCode("")
        setPassword("")
        setPasswordConfirm("")

        setOpenChangePassword(false)
      })
      .catch((error) => {
        parseError(error)
      })
  }

  const onChangePassword = () => {
    setOpenChangePassword(true)
  }

  return (
    <>
      {user ? (
        <div className="w-full">
          <AccountTitle title={t("recovery")} />
          {mask ? (
            <>
              <AccountInputPassword
                title={t("email_address")}
                value={user.email_address}
                handler={() => {}}
                readonly={true}
              />
              <AccountInputPassword
                title={t("phone_number")}
                value={user.phone_number}
                handler={() => {}}
                readonly={true}
              />
            </>
          ) : (
            <>
              <AccountInput
                title={t("email_address")}
                value={user.email_address}
                handler={() => {}}
                readonly={true}
              />
              <AccountInput
                title={t("phone_number")}
                value={user.phone_number}
                handler={() => {}}
                readonly={true}
              />
            </>
          )}
          <AccountLine />
          <AccountTitle title={t("change_password")} />
          <Wrapper open={openChangePassword}>
            <AccountInputPassword
              title={t("enter_new_password")}
              value={password}
              handler={setPassword}
            />
            <AccountInputPassword
              title={t("confirm_new_password")}
              value={passwordConfirm}
              handler={setPasswordConfirm}
            />
            <Wrapper open={user.use_two_fa}>
              <AccountInputPassword
                title={"Enter 2FA code"}
                value={code}
                handler={setCode}
              />
            </Wrapper>
          </Wrapper>
          <AccountButtons>
            <AccountButton
              title={t("change_password")}
              bg={"bg-green"}
              handler={!openChangePassword ? onChangePassword : onChange}
            />
          </AccountButtons>
          <AccountLine />
          <AccountTitle title={"Two-factor authentication"} />
          <AccountToggle
            title={"Two-Factor authentication"}
            text={
              "Enabling two-factor authentication will require a code when updating or viewing sensitive information"
            }
            value={twofactor}
            handler={setTwofactor}
          />
          <AccountLine />
        </div>
      ) : (
        ""
      )}
      <ModalTwoFactor open={openTwofactor} handler={setOpenTwofactor} />
      <ModalTwoFactorDisable
        open={openTwofactorDisable}
        handler={setOpenTwofactorDisable}
      />
    </>
  )
}

export default AccountSecurity
