import React from "react"
import Countdown from "react-countdown"
import { useTranslation } from "react-i18next"

const TournamentCountdown = ({
    endDate
}:{
    endDate: string
}) => {
    const { t } = useTranslation(['landing'])

    const renderNumber = (value: number, text: string) => {
        return (
            <div className="grid grid-cols-1 items-center gap-12">
                <div className="flex justify-center items-center gap-12">
                    <div className="w-72 py-12 rounded-10 flex justify-center bg-grey-light-3 dark:bg-grey-med text-64 text-black dark:text-white font-bold">{(value / 10 | 0) % 10}</div>
                    <div className="w-72 py-12 rounded-10 flex justify-center bg-grey-light-3 dark:bg-grey-med text-64 text-black dark:text-white font-bold">{value % 10}</div>
                </div>
                <div className="flex justify-center text-20 text-grey-light font-bold">{text}</div>
            </div>
        )
    }

    const renderCountdown = ({ days, hours, minutes } : { days: number, hours: number, minutes: number}) => {
        return (
            <div className="w-full flex justify-center items-start gap-12">
                {renderNumber(days, t("days"))}
                <div className="flex justify-center text-64 text-black dark:text-white font-bold">:</div>
                {renderNumber(hours, t("hours"))}
                <div className="flex justify-center text-64 text-black dark:text-white font-bold">:</div>
                {renderNumber(minutes, t("minutes"))}
            </div>
        )
    }

  return <Countdown date={new Date(endDate)} renderer={renderCountdown} />
}

export default TournamentCountdown
