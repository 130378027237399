import { openLink } from "libs/functions"
import React from "react"
import { isMobile } from "react-device-detect"

const BannerButton = ({
    value,
    banner,
    handler,
    text
}:{
    value: string
    banner: string
    handler?: any
    text: string
}) => {
    const onButtonClick = (e: any, link: string) => {
        openLink(e, link)
    }

    return (
        <div className="w-full relative">
            <img src={banner} alt="" className="w-full rounded-24"/>
            <button
                onClick={(e) => handler ? handler(value) : onButtonClick(e, value)}
                className={`absolute top-[75%] left-[42%] bg-purple rounded-6 px-12 text-black dark:text-white font-bold ${isMobile ? "text-10 h-18" : "h-48"} `}
            >
            {text}
            </button>
        </div>
    )
}

export default BannerButton
