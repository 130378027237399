import { getBettingJwt } from "api/user"
import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import WrapperBetby from "components/wrapper/WrapperBetby"
import { useProps } from "contexts/PropsContext"
import { BETBY_BRAND_ID } from "libs/constants"
import React, { useEffect } from "react"

const Betby = () => {
  const { authenticated, setOpenLogin, setOpenRegister, setOpenDeposit } = useProps()

  useEffect(() => {
    const bt = new (window as any).BTRenderer()
    onLoad(bt)

    return () => bt.kill()
  }, [authenticated])

  const getBetbyJWTToken = async () => {
    const data = await getBettingJwt()

    if (data) {
      return data?.accessToken
    }

    return null
  }

  const onLoad = async (bt: any) => {
    let tokenValue = null
    if (authenticated) {
      tokenValue = await getBetbyJWTToken()
    }

    bt.initialize({
      brand_id: BETBY_BRAND_ID,
      token: tokenValue,
      onTokenExpired: getBetbyJWTToken,
      themeName: "gamblingapes",
      target: document.getElementById('betby'),
      stickyTop: 70,
      betSlipOffsetTop: 70,
      betslipZIndex: 999,
      fontFamilies: ['Montserrat, sans-serif', 'Roboto, sans-serif'], onRouteChange: function() {},
      onLogin: () => setOpenLogin(true),
      onRegister: () => setOpenRegister(true),
      onSessionRefresh: () => location.reload(),
      onRecharge: () => setOpenDeposit(true)
    })
  }

  return (
    <>
      <Nav />
      <WrapperBetby>
        <div id='betby'></div>
      </WrapperBetby>
      <Footer />
    </>
  )
}

export default Betby
