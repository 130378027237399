import { parseFiat } from "api/balance"
import ModalDeposit from "components/modals/elements/ModalDeposit"
import ModalWithdraw from "components/modals/elements/ModalWithdraw"
import Modal from "components/modals/Modal"
import { useProps } from "contexts/PropsContext"
import { DepositType } from "libs/enums"
import {
  DepositInterface,
  SiteCryptoDetailsInterface,
  WalletInterface
} from "libs/interfaces"
import React, { useEffect, useState } from "react"
import { FaBtc } from "react-icons/fa"
import { MdClose, MdOutlineDownload, MdOutlineUpload } from "react-icons/md"
import { SiEthereum, SiLitecoin } from "react-icons/si"
import NumberFormat from "react-number-format"

const ModalFunds = ({ open, handler }: { open: boolean; handler: any }) => {
  const { coin, fiat, crypto, site, wallets, onChangeCoin } = useProps()

  const [tab, setTab] = useState<DepositType>(DepositType.Deposit)

  const [tabs] = useState<DepositInterface[]>([
    {
      icon: <MdOutlineDownload className="text-18" />,
      title: "Deposit",
      type: DepositType.Deposit
    },
    {
      icon: <MdOutlineUpload className="text-18" />,
      title: "Withdraw",
      type: DepositType.Withdraw
    }
  ])

  const [settings, setSettings] = useState<SiteCryptoDetailsInterface>(
    site.crypto[coin]
  )

  useEffect(() => {
    setSettings(site.crypto[coin])
  }, [coin, site])

  const onChange = async (coinValue: string) => {
    await onChangeCoin(coinValue)
  }

  return (
    <>
      <Modal open={open} handler={handler}>
        <div className="bg-grey-light-3 dark:bg-grey-med rounded-10 grid grid-cols-1 w-[700px] max-w-full relative">
          <button
            onClick={() => handler(!open)}
            className="absolute top-0 right-0 group w-50 h-50 rounded-10 flex items-center justify-center"
          >
            <MdClose className="text-grey-light group-hover:text-black dark:group-hover:text-white text-20" />
          </button>
          <div className="w-full grid grid-cols-1 p-20 sm:p-30 gap-24">
            <div className="w-full flex justify-start border-b-1 border-grey-med-2-40">
              <div className="flex items-center justify-center gap-24 mb-[-1px]">
                {tabs.map((item: DepositInterface, key: number) => (
                  <button
                    onClick={() => setTab(item.type)}
                    className={`flex px-2 border-b-2 pb-14 items-center gap-12 ${
                      item.type === tab ? "border-purple" : "border-transparent"
                    }`}
                    key={key}
                  >
                    <div
                      className={`${
                        item.type === tab ? "text-purple" : "text-grey-light"
                      }`}
                    >
                      {item.icon}
                    </div>
                    <div
                      className={`block text-16 font-bold ${
                        item.type === tab ? "text-black dark:text-white" : "text-grey-light"
                      }`}
                      key={key}
                    >
                      {item.title}
                    </div>
                  </button>
                ))}
              </div>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-16">
              {wallets.map((entry: WalletInterface, key: number) => (
                <button
                  onClick={() => onChange(entry.coin)}
                  className={`w-full bg-grey-light-1 dark:bg-grey-med-3 items-center rounded-6 p-12 grid border-2 grid-cols-[auto,auto,1fr] gap-12 ${
                    entry.coin === coin ? "border-purple" : "border-transparent"
                  }`}
                  key={key}
                >
                  <div className="w-24 flex items-center justify-center">
                    {
                      {
                        ["ETH"]: <SiEthereum className="text-purple text-22" />,
                        ["BTC"]: <FaBtc className="text-orange-2 text-22" />,
                        ["LTC"]: (
                          <SiLitecoin className="text-grey-light text-22" />
                        )
                      }[entry.coin]
                    }
                  </div>
                  <div className="h-32 border-r-1 border-grey-med-2"></div>
                  <div className="w-full grid grid-cols-1">
                    <div className="w-full text-left text-black dark:text-white text-14 sm:text-16">
                      {
                        {
                          ["ETH"]: "Ethereum",
                          ["BTC"]: "Bitcoin",
                          ["LTC"]: "Litecoin"
                        }[entry.coin]
                      }
                    </div>
                    <div className="w-full text-left">
                      <NumberFormat
                        value={parseFiat(
                          crypto,
                          entry.coin,
                          fiat,
                          entry.balance
                        )}
                        prefix={fiat ? "$" : ""}
                        displayType={"text"}
                        thousandSeparator={true}
                        className="text-grey-light text-14"
                      />
                    </div>
                  </div>
                </button>
              ))}
            </div>
            <div className="w-full border-b-1 border-grey-med-2-40"></div>
            {
              {
                [DepositType.Deposit]: <ModalDeposit open={open} />,
                [DepositType.Withdraw]: (
                  <ModalWithdraw settings={settings} handler={handler} />
                )
              }[tab]
            }
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalFunds
