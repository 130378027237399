import axios from "axios"
import { REQ } from "libs/constants"
import { addGameImages } from "libs/functions"

import { parseError } from "./error"

export const getSite = () => {
  return axios({
    url: REQ.SITE.GET,
    method: "get",
    data: {},
    withCredentials: true
  })
    .then((res: any) => res.data.response)
    .catch((error) => {
      console.log(error.response)
    })
}

export const getUser = () => {
  return axios({
    url: REQ.USER.GET,
    method: "get",
    data: {},
    withCredentials: true
  })
    .then((res: any) => res.data.response.payload)
    .catch((error) => {
      console.log(error.response)
    })
}

export const getUserLevelProgress = async () => {
  const response = await axios({
      url: REQ.USER.GET_LEVEL,
      method: "get",
      data: {},
      withCredentials: true
  })

  if (response?.data?.success) {
      return response?.data?.response
  }
  return null
}

export const getWallet = () => {
  return axios({
    url: REQ.PAYMENTS.WALLETS,
    method: "get",
    data: {},
    withCredentials: true
  })
    .then((res: any) => res.data.response.payload)
    .catch((error) => {
      console.log(error.response)
    })
}

export const getRewardGames = async () => {
  const rewardGames = await axios({
    url: REQ.USER.REWARDS.GAMES,
    method: "get",
    data: {},
    withCredentials: true
  })

  if (rewardGames?.data?.success) {

    return addGameImages(rewardGames?.data?.response)
  }
  return null
}

export const onAuthSocial = async (email: string, provider: string) => {
  const response = await axios({
    url: REQ.AUTH.SOCIAL,
    method: "post",
    data: { email, provider },
    withCredentials: true
  })
    .then((res: any) => res.data.response.payload)
    .catch((error) => {
      parseError(error)
    })

  if (response?.data?.success) {
    return response?.data?.response?.payload
  }

  return null
}

export const onIntegrateLooksRare = async (token: string) => {
  const response = await axios({
    url: REQ.USER.INTEGRATE_LOOKSRARE,
    method: "post",
    data: { token },
    withCredentials: true
  }).catch((error) => {
      parseError(error)
  })

  if (response?.data?.success) {
    return true
  }

  return false
}

export const onChangeDiscordName = async (name: string) => {
  const response = await axios({
    url: REQ.USER.DISCORD_NAME,
    method: "post",
    data: { name },
    withCredentials: true
  })
    .catch((error) => {
      parseError(error)
    })

  if (response?.data?.success) {
    return response?.data?.response
  }

  return null
}

export const onAffiliateUse = async (code: string) => {
 return await axios({
  url: REQ.AFFILIATE.USE,
  method: "post",
  data: { code: code },
  withCredentials: true
})
  .then((res: any) => res.data.response.payload)
  .catch((error) => {
    parseError(error)
  })
}

export const getUserCountry = async () => {
  const geoResponse = await axios({
    url: "https://geolocation-db.com/json/",
    method: "get"
  })
    .catch((error) => {
      console.log(error)
    })

  if (geoResponse?.status === 200) {
    return geoResponse?.data
  }

  const ipApiResponse = await axios({
    url: "https://ipapi.co/json/",
    method: "get"
  })
    .catch((error) => {
      console.log(error)
    })

  if (ipApiResponse?.status === 200) {
    return ipApiResponse?.data
  }

  return null
}

export const detectVPN = async () => {
  const userData = await getUserCountry()
  if(!userData?.IPv4) {
    return true
  }
  const params = {key: '18df7978c50a4c8c9f904e1e05d81500'}
  const response = await axios.get(
      "https://vpnapi.io/api/" + userData.IPv4,
    {params}
  )
  .catch((error) => {
    console.log(error)
  })
  if(!response?.data?.security) {
    console.log(response)
  }
  const security = response?.data?.security
  console.log('VPN Detect', security)
  if(security?.proxy === true || security?.tor === true || security?.vpn === true) {
    return true
  }
  return false
}

export const getBettingJwt = () => {
  return axios({
    url: REQ.BETBY,
    method: "post",
    withCredentials: true
  })
  .then((res: any) => res.data.response.payload)
  .catch((error) => {
    parseError(error)
  })
}

export const onChangeWalletCoin = async (coin: string) => {
  const response = await axios({
    url: REQ.USER.WALLET_COIN,
    method: "post",
    data: { coin },
    withCredentials: true
  })
    .catch((error) => {
      parseError(error)
    })

  if (response?.data?.success) {
    return response?.data?.response
  }

  return null
}
