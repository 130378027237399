import React from "react"

const AccountTitle = ({ title }: { title: string }) => {
  return (
    <>
      <div className="w-full mb-12 text-black dark:text-white text-16 sm:text-18">{title}</div>
    </>
  )
}

export default AccountTitle
