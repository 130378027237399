import Chat from "components/chat/Chat"
import NavWallet from "components/nav/NavWallet"
import UserAvatarNavIcon from "components/partials/UserAvatarNavIcon"
import ProfilePopup from "components/popups/ProfilePopup"
import SearchBarNav from "components/search/SearchBarNav"
import Wrapper from "components/wrapper/Wrapper"
import { useProps } from "contexts/PropsContext"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  MdClose,
} from "react-icons/md"
import { VscThreeBars } from "react-icons/vsc"

const NavMobile = ({
  game,
  openNavSide,
  setOpenNavSide,
  setOpenLogin,
  setOpenRegister
}: {
  game?: boolean,
  openNavSide: any,
  setOpenNavSide: any,
  setOpenLogin: any,
  setOpenRegister: any
}) => {
  const { authenticated, user } = useProps()

  const { t } = useTranslation(["landing"])

  const [openProfile, setOpenProfile] = useState(false)

  return (
    <>
      <div className={`z-30 w-full min-w-[300px] fixed top-0 left-0 grid grid-cols-1`} >
        <div className="bg-grey-light-3 dark:bg-grey-med w-full h-70 px-10 sm:px-10 flex justify-evenly items-center">
            <div className="w-full flex justify-start items-center gap-12">
              <button
                onClick={() => setOpenNavSide(!openNavSide)}
                className="flex items-center justify-center group"
              >
                {openNavSide ? (
                  <MdClose className="text-grey group-hover:text-black dark:group-hover:text-white text-30" />
                ) : (
                  <VscThreeBars className="text-grey text-30 group-hover:text-black dark:group-hover:text-white" />
                )}
              </button>
              <SearchBarNav />
            </div>
            <Wrapper open={authenticated}>
              <div className="w-full flex items-center">
                <NavWallet game={game} />
              </div>
            </Wrapper>
            {user ? (
              <div className="w-full flex items-center justify-end gap-12">
                <div className="relative">
                  <Chat />
                </div>
                <div className="relative">
                  <button
                    onClick={() => setOpenProfile(!openProfile)}
                    className="rounded-full overflow-hidden"
                  >
                    <UserAvatarNavIcon user={{ avatar_url: user.avatar_url, name: user.username}} />
                  </button>
                  <Wrapper open={openProfile}>
                    <div
                      onClick={() => setOpenProfile(false)}
                      className="z-20 fixed top-0 left-0 w-full h-full"
                    ></div>
                    <ProfilePopup />
                  </Wrapper>
                </div>
              </div>
            ) : (
              <div className="w-full flex items-center justify-end gap-12">
                <button
                  onClick={() => {
                    setOpenLogin(true)
                    setOpenRegister(false)
                  }}
                  className="px-18 h-48 flex items-center justify-center text-black dark:text-white font-bold text-16"
                >
                  {t("login")}
                </button>
                <button
                  onClick={() => {
                    setOpenRegister(true)
                    setOpenLogin(false)
                  }}
                  className="px-18 bg-purple rounded-6 h-48 flex items-center justify-center text-black dark:text-white font-bold text-16"
                >
                  {t("register")}
                </button>
              </div>
            )}
        </div>
      </div>
    </>
  )
}

export default NavMobile
