import { parseError } from "api/error"
import axios from "axios"
import FundsAmount from "components/funds/FundsAmount"
import { useProps } from "contexts/PropsContext"
import { REQ } from "libs/constants"
import QR from "qrcode.react"
import React, { useEffect, useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { useTranslation } from "react-i18next"
import { MdContentCopy } from "react-icons/md"
import { toast } from "react-toastify"

const ModalDeposit = ({ open }: { open: boolean }) => {
  const { coin } = useProps()
  const { t } = useTranslation(['landing'])

  const [addressDeposit, setAddressDeposit] = useState("")
  const [hasEnteredMinimumDepositCorrectly, setHasEnteredMinimumDepositCorrectly] = useState(false)
  const [cryptoValue, setCryptoValue] = useState<number>()
  const [usdValue, setUsdValue] = useState<number>()

  useEffect(() => {
    if (open) {
      onLoad()
    }
  }, [coin, open])

  const onLoad = async () => {
    if (!coin) {
      toast.error("Please try again later.")
      return
    }

    const response = await axios({
      url: REQ.PAYMENTS.COIN.replace(":coin", coin),
      method: "get",
      data: {},
      withCredentials: true
    })
      .then((res: any) => res.data.response)
      .catch((error) => {
        parseError(error)
      })

    if (response) {
      setAddressDeposit(response.address)
    }
  }

  const onContinue = () => {
    setHasEnteredMinimumDepositCorrectly(true)
  }

  return (
    <>
      {
        !hasEnteredMinimumDepositCorrectly ? (
          <>
            <div className="w-full text-14 sm:text-16 text-grey-light">
              {t("deposit_amount")}
            </div>
            <FundsAmount cryptoValue={cryptoValue} setCryptoValue={setCryptoValue} usdValue={usdValue} setUsdValue={setUsdValue} />
            <button
              onClick={() => onContinue()}
              className="w-full rounded-6 flex h-52 items-center justify-center bg-purple gap-8 disabled:opacity-25"
              disabled={usdValue ? usdValue < 50 : true}
            >
              <div className="text-16 font-bold text-black dark:text-white">{t("continue")}</div>
            </button>
            <div className="w-full bg-grey-light-1 dark:bg-grey-med-3 grid grid-cols-1 px-16 py-12 gap-2 rounded-6">
              <div className="w-full text-16 text-black dark:text-white">
                {t("min_deposit")}
              </div>
              <div className="w-full text-grey-light text-14">
                {t("min_deposit_text")}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="w-full flex justify-center">
              <div className="p-10 rounded-6 bg-white">
                <QR value={addressDeposit} size={140} />
              </div>
            </div>
            <div className="w-full grid grid-cols-1 gap-12">
              <div className="w-full text-14 sm:text-16 text-grey-light">
                {t("send_deposit_to")}
              </div>
              <div className="w-full rounded-6 bg-grey-light-1 dark:bg-grey-med-3 grid grid-cols-[1fr,auto]">
                <div className="w-full px-16 flex items-center">
                  <input
                    type="text"
                    className="w-full text-black dark:text-white text-16"
                    placeholder=""
                    value={addressDeposit}
                    readOnly
                  />
                </div>
                <div className="w-52 h-52 p-4">
                  <CopyToClipboard
                    text={addressDeposit}
                    onCopy={() => toast.success("Copied address")}
                  >
                    <div className="cursor-pointer w-44 h-44 flex items-center justify-center group rounded-6 dark:hover:bg-grey-med">
                      <MdContentCopy className="text-grey-light group-hover:text-black dark:group-hover:text-white text-20" />
                    </div>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
            <div className="w-full border-b-1 border-grey-med-2-40"></div>
            <div className="w-full grid grid-cols-1 gap-12">
              <div className="text-16 text-grey-light font-bold">{t("warning")}</div>
              <div className="w-full text-grey-light text-14">
               {t("dont_send_nfts")}
              </div>
            </div>
          </>
        )
      }
    </>
  )
}

export default ModalDeposit
