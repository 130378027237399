import { ReactComponent as Blackjack } from "assets/img/categories/blackjack.svg"
import { ReactComponent as Poker } from "assets/img/categories/poker.svg"
import { ReactComponent as Popular } from "assets/img/categories/popular.svg"
import { ReactComponent as Recent } from "assets/img/categories/recent.svg"
import { ReactComponent as Roulette } from "assets/img/categories/roulette.svg"
import { ReactComponent as Slot } from "assets/img/categories/slot.svg"
import { ReactComponent as Star } from "assets/img/categories/star.svg"
import { ReactComponent as New } from "assets/img/new.svg"
import NavSideCategory from "components/nav/NavSideCategory"
import GamblingApesLogo from "components/partials/GamblingApesLogo"
import CasinoToggle from "components/toggles/CasinoToggle"
import { useProps } from "contexts/PropsContext"
import { URL } from "libs/constants"
import { CategoryLinkInterface } from "libs/interfaces"
import React, { useState } from "react"
import { isMobile } from "react-device-detect"
import { useTranslation } from "react-i18next"
import { AiOutlineTrophy } from "react-icons/ai"
import { MdCardGiftcard, MdPeopleOutline } from "react-icons/md"
import Toggle from "utils/elements/Toggle"

const NavSide = ({ open, handler }: { open: boolean; handler: any }) => {
  const { authenticated, darkMode, onDarkModeChange, onRewardModalOpen, setOpenAffiliates, setOpenTournament } = useProps()
  const { t } = useTranslation(['landing'])

  const [authenticatedSlots] = useState<CategoryLinkInterface[]>([
    {
      icon: <MdCardGiftcard className="text-18" />,
      title: t("rewards"),
      link: URL.REWARDS,
      function: onRewardModalOpen
    },
    {
      icon: <MdPeopleOutline className="text-18" />,
      title: t("affiliates"),
      link: URL.AFFILIATES,
      function: setOpenAffiliates
    },
    {
      link: URL.FAVOURITE,
      title: t("favourite_games"),
      icon: <Star className="fill-current group-hover:text-black dark:group-hover:text-white" />
    },
    {
      link: URL.RECENT,
      title: t("recent_games"),
      icon: <Recent className="fill-current group-hover:text-black dark:group-hover:text-white" />
    }
  ])

  const [slots] = useState<CategoryLinkInterface[]>([
    {
      icon: <AiOutlineTrophy className="text-22 fill-current group-hover:text-black dark:group-hover:text-white" />,
      title: t("tournament"),
      link: URL.TOURNAMENT,
      function: setOpenTournament
    },
    {
      link: URL.CASINO.POPULAR,
      title: t("popular_games"),
      icon: <Popular className="fill-current group-hover:text-black dark:group-hover:text-white" />
    },
    {
      link: URL.CASINO.SLOTS,
      title: t("slots"),
      icon: <Slot className="fill-current group-hover:text-black dark:group-hover:text-white" />
    },
    {
      link: URL.CASINO.POKER,
      title: t("poker"),
      icon: <Poker className="fill-current group-hover:text-black dark:group-hover:text-white" />
    },
    {
      link: URL.CASINO.CARD,
      title: t("blackjack"),
      icon: <Blackjack className="fill-current group-hover:text-black dark:group-hover:text-white" />
    },
    {
      link: URL.CASINO.ROULETTE,
      title: t("roulette"),
      icon: <Roulette className="fill-current group-hover:text-black dark:group-hover:text-white" />
    },
    {
      link: URL.CASINO.NEW,
      title: t("new_games"),
      icon: <New className="fill-current group-hover:text-black dark:group-hover:text-white" />
    }
  ])

  return (
    <>
      <div
        className={`z-30 shadow dark:shadow-none dark:border-t-1 dark:border-grey-dark fixed top-70 left-0 w-[250px] bottom-0 bg-grey-light-2 dark:bg-grey-med transform transition duration-300 ${
          open ? "" : "translate-x-[-100%]"
        }`}
      >
        <div className="max-h-full p-24 overflow-y-scroll w-full">
          <div className="w-full grid grid-cols-1 items-start gap-20">
            {isMobile && <GamblingApesLogo />}
            {isMobile && <CasinoToggle />}
            <NavSideCategory
              title={t("slots")}
              categories={authenticated ? [...authenticatedSlots, ...slots] : slots}
              divider={authenticated ? 1 : -1}
              handler={handler}
              hideCategory
            />
             <div className="w-full py-16 border-t-1 border-grey-med-2 flex items-center gap-12">
                <Toggle value={darkMode} handler={onDarkModeChange} />
                <div className="text-14 text-black dark:text-white">Dark mode</div>
              </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NavSide
