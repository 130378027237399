import Modal from "components/modals/Modal"
import ModalClose from "components/modals/ModalClose"
import RewardsFreeGame from "components/rewards/RewardsFreeGame"
import RewardsMenu from "components/rewards/RewardsMenu"
import RewardsPromoCode from "components/rewards/RewardsPromoCode"
import RewardsRakeback from "components/rewards/RewardsRakeback"
import RewardsVIP from "components/rewards/RewardsVIP"
import { useProps } from "contexts/PropsContext"
import { URL } from "libs/constants"
import { RewardType } from "libs/enums"
import React, { useEffect, useState } from "react"
import { MdOutlineOpenInNew } from "react-icons/md"
import { useHistory } from "react-router-dom"

const ModalRewards = ({ open, handler }: { open: boolean; handler: any }) => {
  const { onGetUser, rewardTab } = useProps()
  const history = useHistory()
  const [tab, setTab] = useState<RewardType>(rewardTab)

  const reloadUser = async () => {
    await onGetUser()
  }

  useEffect(() => {
    if (open && tab === RewardType.Rakeback) {
      reloadUser()
    }
  }, [open])

  useEffect(() => {
    setTab(rewardTab)
  }, [rewardTab])

  const onOpenRewardsButtonClick = () => {
    history.push(URL.REWARDS, { tabToShow: tab })
    handler(false)
  }

  return (
    <>
      <Modal open={open} handler={handler}>
        <div className="bg-grey-light-3 dark:bg-grey-med rounded-10 grid grid-cols-1 lg:grid-cols-[300px,1fr] w-[800px] max-w-full relative">
          <RewardsMenu tab={tab} handler={setTab} />
          <div className="w-full grid grid-cols-1 p-24">
            {
              {
                [RewardType.Promo]: <RewardsPromoCode />,
                [RewardType.Rakeback]: <RewardsRakeback />,
                [RewardType.VIP]: <RewardsVIP handler={() => setTab(RewardType.Freegame)} />,
                [RewardType.Freegame]: <RewardsFreeGame handler={() => handler(false)} />
              }[tab]
            }
            <div className="w-full flex justify-center mt-24">
              <button
                onClick={() => onOpenRewardsButtonClick()}
                className="max-w-full grid grid-cols-[1fr,auto] gap-10 items-center group"
              >
                <div className="text-grey-light underline text-16 font-bold group-hover:text-black dark:group-hover:text-white">
                  Open the Reward Page to get more information.
                </div>
                <MdOutlineOpenInNew className="text-grey-light text-18 group-hover:text-black dark:group-hover:text-white" />
              </button>
            </div>
          </div>
          <ModalClose open={open} handler={handler} />
        </div>
      </Modal>
    </>
  )
}

export default ModalRewards
