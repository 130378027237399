import axios from "axios"
import { REQ } from "libs/constants"
import { ProviderInterface } from "libs/interfaces"

import { parseError } from "./error"

export const getProviderLogo = (value: string, darkMode: boolean, small?: boolean) => {
  switch (value) {
    case "groove":
      return `https://cdn.softswiss.net/logos/providers${small ? "_small" : ""}/${darkMode ? "white" : "black"}/fugaso.svg`
    case "atmosphera":
      return `https://cdn.softswiss.net/logos/providers${small ? "_small" : ""}/${darkMode ? "white" : "black"}/atmosfera.svg`
    default:
      return `https://cdn.softswiss.net/logos/providers${small ? "_small" : ""}/${darkMode ? "white" : "black"}/${value}.svg`
    }
}

export const onGetProviders = async (darkMode: boolean, page?: number, pageSize?: number, category?: string, small?: boolean) => {
    const data = await axios({
      url: REQ.PROVIDERS.SEARCH,
      method: "get",
      data: {},
      params: {
        page,
        pageSize,
        category,
      },
      withCredentials: true
    })
      .then((res: any) => res.data)
      .catch((error) => {
        parseError(error)
      })

    let mapped = []
    if (data?.success) {
      if (data.response?.providers?.length > 0) {
        mapped = data.response.providers?.map((provider: ProviderInterface) => ({
          ...provider,
          image: getProviderLogo(provider.identifier, darkMode, small)}))
      }

      return {
        ...data.response,
        providers: mapped
      }
    }
    return null
  }
