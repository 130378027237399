import { useProps } from "contexts/PropsContext"
import { URL } from "libs/constants"
import { LinkInterface } from "libs/interfaces"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { MdCardGiftcard, MdOutlineDownload, MdOutlineLogout, MdOutlineUpload, MdPeopleOutline, MdPersonOutline } from "react-icons/md"
import { Link } from "react-router-dom"

const ProfilePopup = () => {
const { path, onRewardModalOpen, setOpenAffiliates } = useProps()
const { t } = useTranslation(['landing'])

const [mobile] = useState<LinkInterface[]>([
    {
        icon: <MdPersonOutline className="text-18" />,
        title: t("profile"),
        link: URL.ACCOUNT.BASE
    },
    {
        icon: <MdCardGiftcard className="text-18" />,
        title: t("rewards"),
        link: URL.REWARDS,
        function: onRewardModalOpen
    },
    {
        icon: <MdPeopleOutline className="text-18" />,
        title: t("affiliates"),
        link: URL.AFFILIATES,
        function: setOpenAffiliates
    },
    {
        icon: <MdOutlineDownload className="text-18" />,
        title: t("deposits"),
        link: URL.ACCOUNT.TAB.replace(":tab", "deposits")
    },
    {
        icon: <MdOutlineUpload className="text-18" />,
        title: t("withdrawals"),
        link: URL.ACCOUNT.TAB.replace(":tab", "withdrawals")
    },
    {
        icon: <MdOutlineLogout className="text-18" />,
        title: t("logout"),
        link: URL.LOGOUT
    }
    ])

  return (
    <div className="rounded-6 absolute z-30 top-60 right-0 shadow dark:shadow-none bg-grey-light-2 dark:bg-grey-med-2 p-8 grid grid-cols-1 w-[210px]">
        {mobile.map((link: LinkInterface, key: number) => (
            <div className="w-full" key={key}>
                {!link.function ? (
                    <Link
                        to={link.link}
                        className="w-full group p-8 items-center grid grid-cols-[auto,1fr] gap-16"
                        key={key}
                    >
                        <div
                            className={`group-hover:first:text-black dark:group-hover:first:text-white ${
                                path === link.link
                                ? "first:text-white"
                                : "first:text-grey-light"
                            }`}
                        >
                            {link.icon}
                        </div>
                        <div
                            className={`w-full text-14 sm:text-16 group-hover:text-black dark:group-hover:text-white ${
                                path === link.link
                                ? "text-black dark:text-white"
                                : "text-grey-light"
                            }`}
                        >
                        {link.title}
                        </div>
                    </Link>
                ) : (
                    <button
                        onClick={() => link.function(true)}
                        className="w-full group p-8 items-center grid grid-cols-[auto,1fr] gap-16"
                        key={key}
                    >
                        <div className="first:text-grey-light group-hover:first:text-black dark:group-hover:first:text-white">
                            {link.icon}
                        </div>
                        <div className="w-full text-left text-grey-light text-14 sm:text-16 group-hover:text-black dark:group-hover:text-white">
                            {link.title}
                        </div>
                    </button>
                )}
            </div>
        ))}
  </div>
  )
}

export default ProfilePopup
