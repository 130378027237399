import AccountTransactions from "components/account/elements/AccountTransactions"
import React from "react"

const AccountDeposits = () => {
  return (
    <>
      <AccountTransactions type={"DEPOSIT"} />
    </>
  )
}

export default AccountDeposits
