import React from "react"

const FooterButton = ({ title, action }: { title: string; action: any }) => {
  return (
    <>
      <button
        onClick={() => action()}
        className="w-full text-grey-light text-left text-16 font-bold hover:text-black dark:hover:text-white"
      >
        {title}
      </button>
    </>
  )
}

export default FooterButton
