import AccountTitle from "components/account/elements/AccountTitle"
import ProvablyText from "components/provably/elements/ProvablyText"
import React from "react"

const ProvablyParties = () => {
  return (
    <>
      <div className="w-full">
        <AccountTitle title={"3rd party values"} />
        <ProvablyText
          text={
            <>
              Ticket Id - A unique identifier for a random result(3rd Party
              Seed). You can verify it at RANDOM.ORG Verify Ticket 3rd Party
              Seed - The 3rd party seed generated by RANDOM.ORG, used for
              influencing the roll
              <br />
              <br />
              The 3rd Party Seed is generated as the game ends, after all bets
              have been placed.
            </>
          }
        />
      </div>
    </>
  )
}

export default ProvablyParties
