import { parseBalance } from "api/balance"
import { parseError } from "api/error"
import axios from "axios"
import FundsAmount from "components/funds/FundsAmount"
import Wrapper from "components/wrapper/Wrapper"
import { useProps } from "contexts/PropsContext"
import { REQ } from "libs/constants"
import { percentage, transactionCoinFee } from "libs/data"
import {
  CryptoInterface,
  PercentageInterface,
  SiteCryptoDetailsInterface,
  TransactionFessInterface
} from "libs/interfaces"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaBtc } from "react-icons/fa"
import { MdClose, MdOutlineUpload } from "react-icons/md"
import { SiEthereum, SiLitecoin } from "react-icons/si"
import { toast } from "react-toastify"
import InputFormPassword from "utils/inputs/InputFormPassword"

const ModalWithdraw = ({
  settings,
  handler
}: {
  settings: SiteCryptoDetailsInterface
  handler: any
}) => {
  const { coin, user, wallet } = useProps()

  const { t } = useTranslation(['landing'])

  const [percentages] = useState<PercentageInterface[]>(percentage)

  const [rate] = useState<CryptoInterface>({ coin: coin, value: 0 })

  const [addressWithdraw, setAddressWithdraw] = useState("")

  const [cryptoValue, setCryptoValue] = useState<number>()
  const [usdValue, setUsdValue] = useState<number>()

  const [enterCrypto] = useState(true)
  const [use2fa, setUse2fa] = useState(false)

  const [code, setCode] = useState("")

  const [transactionFees] = useState<TransactionFessInterface>(transactionCoinFee)

  useEffect(() => {
    if (cryptoValue && cryptoValue >= 0 && wallet.balance < cryptoValue) {
      setCryptoValue(wallet.balance)
    }
  }, [])

  useEffect(() => {
    user && setUse2fa(user.use_two_fa)
  }, [])

  const onEnter = (key: string) => {
    if (key === "Enter") {
      onWithdraw()
    }
  }

  const onWithdraw = async () => {
    if (!(cryptoValue && cryptoValue > 0 && usdValue && usdValue > 0)) {
      toast.error(t("something_went_wrong"))
      return
    }

    if (!addressWithdraw) {
      toast.error(t("enter_wallet_address"))
      return
    }

    if (use2fa && !code) {
      toast.error(t("enter_2fa_code"))
      return
    }

    if (cryptoValue < 0.0005) {
      toast.error(t("minimum_amout"))
      return
    }

    const data: any = { address: addressWithdraw, amount: cryptoValue }

    if (code) {
      data.code = code
    }

    axios({
      url: REQ.PAYMENTS.WITHDRAW.replace(":coin", coin),
      method: "post",
      data: data,
      withCredentials: true
    })
      .then(() => {
        toast.success(t("success_withdrawal"))
        handler(false)
      })
      .catch((error) => {
        parseError(error)
      })
  }

  return (
    <>
      <div className="w-full grid grid-cols-1 gap-12">
        <div className="w-full text-14 sm:text-16 text-grey-light">
          {t("withdraw_amount")}
        </div>
        <FundsAmount cryptoValue={cryptoValue} setCryptoValue={setCryptoValue} usdValue={usdValue} setUsdValue={setUsdValue} />
        {wallet ? (
          <div className="w-full flex items-center justify-start gap-12">
            {percentages.map((percentage: PercentageInterface, key: number) => (
              <button
                onClick={() =>
                  enterCrypto
                    ? setCryptoValue((wallet.balance * percentage.value) / 100)
                    : setUsdValue(
                        (wallet.balance * rate.value * percentage.value) / 100
                      )
                }
                className="h-40 px-16 flex items-center rounded-6 bg-grey-light-1 dark:bg-grey-med-3 dark:hover:bg-grey-med-2 text-grey-light hover:text-black dark:hover:text-white text-14 sm:text-16"
                key={key}
              >
                {percentage.title}
              </button>
            ))}
          </div>
        ) : (
          ""
        )}
        {
          coin !== "LTC" && (
            <div className="w-full p-12 text-12 sm:text-14 text-grey-light text-center">
              {t("transaction_fees", { value: transactionFees[coin as keyof TransactionFessInterface] })}
            </div>
          )
        }
        <div className="w-full text-14 sm:text-16 text-grey-light">
          {t("send_withdrawal_to")}
        </div>
        <div className="w-full rounded-6 bg-grey-light-1 dark:bg-grey-med-3 grid grid-cols-[auto,1fr,auto]">
          <div className="w-52 h-52 flex items-center justify-center">
            {
              {
                ["ETH"]: <SiEthereum className="text-purple text-20" />,
                ["BTC"]: <FaBtc className="text-orange-2 text-20" />,
                ["LTC"]: <SiLitecoin className="text-grey-light text-20" />
              }[coin]
            }
          </div>
          <div className="w-full pr-16 flex items-center">
            <input
              type="text"
              className="w-full text-black dark:text-white text-16"
              placeholder="Enter your address"
              value={addressWithdraw}
              onChange={(e) => setAddressWithdraw(e.target.value)}
            />
          </div>
          <button
            onClick={() => setAddressWithdraw("")}
            className="w-52 h-52 flex items-center justify-center dark:hover:bg-grey-med-2 rounded-6"
          >
            <MdClose className="text-grey-light text-20" />
          </button>
        </div>
       {coin !== "LTC" && (<div className="w-full bg-grey-light-1 dark:bg-grey-med-3 grid grid-cols-1 px-16 py-12 gap-2 rounded-6">
          <div className="w-full text-16 text-black dark:text-white">
            {t("min_withdrawal")}
          </div>
          <div className="w-full text-grey-light text-14">
            {parseBalance(settings?.minWithdraw)} {coin}
          </div>
        </div>)}
        <Wrapper open={use2fa}>
          <InputFormPassword
            title={t("2fa_code")}
            placeholder={t("enter_2fa_code")}
            value={code}
            handler={setCode}
            background={"bg-grey-light-1 dark:bg-grey-med-3"}
            onEnter={onEnter}
          />
        </Wrapper>
      </div>
      <button
        onClick={() => onWithdraw()}
        className="w-full rounded-6 flex h-52 items-center justify-center bg-purple gap-8"
      >
        <MdOutlineUpload className="text-22 text-black dark:text-white" />
        <div className="text-16 font-bold text-black dark:text-white">{t("withdraw")}</div>
      </button>
    </>
  )
}

export default ModalWithdraw
