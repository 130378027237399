import AccountInputCopy from "components/account/elements/AccountInputCopy"
import AccountLine from "components/account/elements/AccountLine"
import AccountTitle from "components/account/elements/AccountTitle"
import React, { useState } from "react"

const AccountCode = () => {
  const [code] = useState("Maestro")
  const [prefix] = useState("https://gamblingapes.com/r/")

  return (
    <>
      <div className="w-full">
        <AccountTitle title={"Streamer Code"} />
        <AccountInputCopy title={"Affiliate link"} value={`${prefix}${code}`} />
        <AccountInputCopy title={"Affiliate code"} value={`${code}`} />
        <AccountLine />
        <div className="w-full flex items-center justify-between gap-12"></div>
        <div className="w-full"></div>
        <div className="w-full"></div>
      </div>
    </>
  )
}

export default AccountCode
