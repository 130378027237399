import React from "react"

const FooterTitle = ({ title }: { title: string }) => {
  return (
    <>
      <div className="w-full text-18 font-bold text-black dark:text-white">{title}</div>
    </>
  )
}

export default FooterTitle
