import { ReactComponent as Cherry } from "assets/img/categories/cherry.svg"
import { ReactComponent as Table } from "assets/img/categories/roulette.svg"
import { ReactComponent as Slot } from "assets/img/categories/slot.svg"
import { ReactComponent as Suit } from "assets/img/categories/suit.svg"
import { homeTabs } from "libs/data"
import { HomeTabsInterface } from "libs/interfaces"
import React from "react"
import { useTranslation } from "react-i18next"
import { MdCardGiftcard } from "react-icons/md"

const HomeSectionTabs = ({
  value,
  handler,
}: {
  value?: string
  handler?: any
}) => {
  const { t } = useTranslation(['landing'])

  const tabsList: HomeTabsInterface[] = [
    {
      title: homeTabs.LOBBY,
      icon: <Cherry className="fill-current" />
    },
    {
      title: homeTabs.LIVE_CASINO,
      icon: <Suit className="fill-current" />
    },
    {
      title: homeTabs.SLOTS,
      icon: <Slot className="fill-current" />
    },
    {
      title: homeTabs.GAME_SHOWS,
      icon: <MdCardGiftcard className="text-18" />,
    },
    {
      title: homeTabs.TABLE_GAMES,
      icon: <Table className="fill-current" />
    },
  ]

  return (
    <>
      <div className="w-full grid grid-cols-1 gap-24 overflow-x-auto">
        <div
            className={"w-full flex justify-start items-center gap-24"}
          >
            {tabsList.map((tab: HomeTabsInterface, key: number) => (
                <button
                  onClick={() => handler(tab.title)}
                  className={`flex justify-center items-center gap-10 p-10 dark:hover:bg-grey-med-2 rounded-10 ${
                    tab.title === value ? "text-white bg-grey-light-2 dark:bg-grey-med-2" : "text-grey-light bg-grey-light-3 dark:bg-grey-med"
                  }`}
                  key={key}
                >
                  {tab.icon}
                  {t(tab.title)}
                </button>
            ))}
          </div>
        </div>
    </>
  )
}

export default HomeSectionTabs
