import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import ProvablyMenu from "components/provably/ProvablyMenu"
import ProvablyOverview from "components/provably/ProvablyOverview"
import ProvablyParties from "components/provably/ProvablyParties"
import ProvablyRandom from "components/provably/ProvablyRandom"
import ProvablyServer from "components/provably/ProvablyServer"
import WrapperPage from "components/wrapper/WrapperPage"
import { ProvablyTabType } from "libs/enums"
import React, { useState } from "react"
import { MdOutlineAccountCircle } from "react-icons/md"

const ProvablyFair = () => {
  const [tab, setTab] = useState(ProvablyTabType.Overview)

  return (
    <>
      <Nav />
      <WrapperPage>
        <div className="w-full grid grid-cols-1 gap-32">
          <div className="w-full grid grid-cols-[auto,1fr] gap-14 items-center">
            <MdOutlineAccountCircle className="text-20 text-purple" />
            <div className="text-black dark:text-white text-24">Provably fair</div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-[300px,1fr] w-full gap-24">
            <ProvablyMenu tab={tab} setTab={setTab} />
            <div className="w-full bg-grey-light-3 dark:bg-grey-med rounded-10 p-20 sm:p-24">
              {
                {
                  [ProvablyTabType.Parties]: <ProvablyParties />,
                  [ProvablyTabType.Random]: <ProvablyRandom />,
                  [ProvablyTabType.Server]: <ProvablyServer />,
                  [ProvablyTabType.Overview]: <ProvablyOverview />
                }[tab]
              }
            </div>
          </div>
        </div>
      </WrapperPage>
      <Footer />
    </>
  )
}

export default ProvablyFair
