import AccountBets from "components/account/AccountBets"
import AccountCode from "components/account/AccountCode"
import AccountDeposits from "components/account/AccountDeposits"
import AccountGeneral from "components/account/AccountGeneral"
import AccountMenu from "components/account/AccountMenu"
import AccountPrivacy from "components/account/AccountPrivacy"
import AccountSecurity from "components/account/AccountSecurity"
import AccountVerification from "components/account/AccountVerification"
import AccountWithdrawals from "components/account/AccountWithdrawals"
import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import WrapperPage from "components/wrapper/WrapperPage"
import { AccountTabType } from "libs/enums"
import React, { useEffect, useState } from "react"
import { MdOutlineAccountCircle } from "react-icons/md"
import { useParams } from "react-router-dom"

interface Params {
  tab: string
}

const Account = () => {
  const { tab } = useParams<Params>()

  const [type, setType] = useState(AccountTabType.General)

  useEffect(() => {
    switch (tab) {
      case "general":
        setType(AccountTabType.General)
        break
      case "security":
        setType(AccountTabType.Security)
        break
      case "privacy":
        setType(AccountTabType.Privacy)
        break
      case "verification":
        setType(AccountTabType.Verification)
        break
      case "bets":
        setType(AccountTabType.Bets)
        break
      case "deposits":
        setType(AccountTabType.Deposits)
        break
      case "withdrawals":
        setType(AccountTabType.Withdrawals)
        break
      case "streamer":
        setType(AccountTabType.Code)
        break
      default:
        setType(AccountTabType.General)
        break
    }
  }, [tab])

  return (
    <>
      <Nav />
      <WrapperPage>
        <div className="w-full grid grid-cols-1 gap-32">
          <div className="w-full grid grid-cols-[auto,1fr] gap-14 items-center">
            <MdOutlineAccountCircle className="text-20 text-purple" />
            <div className="text-black dark:text-white text-24">Account</div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-[220px,1fr] xl:grid-cols-[300px,1fr] w-full gap-24">
            <AccountMenu />
            <div className="w-full bg-grey-light-3 dark:bg-grey-med rounded-10 p-20 sm:p-24">
              {
                {
                  [AccountTabType.Bets]: <AccountBets />,
                  [AccountTabType.Code]: <AccountCode />,
                  [AccountTabType.Deposits]: <AccountDeposits />,
                  [AccountTabType.General]: <AccountGeneral />,
                  [AccountTabType.Privacy]: <AccountPrivacy />,
                  [AccountTabType.Security]: <AccountSecurity />,
                  [AccountTabType.Verification]: <AccountVerification />,
                  [AccountTabType.Withdrawals]: <AccountWithdrawals />
                }[type]
              }
            </div>
          </div>
        </div>
      </WrapperPage>
      <Footer />
    </>
  )
}

export default Account
