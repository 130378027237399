import HomeVIPSystem from "components/home/HomeVIPSystem"
import React from "react"

const RewardsVIP = ({ handler }:{ handler?: any }) => {
  return (
    <>
      <div className="w-full mb-12 text-16 sm:text-18 text-black dark:text-white">
        VIP
      </div>
      <HomeVIPSystem moreInfo handler={handler} />
    </>
  )
}

export default RewardsVIP
