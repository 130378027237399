import VerifyButton from "@passbase/button/react"
import AccountButton from "components/account/elements/AccountButton"
import AccountButtons from "components/account/elements/AccountButtons"
import AccountDropZone from "components/account/elements/AccountDropZone"
import AccountInput from "components/account/elements/AccountInput"
import AccountLine from "components/account/elements/AccountLine"
import AccountText from "components/account/elements/AccountText"
import AccountTitle from "components/account/elements/AccountTitle"
import React, { useState } from "react"
import { toast } from "react-toastify"

const AccountVerification = () => {
  const [name, setName] = useState("")
  const [dob] = useState("")
  const [address, setAddress] = useState("")
  const [source] = useState("")

  const [fileId, setFileId] = useState<File>()
  const [fileAddress, setFileAddress] = useState<File>()
  const [fileFunds, setFileFunds] = useState<File>()

  const [linkId] = useState("")
  const [linkAddress] = useState("")
  const [linkFunds] = useState("")

  const onSave = () => {
    if (!(name && dob && address && source)) {
      toast.error("Complete the form")
    }
  }

  return (
    <>
      <div className="w-full">
        <AccountTitle title={"Personal Info"} />
        <AccountInput title={"Name"} value={name} handler={setName} />
        <AccountInput title={"Address"} value={address} handler={setAddress} />
        <AccountButtons>
          <AccountButton
            title={"Save changes"}
            bg={"bg-green"}
            handler={onSave}
          />
        </AccountButtons>
        <AccountLine />
        <AccountTitle title={"Verify account"} />
        <AccountButtons>
          <VerifyButton
            apiKey="zq5Ec9qMZdga8BimfsvVXLCef5bYMl1hd64WwnC6CSECxfuBAFn73U22yIRSpxBW"
            onStart={() => {}}
            onError={() => {}}
            onFinish={() => {}}
          />
        </AccountButtons>
        <AccountLine />
        <AccountTitle title={"Documents"} />
        <AccountText
          text={"Please upload three documents below in .png, .jpg, .pdf."}
        />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          <AccountDropZone
            title={"Proof of identity"}
            text={"ID Card, Passport, Drivers License"}
            file={fileId}
            setFile={setFileId}
            link={linkId}
          />
          <AccountDropZone
            title={"Proof of Address"}
            text={"Utility Bill or similar"}
            file={fileAddress}
            setFile={setFileAddress}
            link={linkAddress}
          />
          <AccountDropZone
            title={"Source of Funds"}
            text={"Paystub or similar"}
            file={fileFunds}
            setFile={setFileFunds}
            link={linkFunds}
          />
        </div>
      </div>
    </>
  )
}

export default AccountVerification
