import { ReactComponent as Slot } from "assets/img/categories/slot.svg"
import { useProps } from "contexts/PropsContext"
import { URL } from "libs/constants"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { MdSportsSoccer } from 'react-icons/md'
import { useHistory } from "react-router-dom"

const CasinoToggle = ({ toggleColor }: { toggleColor?: string }) => {
  const { isCasino, setIsCasino } = useProps()
  const { t } = useTranslation(['landing'])
  const history = useHistory()

  useEffect(() => {
    if ((window.location.href).includes(URL.BETBY)) {
      setIsCasino(false)
    } else {
      setIsCasino(true)
    }
  }, [])

  const onValueChange = (updatedValue: boolean) => {
    setIsCasino(updatedValue)
    if (updatedValue) {
      return history.push(URL.CASINO.BASE)
    }
    return history.push(URL.BETBY)
  }

  return (
    <div className="flex relative rounded-xl bg-white dark:bg-grey-med-2">
      <button
        onClick={() => onValueChange(true)}
        className={`w-full rounded-xl py-4 px-12 text-center group flex gap-4 justify-center items-center ${
          isCasino ? (toggleColor || "bg-purple") : "bg-white dark:bg-grey-med-2"
        }`}
      >
        <Slot className={`fill-current group-hover:text-black dark:group-hover:text-white ${isCasino ? "text-white" : "text-grey-light"}`} />
        <div className={`w-ful group-hover:text-black dark:group-hover:text-white text-16 font-bold ${isCasino ? "text-white" : "text-grey-light"}`}>
          {t("casino")}
        </div>
      </button>
      <button
        onClick={() => onValueChange(false)}
        className={`w-full rounded-xl py-4 px-12 text-center group flex gap-4 justify-center items-center ${
          !isCasino ? (toggleColor || "bg-purple") : "bg-white dark:bg-grey-med-2"
        }`}
      >
        <MdSportsSoccer className={`text-20 fill-current group-hover:text-black dark:group-hover:text-white ${!isCasino ? "text-white" : "text-grey-light"}`} />
        <div className={`w-ful group-hover:text-black dark:group-hover:text-white text-16 font-bold ${!isCasino ? "text-white" : "text-grey-light"}`}>
          {t("sport")}
        </div>
      </button>
    </div>
  )
}

export default CasinoToggle
