import ModalAffiliates from "components/modals/ModalAffiliates"
import ModalRewards from "components/modals/ModalRewards"
import ModalTournament from "components/modals/ModalTournament"
import Auth from "components/nav/Auth"
import NavSide from "components/nav/NavSide"
import { useProps } from "contexts/PropsContext"
import React, { useState } from "react"
import { isMobile } from "react-device-detect"

import NavMobile from "./NavMobile"
import NavWeb from "./NavWeb"

const Nav = ({ game }: { game?: boolean }) => {
  const { authenticated,
    openRewards,
    setOpenRewards,
    openAffiliates,
    setOpenAffiliates,
    openTournament,
    setOpenTournament,
    openLogin,
    setOpenLogin,
    openRegister,
    setOpenRegister
  } = useProps()

  const [open, setOpen] = useState(false)

  return (
    <>
      {
        !isMobile
          ? <NavWeb game={game} openNavSide={open} setOpenNavSide={setOpen} setOpenLogin={setOpenLogin} setOpenRegister={setOpenRegister} />
          : <NavMobile game={game} openNavSide={open} setOpenNavSide={setOpen} setOpenLogin={setOpenLogin} setOpenRegister={setOpenRegister} />
      }
      <NavSide open={open} handler={setOpen} />
      <Auth
        openLogin={openLogin}
        setOpenLogin={setOpenLogin}
        openRegister={openRegister}
        setOpenRegister={setOpenRegister}
      />
      {authenticated ? (
        <>
          <ModalAffiliates open={openAffiliates} handler={setOpenAffiliates} />
          <ModalRewards open={openRewards} handler={setOpenRewards} />
        </>
      ) : (
        ""
        )}
      <ModalTournament open={openTournament} handler={setOpenTournament} />
    </>
  )
}

export default Nav
