import AccountLine from "components/account/elements/AccountLine"
import Wrapper from "components/wrapper/Wrapper"
import { useProps } from "contexts/PropsContext"
import { tableCount } from "libs/data"
import { onSortTableAsc, onSortTableDesc } from "libs/functions"
import {
  TableColumnInterface,
  TableCountInterface,
  TableDataInterface,
  TableSortingInterface
} from "libs/interfaces"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdKeyboardArrowDown } from "react-icons/md"

const AccountTable = ({
  columns,
  data,
  sorted,
  sorting,
  handlerSort,
  count,
  handlerCount,
  page,
  handlerPage,
  pages,
  userKey,
  userBgColor
}: {
  columns: TableColumnInterface[]
  data: TableDataInterface[][]
  sorted?: TableSortingInterface
  sorting?: TableSortingInterface[]
  handlerSort?: any
  count?: number
  handlerCount?: any
  page?: number
  handlerPage?: any
  pages?: number
  userKey?: number,
  userBgColor?: string
}) => {
  const { authenticated } = useProps()
  const { t } = useTranslation(['landing'])
  const [openCount, setOpenCount] = useState(false)
  const [openSort, setOpenSort] = useState(false)

  const [dataSorted, setDataSorted] = useState<TableDataInterface[][]>([])

  const [countCategories] = useState<TableCountInterface[]>(tableCount)

  useEffect(() => {
    if (sorted) {
      let newData

      if (sorted.asc) {
        newData = onSortTableAsc(data, sorted.index)
      } else {
        newData = onSortTableDesc(data, sorted.index)
      }

      setDataSorted([...newData])
    } else {
      setDataSorted(data)
    }
  }, [data, sorted])

  const onToggle = (index: number) => {
    if (pages && page) {
      const newPage = page + index

      if (newPage > 0 && newPage <= pages) {
        handlerPage(newPage)
      }
    }
  }

  return (
    <>
      <div className="w-full">
        <div className="w-full flex items-center justify-between gap-12">
          {sorting && handlerSort && <div className="flex items-center gap-12">
            <div className="text-grey text-14">Sort by</div>
            <div className="relative">
              <button
                onClick={() => setOpenSort(!openSort)}
                className="px-16 h-48 flex gap-12 items-center rounded-6 transition-all group duration-300 bg-grey-light-1 dark:bg-grey-border dark:hover:bg-grey-med-2"
              >
                <div className="text-grey-light font-bold group-hover:text-black dark:group-hover:text-white text-16">
                  {sorted ? sorted.title : "Sort by"}
                </div>
                <MdKeyboardArrowDown className="text-grey-light group-hover:text-black dark:group-hover:text-white" />
              </button>
              <Wrapper open={openSort}>
                <div
                  onClick={() => setOpenSort(!openSort)}
                  className="fixed z-10 top-0 left-0 w-screen h-[100vh]"
                ></div>
                <div className="absolute z-20 min-w-full w-[150px] grid grid-cols-1 gap-4 top-[60px] left-0 rounded-6 bg-grey-light-1 dark:bg-grey-border py-8">
                  {sorting?.map((entry: TableSortingInterface, key: number) => (
                    <button
                      onClick={() => {
                        handlerSort(entry)
                        setOpenSort(!openSort)
                      }}
                      className="text-grey-light hover:text-black dark:hover:text-white text-16 py-2 px-16 text-left"
                      key={key}
                    >
                      {entry.title}
                    </button>
                  ))}
                </div>
              </Wrapper>
            </div>
          </div>}
          {count && handlerCount && <div className="flex items-center gap-12">
            <div className="text-grey text-14">{t("show")}</div>
            <div className="relative">
              <button
                onClick={() => setOpenCount(!openCount)}
                className="px-16 h-48 flex gap-12 items-center rounded-6 transition-all group duration-300 bg-grey-light-1 dark:bg-grey-border dark:hover:bg-grey-med-2"
              >
                <div className="text-grey-light font-bold group-hover:text-black dark:group-hover:text-white text-16">
                  {count}
                </div>
                <MdKeyboardArrowDown className="text-grey-light group-hover:text-black dark:group-hover:text-white" />
              </button>
              <Wrapper open={openCount}>
                <div
                  onClick={() => setOpenCount(!openCount)}
                  className="fixed z-10 top-0 left-0 w-screen h-[100vh]"
                ></div>
                <div className="absolute z-20 w-full grid grid-cols-1 gap-4 top-[60px] left-0 rounded-6 bg-grey-light-1 dark:bg-grey-border py-8">
                  {countCategories.map(
                    (category: TableCountInterface, key: number) => (
                      <button
                        onClick={() => {
                          handlerCount(category.count)
                          setOpenCount(!openCount)
                        }}
                        className="text-grey-light hover:text-black dark:hover:text-white text-16 py-2 px-16 text-left"
                        key={key}
                      >
                        {category.title}
                      </button>
                    )
                  )}
                </div>
              </Wrapper>
            </div>
          </div>}
        </div>
        {(sorting || count) && <AccountLine />}
        <div className="w-full overflow-x-scroll">
          <div className="w-full grid grid-cols-1 gap-16">
            {dataSorted.length > 0 ? (
              <>
                <div className="w-max min-w-full px-18 flex items-center justify-between gap-24">
                  {columns.map((column: TableColumnInterface, key: number) => (
                    <div
                      style={{ width: `${column.width}px` }}
                      className={`text-grey text-14 ${
                        key === columns.length - 1 ? "text-right" : ""
                      }`}
                      key={key}
                    >
                      {column.title}
                    </div>
                  ))}
                </div>
                <div className="w-full">
                  {dataSorted.map(
                    (entries: TableDataInterface[], key: number) => (
                      <div
                        className={`w-max min-w-full justify-between px-18 h-48 items-center rounded-6 flex gap-24 ${
                          (authenticated && (userKey !== undefined) && (userKey === key)) ? userBgColor : (key % 2 === 0 ? "bg-grey-light-1 dark:bg-grey-border" : "")
                        }`}
                        key={key}
                      >
                        {entries.map(
                          (entry: TableDataInterface, ke: number) => (
                            <div
                              style={{ width: `${columns[ke].width}px` }}
                              className={`flex items-center gap-6 text-14 ${
                                columns.length - 1 === ke ? "justify-end" : ""
                              } ${
                                entry.color ? entry.color : "text-grey-light"
                              }`}
                              key={ke}
                            >
                              {entry.icon ? entry.icon : ""}
                              {entry.prefix ?? ""}
                              {entry.text}
                            </div>
                          )
                        )}
                      </div>
                    )
                  )}
                </div>
              </>
            ) : (
              <div className="w-full rounded-6 h-48 flex items-center justify-center text-black dark:text-white text-14 bg-grey-light-1 dark:bg-grey-border">
                {t("no_data")}
              </div>
            )}
          </div>
        </div>
        <AccountLine />
        {pages && page ? <div className="w-full flex items-center justify-between gap-12">
          <div className="grid grid-cols-1 gap-4">
            <div className="w-full flex items-center justify-between">
              <div className="text-grey-light text-14">
                {pages && page} <span className="text-14 text-grey">/ {pages}</span>
              </div>
              <div className="text-grey-light text-14">
                {Math.round(pages && (page / pages * 100))}%
              </div>
            </div>
            <div className="w-[200px] h-4 bg-grey-light-1 dark:bg-grey-border">
              <div
                style={{ width: `${pages && (page / pages * 100)}%` }}
                className="h-4 bg-grey-light-0 transition-all duration-300"
              ></div>
            </div>
          </div>
          <div className="flex items-center gap-12">
            <Wrapper open={page > 1}>
              <button
                onClick={() => onToggle(-1)}
                className="text-grey-light text-16 hover:text-black dark:hover:text-white h-48 rounded-6 bg-grey-light-1 dark:bg-grey-border px-16"
              >
                {t("previous")}
              </button>
            </Wrapper>
            <Wrapper open={page < pages}>
              <button
                onClick={() => onToggle(1)}
                className="text-grey-light text-16 hover:text-black dark:hover:text-white h-48 rounded-6 bg-grey-light-1 dark:bg-grey-border px-16"
              >
                {t("next")}
              </button>
            </Wrapper>
          </div>
        </div> : ""}
      </div>
    </>
  )
}

export default AccountTable
