import { URL } from "libs/constants"
import React from "react"
import RouteUser from "router/route/RouteUser"
import Account from "views/Account"
import Affiliates from "views/Affiliates"
import Logout from "views/Logout"
import Rewards from "views/Rewards"

export default [
  {
    component: (
      <RouteUser exact path={URL.ACCOUNT.BASE} key={0}>
        <Account />
      </RouteUser>
    )
  },
  {
    component: (
      <RouteUser exact path={URL.ACCOUNT.TAB} key={1}>
        <Account />
      </RouteUser>
    )
  },
  {
    component: (
      <RouteUser path={URL.AFFILIATES} key={2}>
        <Affiliates />
      </RouteUser>
    )
  },
  {
    component: (
      <RouteUser path={URL.LOGOUT} key={4}>
        <Logout />
      </RouteUser>
    )
  },
  {
    component: (
      <RouteUser path={URL.REWARDS} key={5}>
        <Rewards />
      </RouteUser>
    )
  }
]
