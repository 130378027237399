import RewardsMenuLink from "components/rewards/RewardsMenuLink"
import { RewardType } from "libs/enums"
import { RewardTabInterface } from "libs/interfaces"
import React, { useState } from "react"
import { AiOutlineTrophy } from "react-icons/ai"
import { CgGames } from "react-icons/cg"
import { FaRegMoneyBillAlt } from "react-icons/fa"
import { MdOutlinePages, MdSettingsBackupRestore } from "react-icons/md"

const RewardsMenu = ({ tab, handler }: { tab: RewardType; handler: any }) => {
  const [pages] = useState<RewardTabInterface[]>([
    {
      title: "Rakeback",
      type: RewardType.Rakeback,
      icon: <MdSettingsBackupRestore className="text-20"/>
    },
    {
      title: "Promo code",
      type: RewardType.Promo,
      icon: <FaRegMoneyBillAlt className="text-20"/>
    },
    {
      title: "VIP",
      type: RewardType.VIP,
      icon: <AiOutlineTrophy className="text-20"/>
    },
    {
      title: "Free Games",
      type: RewardType.Freegame,
      icon: <CgGames className="text-20"/>
    }
  ])

  return (
    <>
      <div className="w-full bg-grey-light-3 dark:bg-grey-med rounded-10 py-20 sm:py-24">
        <div className="w-full px-20 sm:px-24 flex items-center gap-8 mb-12">
          <MdOutlinePages className="text-20 text-purple" />
          <div className="text-black dark:text-white text-16 sm:text-18">Rewards</div>
        </div>
        {pages.map((item: RewardTabInterface, key: number) => (
          <RewardsMenuLink link={item} key={key} tab={tab} handler={handler} />
        ))}
      </div>
    </>
  )
}

export default RewardsMenu
