import { onSoftswissReq } from "api/gamesApi"
import { getRewardGames } from "api/user"
import GenericButton from "components/buttons/GenericButton"
import GameButton from "components/game/GameButton"
import { useProps } from "contexts/PropsContext"
import { REQ, URL } from "libs/constants"
import { FreeGameButtonState, FreeGameStatus } from "libs/enums"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"

const RewardsFreeGame = ({ handler }:{ handler?: any }) => {
  const history = useHistory()
  const { coin } = useProps()
  const { t } = useTranslation(['landing'])

  const [games, setGames] = useState<any[]>([])

  useEffect(() => {
    onLoad()
  }, [])

  const onLoad = async () => {
    const rewardGames = await getRewardGames()
    if (rewardGames) {
      setGames(rewardGames)
    }
  }

  const onChangeGameStatus = (game: any, status: FreeGameStatus) => {
    const newStateGames = games.map(g => {
      if (g?.reward_id === game?.reward_id) {
        return { ...g, reward_status: status}
      }
    })

    setGames(newStateGames)
  }

  const onRewardGameClaim = async (game: any) => {
    switch (game?.reward_status) {
      case FreeGameStatus.Play:
        handler()
        history.push(URL.GAME.replace(":slug", game?.identifier).replace(":demo", "live"))
        location.reload()
        break
      case FreeGameStatus.Claim:
        onChangeGameStatus(game, FreeGameStatus.Claimed)
        onSoftswissReq(REQ.SLOTS.ISSUE_FREESPINS, { game: game?.identifier, coin, rewardId: game?.reward_id })
        .then((res) => res && toast.success(t('successful_freespin_issue')))
        .then(() => onLoad())
        break
      default:
        break
    }
  }

  const getButtonText = (game: any) => {
    switch (game?.reward_status) {
      case FreeGameStatus.Claim:
        return FreeGameButtonState.Claim
      case FreeGameStatus.Play:
        return FreeGameButtonState.Play
      case FreeGameStatus.Claimed:
        return FreeGameButtonState.Claimed
      case FreeGameStatus.Pending:
        return FreeGameButtonState.Pending
      default:
        return ''
    }
  }

  const getDisabledStatus = (game: any) => {
    return game?.reward_status !== FreeGameStatus.Claim && game?.reward_status !== FreeGameStatus.Play
  }

  const getButtonColor = (game: any) => {
    return game?.reward_status === FreeGameStatus.Play ? 'bg-green' : ''
  }

  return (
    <div className="w-full grid grid-cols-1 gap-24">
      <div className="w-full text-16 sm:text-18 text-black dark:text-white">
        {t("level_benefit_freegames")}
      </div>
      <div className="w-full text-14 sm:text-16 text-grey-light font-bold">
        {t("level_benefit_freegames_info")}
      </div>
      <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-24 overflow-y-auto">
        {games?.length > 0 && games.map((game: any, key:number) => (
          <div key={key} className="w-full grid grid-cols-1 gap-12">
            <GameButton game={game} />
            <GenericButton value={game} handler={onRewardGameClaim} text={getButtonText(game)} color={getButtonColor(game)} fontSize={20} disabled={getDisabledStatus(game)}/>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RewardsFreeGame
