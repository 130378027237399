export const SERVER = process.env.REACT_APP_SERVER ?? ""
export const SOCKET = process.env.REACT_APP_SOCKET ?? ""
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID ?? ""
export const BETBY_BRAND_ID = process.env.REACT_APP_BETBY_BRAND_ID ?? ""

export const URL = {
  ACCOUNT: {
    BASE: "/account",
    TAB: "/account/:tab"
  },
  ACTIVATE: "/activate/:email/:token",
  AFFILIATES: "/affiliates",
  CASINO: {
    BASE: "/casino",
    TAB: "/casino/:tab",
    CRAPS: "/casino/craps",
    CARD: "/casino/card",
    CASUAL: "/casino/casual",
    LOTTERY: "/casino/lottery",
    NEW: "/casino/new",
    POKER: "/casino/poker",
    POPULAR: "/casino/popular",
    SLOTS: "/casino/slots",
    ROULETTE: "/casino/roulette"
  },
  ABOUTUS: {
    TAB: "/aboutus/:tab",
    PRIVACY: "/aboutus/privacy",
    TOS: "/aboutus/tos",
    SELF_EXCLUSION: "/aboutus/selfexclusion",
  },
  TOURNAMENT: "/tournament",
  ANNOUNCEMENT: "/announcement",
  BETBY: "/sportsbook",
  FAIRNESS: "/fairness",
  FAQ: "/faq",
  FAVOURITE: "/favourite",
  FREE: "/free",
  GAME: "/game/:slug/:demo",
  GAMES: "/games",
  HOME: "/",
  LOGOUT: "/logout",
  MAINTENANCE: "/maintenance",
  PRIVACY: "/privacy",
  PASSWORD: {
    EMAIL: "/password/email",
    RESET: "/account/recover-password/:token"
  },
  PROMOTIONS: "/promotions",
  PROVABLY: "/provably",
  RECENT: "/recent",
  REWARDS: "/rewards",
  SPORTSBOOK: "/sportsbook",
  VIP: "/vip"
}

export const REQ = {
  ACCOUNT: SERVER + "/account/user",
  BETBY: SERVER + "/betby/token",
  AFFILIATE: {
    CLAIM: {
      RAKE: SERVER + "/affiliate/claim-rakeback",
      AFFILIATE: SERVER + "/affiliate/claim-affiliate"
    },
    GET: SERVER + "/affiliate",
    USE: SERVER + "/affiliate/use-code"
  },
  PROMO: {
    USE: SERVER + "/promo/use-code"
  },
  AUTH: {
    ACTIVATE: SERVER + "/auth/register/activate/:param",
    FORGOT: SERVER + "/auth/forgot-password",
    GET: SERVER + "/auth",
    LOGIN: SERVER + "/auth/login",
    LOGOUT: SERVER + "/auth/logout",
    MFA: SERVER + "/auth/login/mfa",
    REGISTER: SERVER + "/auth/register",
    RESEND: SERVER + "/auth/register/resend-activation",
    RESET: SERVER + "/auth/reset-password",
    SOCIAL: SERVER + "/auth/social/register"
  },
  GAMES: {
    SEARCH: SERVER + "/games/search"
  },
  PROVIDERS: {
    SEARCH: SERVER + "/providers/search"
  },
  PAYMENTS: {
    COIN: SERVER + "/payments/:coin/wallet",
    WALLETS: SERVER + "/payments/wallets",
    WITHDRAW: SERVER + "/payments/:coin/withdraw"
  },
  SITE: {
    BETS: SERVER + "/site/bets",
    GET: SERVER + "/site"
  },
  SLOTS: {
    DEMO: SERVER + "/slots/demo",
    START: SERVER + "/slots/start",
    ISSUE_FREESPINS: SERVER + "/slots/issue-freespins",
    CREATE_FREESPINS: SERVER + "/slots/create-freespins",
    CANCEL_FREESPINS: SERVER + "/slots/cancel-freespins",
    ADD_FAVOURITE: SERVER + "/slots/add-favourite"
  },
  TRANSACTIONS: {
    GET: SERVER + "/transactions"
  },
  USER: {
    BETS: SERVER + "/user/bets",
    GET: SERVER + "/user",
    GET_LEVEL: SERVER + "/user/level-progress",
    MFA: {
      START: SERVER + "/user/start-mfa",
      REMOVE: SERVER + "/user/remove-mfa",
      VERIFY: SERVER + "/user/verify-mfa"
    },
    PASSWORD: {
      CHANGE: SERVER + "/user/change-password"
    },
    TRANSACTIONS: SERVER + "/user/transactions",
    TOGGLE: {
      INCOGNITO: SERVER + "/user/toggle-incognito"
    },
    INTEGRATE_LOOKSRARE: SERVER + "/user/looksrare-avatar",
    REWARDS: {
      GAMES: SERVER + "/user/reward-games"
    },
    DISCORD_NAME: SERVER + "/user/discord-name",
    WALLET_COIN: SERVER + "/user/change-wallet-coin"
  },
  UPLOAD: {
    AVATAR: SERVER + "/upload/user-avatar",
  },
  CHAT: {
    MESSAGES: SERVER + "/chat",
    ADD_MESSAGE: SERVER + "/chat/message"
  },
  TOURNAMENT: {
    RANKING: SERVER + "/tournament",
    DATES: SERVER + "/tournament/dates",
  }
}

export const SOCIAL = {
  DISCORD: "https://discord.com/invite/3sEmVxrUAs",
  INSTAGRAM: "https://www.instagram.com/gamblingapes",
  TWITTER: "https://mobile.twitter.com/gamblingapescom",
  LOOKSRARE: "https://looksrare.org/collections/0x90cA8a3eb2574F937F514749ce619fDCCa187d45"
}

export const DATE = {
  DATETIME: "MMM D YYYY, HH:mm",
  SHORT: "MMM D, YYYY"
}

export const AVATAR_MAX_SIZE = 2 * 1024 * 1024
export const FILE_TOO_LARGE_CODE = 'file-too-large'
export const MAX_CHAT_MESSAGE_LENGTH = 255
