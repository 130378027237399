import React from "react"

const WrapperPage = ({ children }: { children: any }) => {
  return (
    <>
      <div
        className={`w-full p-24 sm:p-32 flex justify-center mt-70`}
      >
        <div className="w-full max-w-screen-xl">{children}</div>
      </div>
    </>
  )
}

export default WrapperPage
