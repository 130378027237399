import Overlays from "components/overlays/Overlays"
import { URL } from "libs/constants"
import { OverlayType } from "libs/enums"
import React from "react"
import { Route } from "react-router-dom"
import Activate from "views/Activate"
import Home from "views/Home"
import NotFound from "views/NotFound"
import ProvablyFair from "views/ProvablyFair"
import Reset from "views/Reset"

export default [
  {
    component: (
      <Route exact path={URL.HOME} key={0}>
        <Home />
      </Route>
    )
  },
  {
    component: (
      <Route path={URL.ACTIVATE} key={2}>
        <Activate />
      </Route>
    )
  },
  {
    component: (
      <Route path={URL.MAINTENANCE} key={3}>
        <Overlays overlay={OverlayType.Maintenance} />
      </Route>
    )
  },
  {
    component: (
      <Route path={URL.PASSWORD.RESET} key={4}>
        <Reset />
      </Route>
    )
  },
  {
    component: (
      <Route path={URL.PROVABLY} key={5}>
        <ProvablyFair />
      </Route>
    )
  },
  {
    component: (
      <Route exact path={URL.SPORTSBOOK} key={6}>
        <Home />
      </Route>
    )
  },
  {
    component: (
      <Route key={7}>
        <NotFound />
      </Route>
    )
  }
]
