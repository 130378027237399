import Curacao from "assets/img/curacao.png"
import { ReactComponent as Logo } from "assets/img/logo.svg"
import Bitcoin from "assets/img/methods/bitcoin.png"
import Ethereum from "assets/img/methods/ethereum.png"
import Litecoin from "assets/img/methods/litecoin.png"
import Responsible from "assets/img/responsible.png"
import FooterButton from "components/footer/FooterButton"
import FooterButtonLink from "components/footer/FooterButtonLink"
import FooterLink from "components/footer/FooterLink"
import FooterTitle from "components/footer/FooterTitle"
import { useProps } from "contexts/PropsContext"
import { SOCIAL, URL } from "libs/constants"
import React from "react"
import { useTranslation } from "react-i18next"

const Footer = () => {
  const { openRewards, openAffiliates, setOpenAffiliates, darkMode, onRewardModalOpen } = useProps()
  const { t } = useTranslation(['landing'])

  return (
    <>
      <div className="bg-grey-light-3 dark:bg-grey-med w-full px-20 sm:px-24 flex justify-center py-20 sm:py-30 md:py-40 lg:py-60">
        <div className="w-full max-w-screen-xl grid grid-cols-1 gap-20 md:gap-32">
          <div className="w-full grid grid-cols-1 lg:grid-cols-[1fr,auto] gap-40 sm:gap-50 md:gap-60 items-start">
            <div className="w-full grid grid-cols-1 gap-24 items-start">
              <div className="w-full flex justify-star">
                <Logo fill={darkMode ? "white" : "dark"} />
              </div>
              <div className="w-full text-14 text-grey-light">
                {t("copyright")}
              </div>
            </div>
            <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-[auto,auto,auto,auto] gap-20 sm:gap-30 md:gap-40 items-start">
              <div className="grid grid-cols-1 gap-20 items-start">
                <FooterTitle title="Casino" />
                <div className="grid grid-cols-1 gap-12">
                  <FooterLink title={t("popular_games")} link={URL.CASINO.POPULAR} />
                  <FooterLink title={t("slots")} link={URL.CASINO.SLOTS} />
                  <FooterLink title={t("poker")} link={URL.CASINO.POKER} />
                  <FooterLink title={t("blackjack")} link={URL.CASINO.CARD} />
                  <FooterLink title={t("roulette")} link={URL.CASINO.ROULETTE} />
                  <FooterLink title={t("new_games")} link={URL.CASINO.NEW} />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-20 items-start">
                <FooterTitle title="Support" />
                <div className="grid grid-cols-1 gap-12">
                  <div className="text-grey-light text-16 font-bold hover:text-black dark:hover:text-white break-all">support@gamblingapes.com</div>
                  <FooterButton title={t("affiliates")} action={() => setOpenAffiliates(!openAffiliates)} />
                  <FooterButton title={t("rewards")} action={() => onRewardModalOpen(!openRewards)} />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-20 items-start">
                <FooterTitle title="About Us" />
                <div className="grid grid-cols-1 gap-12">
                  <FooterLink title={t("privacy_policy")} link={URL.ABOUTUS.PRIVACY} />
                  <FooterLink title={t("terms_of_service")} link={URL.ABOUTUS.TOS} />
                  <FooterLink title={t("self_exclusion")} link={URL.ABOUTUS.SELF_EXCLUSION} />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-20 items-start">
                <FooterTitle title="Social Media" />
                <div className="grid grid-cols-1 gap-12">
                  <FooterButtonLink title={t("instagram")} link={SOCIAL.INSTAGRAM} />
                  <FooterButtonLink title={t("twitter")} link={SOCIAL.TWITTER} />
                  <FooterButtonLink title={t("discord")} link={SOCIAL.DISCORD} />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full border-b-1 border-grey-med-2"></div>
          <div className="w-full grid grid-cols-1 gap-16">
            <div className="w-full grid md:flex grid-cols-2 sm:grid-cols-4 md:justify-center gap-16">
              <img src={Bitcoin} alt="" className="w-full md:w-auto h-40" />
              <img src={Ethereum} alt="" className="w-full md:w-auto h-40" />
              <img src={Litecoin} alt="" className="w-full md:w-auto h-40" />
            </div>
            <div className="w-full flex justify-center">
              <img src={Responsible} alt="" />
            </div>
          </div>
          <div className="w-full border-b-1 border-grey-med-2"></div>
          <div className="w-full grid grid-cols-1 lg:grid-cols-[1fr,auto] gap-24 md:gap-32">
            <div className="w-full text-14 text-grey-light">
              {t("gambling_apes")}
            </div>
            <img src={Curacao} alt="" className="h-48" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
