import { parseError } from "api/error"
import axios from "axios"
import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import WrapperPage from "components/wrapper/WrapperPage"
import { useProps } from "contexts/PropsContext"
import { REQ } from "libs/constants"
import React, { useEffect, useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { useTranslation } from "react-i18next"
import { MdContentCopy } from "react-icons/md"
import NumberFormat from "react-number-format"
import { toast } from "react-toastify"

const Affiliates = () => {
  const { authenticated, coin } = useProps()

  const { t } = useTranslation(['landing'])

  let affiliateSite
  if (typeof window !== 'undefined') {
    affiliateSite = location.protocol + '//' + location.host
  }

  const [site] = useState(affiliateSite || "https://gamblingapes.com")
  const [link, setLink] = useState("https://gamblingapes.com")

  const [balance, setBalance] = useState(0)
  const [code, setCode] = useState("")

  const [totalEarned, setTotalEarned] = useState(0)
  const [totalRefs, setTotalRefs] = useState(0)
  const [totalPercent, setTotalPercent] = useState(10)

  useEffect(() => {
    onLoad()
  }, [])

  useEffect(() => {
    setLink(site + `?code=${code}`)
  }, [code])

  const onLoad = async () => {
    if (!authenticated) {
      return
    }

    const response = await axios({
      url: REQ.AFFILIATE.GET,
      method: "get",
      data: {},
      withCredentials: true
    })
      .then((res: any) => res.data.response)
      .catch((error) => {
        parseError(error)
      })

    if (response) {
      setBalance(response.balance)
      setCode(response.code)

      setTotalEarned(response.total_earned)
      setTotalPercent(response.return_percent)
      setTotalRefs(response.total_refs)
    }
  }

  const onClaim = () => {
    if (balance === 0) {
      toast.error("No earnings left to be claimed")
      return
    }

    axios({
      url: REQ.AFFILIATE.CLAIM.AFFILIATE,
      method: "post",
      data: { coin: coin },
      withCredentials: true
    })
      .then((res: any) => res.data.response)
      .catch((error) => {
        parseError(error)
      })
  }

  return (
    <>
      <Nav />
      <WrapperPage>
        <div className="w-full grid grid-cols-1 gap-32">
          <div className="w-full bg-grey-light-3 dark:bg-grey-med rounded-10 p-24 sm:p-32 grid grid-cols-1 gap-22">
            <div className="w-full font-black text-black dark:text-white text-18 sm:text-24 md:text-32">
              <span className="text-green font-black text-18 sm:text-24 md:text-32">
                {t("start_earning")}
              </span>{" "}
              {t("spread_the_word")}
            </div>
            <div className="w-full grid grid-cols-1 xl:grid-cols-[1fr,auto,auto] gap-24">
              <div className="w-full grid grid-cols-1 gap-24">
                <div className="w-full grid grid-cols-1 gap-16">
                  <div className="w-full text-grey-light text-14 sm:text-16">
                    {t("percent_on_the_house_earning")}
                  </div>
                  <div className="w-full text-grey-light text-14 sm:text-16">
                    {t("share_affiliate_link")}
                  </div>
                </div>
                <div className="w-full bg-grey-light-2 dark:bg-grey-med-2 rounded-6 grid grid-cols-[1fr,auto] h-52">
                  <input
                    type="text"
                    className="w-full px-16 h-52 flex items-center text-grey-light text-14 sm:text-16"
                    placeholder="Affiliate link"
                    value={link}
                    readOnly
                  />
                  <CopyToClipboard
                    text={link}
                    onCopy={() => toast.success("Copied!")}
                  >
                    <button className="w-52 h-52 rounded-6 flex items-center justify-center group">
                      <MdContentCopy className="text-grey-light group-hover:text-black dark:group-hover:text-white text-18" />
                    </button>
                  </CopyToClipboard>
                </div>
                <div className="w-full bg-grey-light-2 dark:bg-grey-med-2 rounded-6 grid grid-cols-[1fr,auto] h-52">
                  <input
                    type="text"
                    className="w-full px-16 h-52 flex items-center text-grey-light text-14 sm:text-16"
                    placeholder="Affiliate code"
                    value={code}
                    readOnly
                  />
                  <CopyToClipboard
                    text={code}
                    onCopy={() => toast.success("Copied!")}
                  >
                    <button className="w-52 h-52 rounded-6 flex items-center justify-center group">
                      <MdContentCopy className="text-grey-light group-hover:text-black dark:group-hover:text-white text-18" />
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
              <div className="border-b-1 xl:border-r-1 border-grey-med-2 h-full w-full xl:w-auto"></div>
              <div className="w-full xl:w-[450px] grid grid-cols-1">
                <div className="w-full mb-8 text-14 sm:text-16 text-grey-light uppercase">
                  {t("available_earnings")}
                </div>
                <div className="w-full mb-24">
                  <NumberFormat
                    value={Math.round(balance) / 100}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={true}
                    className="text-green text-16 sm:text-18 md:text-24 font-bold"
                  />
                </div>
                <button
                  disabled={balance === 0}
                  onClick={() => onClaim()}
                  className="w-full justify-center h-52 bg-green rounded-6 flex items-center text-black dark:text-white font-bold text-14 sm:text-16"
                >
                  {t("claim_earnings")}
                </button>
              </div>
            </div>
          </div>
          <div className="w-full"></div>
        </div>
        <div className="w-full gap-24">
          <div className="w-full grid grid-cols-3 gap-24">
            <div className="w-full p-16 sm:p-24 rounded-6 bg-grey-light-3 dark:bg-grey-med grid grid-cols-1 gap-4">
              <div className="w-full text-grey-light text-14 sm:text-16 uppercase">
                {t("total_earned")}
              </div>
              <div className="w-full">
                <NumberFormat
                  value={totalEarned / 100}
                  displayType={"text"}
                  prefix={"$"}
                  thousandSeparator={true}
                  className="text-black dark:text-white text-18 sm:text-20 font-bold"
                />
              </div>
            </div>
            <div className="w-full p-16 sm:p-24 rounded-6 bg-grey-light-3 dark:bg-grey-med grid grid-cols-1 gap-4">
              <div className="w-full text-grey-light text-14 sm:text-16">
                {t("referrals")}
              </div>
              <div className="w-full">
                <NumberFormat
                  value={totalRefs}
                  displayType={"text"}
                  thousandSeparator={true}
                  className="text-black dark:text-white text-18 sm:text-20 font-bold"
                />
              </div>
            </div>
            <div className="w-full p-16 sm:p-24 rounded-6 bg-grey-light-3 dark:bg-grey-med grid grid-cols-1 gap-4">
              <div className="w-full text-grey-light text-14 sm:text-16">
                {t("percentage_cut")}
              </div>
              <div className="w-full">
                <NumberFormat
                  value={totalPercent}
                  suffix={"%"}
                  displayType={"text"}
                  thousandSeparator={true}
                  className="text-black dark:text-white text-18 sm:text-20 font-bold"
                />
              </div>
            </div>
          </div>
        </div>
      </WrapperPage>
      <Footer />
    </>
  )
}

export default Affiliates
