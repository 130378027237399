import GameButton from "components/game/GameButton"
import GameButtonSmall from "components/game/GameButtonSmall"
import { useProps } from "contexts/PropsContext"
import { URL } from "libs/constants"
import { GameInterface } from "libs/interfaces"
import React from "react"
import { useHistory } from "react-router-dom"

const HomeSectionGame = ({ game, small }: { game: GameInterface, small?: boolean }) => {
  const { authenticated } = useProps()
  const history = useHistory()


  const onToggleDemoGame = () => {
    history.push(URL.GAME.replace(":slug", game.identifier).replace(":demo", "demo"))
    location.reload()
  }

  const onToggleLiveGame = () => {
    history.push(URL.GAME.replace(":slug", game.identifier).replace(":demo", "live"))
    location.reload()
  }

  const onHandleGameOpen = () => {
    if (authenticated) {
      return onToggleLiveGame()
    }
    onToggleDemoGame()
  }

  return (
    <>
      {!small ? (
        <GameButton game={game} handler={onHandleGameOpen} />
      ) : (
        <GameButtonSmall game={game} handler={onHandleGameOpen}/>
      )}
    </>
  )
}

export default HomeSectionGame
