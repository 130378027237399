import Modal from "components/modals/Modal"
import ModalAuth from "components/modals/ModalAuth"
import { AuthType } from "libs/enums"
import React from "react"

const ModalRegister = ({ open, handler, affiliateCode }: { open: boolean; handler: any, affiliateCode?: string | null }) => {
  return (
    <>
      <Modal open={open} handler={handler}>
        <ModalAuth type={AuthType.Register} open={open} handler={handler} affiliateCode={affiliateCode} />
      </Modal>
    </>
  )
}

export default ModalRegister
