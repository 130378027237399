export enum AccountTabType {
  General,
  Security,
  Privacy,
  Verification,
  Bets,
  Deposits,
  Withdrawals,
  Code
}

export enum AuthType {
  Login,
  Register
}

export enum BetCategoryType {
  My,
  All,
  High
}

export enum CurrencyType {
  Bitcoin
}

export enum DepositType {
  Deposit,
  Withdraw
}

export enum LanguageType {
  English
}

export enum OverlayType {
  None,
  Forbidden,
  Maintenance,
  NotFound,
  ForbiddenVpn,
}

export enum ProvablyTabType {
  Parties,
  Random,
  Server,
  Overview
}

export enum ProviderType {
  None,
  Evolution,
  GameArt,
  WazDan,
  Habanero,
  EvoPlay,
  BGaming
}

export enum RewardType {
  VIP,
  Freegame,
  Rakeback,
  Promo
}

export enum TabFilterType {
  Name,
  RTP
}

export enum FreeGameStatus {
  Play,
  Claim,
  Claimed,
  Pending,
}

export enum FreeGameButtonState {
  Play = 'Play',
  Claim = 'Claim',
  Claimed = 'Claimed',
  Pending = 'Pending',
}
