import Modal from "components/modals/Modal"
import ModalClose from "components/modals/ModalClose"
import { TwofactorInterface } from "libs/interfaces"
import React from "react"

const ModalTwoFactorBackup = ({
  open,
  handler,
  tfa
}: {
  open: boolean
  handler: any
  tfa: TwofactorInterface
}) => {
  return (
    <>
      <Modal open={open} handler={handler}>
        <div className="bg-grey-light-3 dark:bg-grey-med rounded-10 grid grid-cols-1 w-[500px] max-w-full relative">
          <div className="w-full grid grid-cols-1 gap-12 p-24 border-b-1 border-grey-med-2">
            <div className="w-full text-16 sm:text-18 text-black dark:text-white">
              Save 2FA Recovery codes
            </div>
            <div className="w-full text-14 text-grey-light">
              It&apos;s really important that you save this recovery code! If
              you lose access to your 2FA device, you can use this code to reset
              your 2FA. Keep it in a safe place!
            </div>
          </div>
          <div className="w-full grid grid-cols-2 gap-12 p-24">
            {tfa.recoveryString.split(" ").map((code, key: number) => (
              <div
                className="w-full px-10 grid grid-cols-[auto,1fr] gap-12 h-48 rounded-6 bg-grey-light-2 dark:bg-grey-med-2 items-center"
                key={key}
              >
                <div className="w-28 h-28 rounded-full font-bold bg-grey-light-0 text-grey-med-2 text-14 flex justify-center items-center">
                  {key + 1}
                </div>
                <div className="w-full text-black dark:text-white text-14 sm:text-16">
                  {code}
                </div>
              </div>
            ))}
          </div>
          <ModalClose open={open} handler={handler} />
        </div>
      </Modal>
    </>
  )
}

export default ModalTwoFactorBackup
