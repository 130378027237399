import AccountTitle from "components/account/elements/AccountTitle"
import ProvablyMenuLink from "components/provably/elements/ProvablyMenuLink"
import { ProvablyTabType } from "libs/enums"
import { ProvablyTabInterface } from "libs/interfaces"
import React, { useState } from "react"
import { MdOutlineShield } from "react-icons/md"

const ProvablyMenu = ({
  tab,
  setTab
}: {
  tab: ProvablyTabType
  setTab: any
}) => {
  const [links] = useState<ProvablyTabInterface[]>([
    {
      title: "Overview",
      type: ProvablyTabType.Overview,
      icon: <MdOutlineShield />
    },
    {
      title: "Server",
      type: ProvablyTabType.Server,
      icon: <MdOutlineShield />
    },
    {
      title: "3rd Party",
      type: ProvablyTabType.Parties,
      icon: <MdOutlineShield />
    },
    {
      title: "Random.org",
      type: ProvablyTabType.Random,
      icon: <MdOutlineShield />
    }
  ])

  return (
    <>
      <div className="w-full bg-grey-light-3 dark:bg-grey-med rounded-10 py-20 sm:py-24">
        <div className="w-full px-20 sm:px-24">
          <AccountTitle title={"Links"} />
        </div>
        {links.map((item: ProvablyTabInterface, key: number) => (
          <ProvablyMenuLink tab={tab} setTab={setTab} link={item} key={key} />
        ))}
      </div>
    </>
  )
}

export default ProvablyMenu
