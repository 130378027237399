import axios from "axios"
import { REQ } from "libs/constants"

export const getTournamentRanking = async () => {
  const response = await axios({
      url: REQ.TOURNAMENT.RANKING,
      method: "get",
      data: {},
      withCredentials: true
  })

  if (response?.data?.success) {
      return response?.data?.response
  }
  return null
}

export const getTournamentDates = async () => {
  const response = await axios({
      url: REQ.TOURNAMENT.DATES,
      method: "get",
      data: {},
      withCredentials: true
  })

  if (response?.data?.success) {
      return response?.data?.response
  }
  return null
}
