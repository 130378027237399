import { GameInterface } from "libs/interfaces"
import React from "react"

const GameButtonSmall = ({
  game,
  handler
}: {
  game: GameInterface
  handler: any
}) => {
  return (
    <button
      onClick={() => handler()}
      className="w-full flex gap-8"
    >
      <img src={game.image} alt="" className="w-48 h-48 rounded-10" />
      <div className="w-full grid grid-cols-1 justify-start">
        <div className="w-full text-14 text-black dark:text-white text-left overflow-ellipsis truncate">
          {game.title}
        </div>
        <div className="w-full text-12 text-left text-grey-light-1 capitalize">
          {game.gameProvider.title}
        </div>
      </div>
    </button>
  )
}

export default GameButtonSmall
