import { parseError } from "api/error"
import axios from "axios"
import { useProps } from "contexts/PropsContext"
import { REQ } from "libs/constants"
import { rakebacksByLevel } from "libs/data"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import NumberFormat from "react-number-format"
import { toast } from "react-toastify"

const RewardsRakeback = () => {
  const { coin, user, onWallets, onGetUser, userProgress } = useProps()
  const { t } = useTranslation(['landing'])
  const userLevel: keyof typeof rakebacksByLevel = userProgress?.level || 1

  const [balance, setBalance] = useState(0)
  const [affiliatedUser, setAffiliatedUser] = useState<string>()

  useEffect(() => {
    onLoad()
  }, [user?.rakeback_balance])

  const onLoad = () => {
    if (user) {
      setBalance(user.rakeback_balance)
      setAffiliatedUser(user.referral_links?.[0]?.affiliate?.user?.display_name)
    }
  }

  const onClaim = async () => {
    if (balance === 0) {
      toast.error(t("no_earnings_left"))
      return
    }

    const response = await axios({
      url: REQ.AFFILIATE.CLAIM.RAKE,
      method: "post",
      data: { coin: coin },
      withCredentials: true
    })
      .then((res: any) => res.data.response)
      .catch((error) => {
        parseError(error)
      })

    if (response) {
      toast.success(t("success_claim_rakeback"))

      setBalance(0)
      await onWallets()
      await onGetUser()
    }
  }

  return (
    <>
      <div className="w-full mb-12 text-16 sm:text-18 text-black dark:text-white">{t("rakeback")}</div>
      {affiliatedUser ? (
        <div className="mb-30 w-full text-14 text-grey-light">
          {t("gambling_apes_shares", { value: rakebacksByLevel[userLevel] })}
          {" "}Thanks to{" "}
          <span className="text-14 text-black dark:text-white">{affiliatedUser}</span> you are getting{" "}
          <span className="text-14 text-black dark:text-white">{affiliatedUser === 'recoonier' ? '2x' : '1.5x'}</span> rakeback boost.
        </div>
        ) : (
        <div className="mb-30 w-full text-14 text-grey-light">
          {t("gambling_apes_shares", { value: rakebacksByLevel[userLevel] })}
        </div>
      )}
      <div className="w-full mb-12 text-14 sm:text-16 text-grey-light">
        {t("current_rakeback")}
      </div>
      <div className="w-full mb-16 flex items-center justify-between px-16 h-52 bg-grey-light-1 dark:bg-grey-med-3 rounded-6">
        <div className="flex">
          <NumberFormat
            value={balance / 100}
            displayType={"text"}
            prefix={"$"}
            thousandSeparator={true}
            className="text-14 sm:text-16 text-black dark:text-white"
          />
        </div>
      </div>
      <button
        disabled={balance === 0}
        onClick={() => onClaim()}
        className="w-full bg-purple rounded-6 h-52 flex items-center justify-center text-black dark:text-white text-16 font-bold"
      >
        {t("claim_reload")}
      </button>
    </>
  )
}

export default RewardsRakeback
