import Wrapper from "components/wrapper/Wrapper"
import { categories } from "libs/data"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { MdKeyboardArrowDown } from "react-icons/md"

const CategoriesPopup = ({ category, setCategory }:{category: string, setCategory: any}) => {
    const { t } = useTranslation(['landing'])

    const [openCategory, setOpenCategory] = useState(false)

    const onToggleCategory = (entry: string) => {
        if (category === entry) {
            return setCategory("")
        }
        setCategory(entry)
    }

    return (
        <>
            <div className="relative">
                <button
                    onClick={() => setOpenCategory(!openCategory)}
                    className="px-16 h-48 flex gap-12 items-center rounded-6 transition-all group duration-300 bg-grey-light-1 dark:bg-grey-border"
                >
                    <div className={`${category ? "text-black dark:text-white" : "text-grey-light"} font-bold group-hover:text-black dark:group-hover:text-white text-16 capitalize`}>
                        {category || t("categories")}
                    </div>
                    <MdKeyboardArrowDown className="text-grey-light group-hover:text-black dark:group-hover:text-white" />
                </button>
                <Wrapper open={openCategory}>
                    <div
                        onClick={() => setOpenCategory(!openCategory)}
                        className="fixed z-10 top-0 left-0 w-screen h-[100vh]"
                    ></div>
                    <div className="absolute z-20 w-[150px] overflow-x-auto grid grid-cols-1 top-[60px] left-0 rounded-6">
                        {categories.map(
                            (categoryEntry: any, key: number) => (
                                <button
                                    onClick={() =>
                                        onToggleCategory(categoryEntry.identifier)
                                    }
                                    className={`p-12 text-left ${
                                        category === categoryEntry.identifier
                                        ? "bg-grey-light"
                                        : "bg-grey-light-1 dark:bg-grey-border"
                                    }`}
                                    key={key}
                                >
                                <div className="text-black dark:text-white text-15">{categoryEntry.title}</div>
                                </button>
                            )
                        )}
                    </div>
                </Wrapper>
            </div>
        </>
    )
}

export default CategoriesPopup
