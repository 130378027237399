import React from "react"

const ProvablyText = ({ text }: { text: JSX.Element }) => {
  return (
    <>
      <div className="w-full mb-16 text-grey text-14 sm:text-16">{text}</div>
    </>
  )
}

export default ProvablyText
