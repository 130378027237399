import { openLink } from "libs/functions"
import React from "react"

const FooterButtonLink = ({ title, link }: { title: string; link: string }) => {
  return (
    <>
      <button
        onClick={(e) => openLink(e, link)}
        className="w-full text-grey-light text-left text-16 font-bold hover:text-black dark:hover:text-white"
      >
        {title}
      </button>
    </>
  )
}

export default FooterButtonLink
