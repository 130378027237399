import { URL } from "libs/constants"
import React from "react"
import { Route } from "react-router-dom"
import Announcement from "views/Announcement"
import Betby from "views/Betby"
import Home from "views/Home"
import Tournament from "views/Tournament"

export default [
  {
    component: (
      <Route exact path={URL.CASINO.BASE} key={0}>
        <Home />
      </Route>
    )
  },
  {
    component: (
      <Route path={URL.CASINO.TAB} key={1}>
        <Home />
      </Route>
    )
  },
  {
    component: (
      <Route path={URL.TOURNAMENT} key={2}>
        <Tournament />
      </Route>
    )
  },
  {
    component: (
      <Route path={URL.ANNOUNCEMENT} key={3}>
        <Announcement />
      </Route>
    )
  },
  {
    component: (
      <Route path={URL.BETBY} key={4}>
        <Betby />
      </Route>
    )
  }
]
