import Wrapper from "components/wrapper/Wrapper"
import { GameInterface } from "libs/interfaces"
import React from "react"
import { MdInfo } from "react-icons/md"
import NumberFormat from "react-number-format"

const GameInfoPopup = ({ game }: { game: GameInterface }) => {
  return (
    <div className="relative group">
        <MdInfo className="text-grey-light hover:text-black dark:hover:text-white text-18 relative" />
        <div className="group-hover:grid hidden transition-all duration-300 absolute bottom-24 right-0 bg-grey-light-1 dark:bg-grey-med-3 rounded-6 p-14 w-[160px] grid-cols-1">
        <div className="w-full bg-grey-light-2 dark:bg-grey-med-2 rounded-4 px-16 h-44 flex items-center justify-between gap-12">
            <div className="text-grey-light text-14 font-semibold">
            RTP
            </div>
            <div className="">
            <NumberFormat
                value={game.payout}
                displayType={"text"}
                suffix={"%"}
                thousandSeparator={true}
                className="text-black dark:text-white text-14 font-semibold"
            />
            </div>
        </div>
        <div className="w-full rounded-4 px-16 h-44 flex items-center justify-between gap-12">
            <div className="text-grey-light text-14 font-semibold">
            Theme
            </div>
            <div className="text-14 text-black dark:text-white font-semibold capitalize">
            {game.theme}
            </div>
        </div>
        <div className="w-full rounded-4 bg-grey-light-2 dark:bg-grey-med-2 px-16 h-44 flex items-center justify-between gap-12">
            <div className="text-grey-light text-14 font-semibold">
            Group
            </div>
            <div className="text-14 text-black dark:text-white font-semibold capitalize">
            {game.feature_group}
            </div>
        </div>
        {game.hd || game.has_freespins ? (
            <div className="w-full flex items-center justify-start mt-12 gap-6">
            <Wrapper open={game.hd}>
                <div className="rounded-full border-1 dark:border-grey-med-2 text-black dark:text-grey-light text-12 px-12 h-24 flex items-center">
                HD
                </div>
            </Wrapper>
            <Wrapper open={game.has_freespins}>
                <div className="rounded-full border-1 dark:border-grey-med-2 text-black dark:text-grey-light text-12 px-12 h-24 flex items-center">
                Free spins
                </div>
            </Wrapper>
            </div>
        ) : (
            ""
        )}
        </div>
    </div>
  )
}

export default GameInfoPopup
