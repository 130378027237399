import Modal from "components/modals/Modal"
import ModalAuth from "components/modals/ModalAuth"
import { AuthType } from "libs/enums"
import React from "react"

const ModalLogin = ({
  open,
  handler,
  handlerReset
}: {
  open: boolean
  handler: any
  handlerReset: any
}) => {
  return (
    <>
      <Modal open={open} handler={handler}>
        <ModalAuth
          type={AuthType.Login}
          open={open}
          handler={handler}
          handlerReset={handlerReset}
        />
      </Modal>
    </>
  )
}

export default ModalLogin
