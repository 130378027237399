import { ProvablyTabType } from "libs/enums"
import { ProvablyTabInterface } from "libs/interfaces"
import React from "react"

const ProvablyMenuLink = ({
  tab,
  setTab,
  link
}: {
  tab: ProvablyTabType
  setTab: any
  link: ProvablyTabInterface
}) => {
  return (
    <>
      <button
        onClick={() => setTab(link.type)}
        className={`w-full px-20 h-48 items-center dark:hover:bg-purple-5 sm:px-24 relative group grid grid-cols-[auto,1fr] gap-16 ${
          tab === link.type ? "bg-purple-5" : ""
        }`}
      >
        <div
          className={`flex first:text-24 group-hover:first:text-black dark:group-hover:first:text-purple ${
            tab === link.type ? "first:text-purple" : "first:text-grey"
          }`}
        >
          {link.icon}
        </div>
        <div
          className={`w-full text-left group-hover:text-black dark:group-hover:text-white text-16 ${
            tab === link.type ? "text-black dark:text-white" : "text-grey"
          }`}
        >
          {link.title}
        </div>

        <div
          className={`absolute right-0 top-0 bottom-0 w-2 bg-purple dark:group-hover:opacity-100 ${
            tab === link.type ? "opacity-100" : "opacity-0"
          }`}
        ></div>
      </button>
    </>
  )
}

export default ProvablyMenuLink
