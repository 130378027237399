import Footer from "components/footer/Footer"
import HomeFull from "components/home/HomeFull"
import HomeTab from "components/home/HomeTab"
import Nav from "components/nav/Nav"
import WrapperPage from "components/wrapper/WrapperPage"
import { Params } from "libs/interfaces"
import React from "react"
import { useParams } from "react-router-dom"
// import { debounce } from "lodash" THIS IS FOR DEBOUNCED SEARCH

const Home = () => {
  const { tab } = useParams<Params>()

  return (
    <>
      <Nav />
      <WrapperPage>
        <div className="w-full grid grid-cols-1 gap-32">
          {tab ? (
            <HomeTab />
          ) : (
            <HomeFull/>
          )}
        </div>
      </WrapperPage>
      <Footer />
    </>
  )
}

export default Home
