import NotFound from "assets/img/notfound.png"
import { URL } from "libs/constants"
import React from "react"
import { Link } from "react-router-dom"

const OverlayNotFound = () => {
  return (
    <>
      <div className="w-full flex justify-center">
        <div className="w-[500px] max-w-full grid grid-cols-1">
          <div className="w-full flex justify-center mb-16">
            <img src={NotFound} alt="" />
          </div>
          <div className="w-full text-center mb-24 text-18 sm:text-24 text-black dark:text-white font-bold">
            Not Found Page
          </div>
          <div className="w-full text-center mb-32 text-14 sm:text-16 text-grey-light">
            The page you were looking for does not exist or could not be found.
            Go back home or try again later.
          </div>
          <div className="w-full flex justify-center">
            <Link
              to={URL.HOME}
              className="h-48 rounded-6 flex items-center justify-center text-black dark:text-white bg-purple w-[160px]"
            >
              Go to home
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default OverlayNotFound
