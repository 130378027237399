import { parseError } from "api/error"
import axios from "axios"
import ModalActivate from "components/modals/ModalActivate"
import ModalActivateError from "components/modals/ModalActivateError"
import { REQ } from "libs/constants"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Home from "views/Home"

interface Params {
  email: string
  token: string
}

const Activate = () => {
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openError, setOpenError] = useState(false)

  const [again, setAgain] = useState(false)
  const [resend, setResend] = useState(false)

  const { email, token } = useParams<Params>()

  useEffect(() => {
    onSubmit()
  }, [])

  useEffect(() => {
    if (again) {
      onSubmit()
    }
  }, [again])

  const onSubmit = () => {
    axios({
      url: REQ.AUTH.ACTIVATE.replace(":param", token),
      method: "get",
      data: {}
    })
      .then((res: any) => res.data)
      .then(() => {
        setOpenSuccess(true)
      })
      .catch((error) => {
        setOpenError(true)
        setResend(again)
        parseError(error)
      })
  }

  return (
    <>
      <Home />
      <ModalActivate open={openSuccess} handler={setOpenSuccess} />
      <ModalActivateError
        email={email}
        open={openError}
        handler={setOpenError}
        submit={setAgain}
        resend={resend}
      />
    </>
  )
}

export default Activate
