import { ReactComponent as Logo } from "assets/img/logo.svg"
import AnimationLoading from "components/animations/AnimationLoading"
import { useProps } from "contexts/PropsContext"
import React, { Suspense } from "react"
import { useTranslation } from "react-i18next"

const OverlayForbidden = () => {
  const { darkMode } = useProps()
  const { t } = useTranslation(['landing'])

  return (
    <Suspense fallback={<AnimationLoading />}>
      <div className="w-full grid grid-cols-1 content-center place-items-center text-center">
        <Logo height={"60%"} width={"60%"} fill={darkMode ? "white" : "dark"} className="mb-48"/>
        <div className="w-full text-18 sm:text-20 text-white">
          {t("forbidden")}
        </div>
        <div className="w-full text-14 sm:text-16 text-grey-light">
          {t("forbidden_text")}
        </div>
      </div>
    </Suspense>
  )
}

export default OverlayForbidden
