import moment from "moment"

import { forbiddenCountryCodes } from "./data"

export function openLink(event: any, link?: string) {
  event.preventDefault()
  window.open(link)
}

export const onSort = (arr: any[]) => {
  return arr.sort((a, b) => (a.title > b.title ? 1 : -1))
}

export const onSortBets = (arr: any[]) => {
  return arr.sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
}

export const onSortTitleAsc = (arr: any[]) => {
  return arr.sort((a, b) => (a.title > b.title ? 1 : -1))
}

export const onSortTitleDesc = (arr: any[]) => {
  return arr.sort((a, b) => (a.title < b.title ? 1 : -1))
}

export const onSortPayoutAsc = (arr: any[]) => {
  return arr.sort((a, b) => (a.payout > b.payout ? 1 : -1))
}

export const onSortPayoutDesc = (arr: any[]) => {
  return arr.sort((a, b) => (a.payout < b.payout ? 1 : -1))
}

export const onSortTableAsc = (arr: any[], index: number) => {
  return arr.sort((a, b) => (a[index].text > b[index].text ? 1 : -1))
}

export const onSortTableDesc = (arr: any[], index: number) => {
  return arr.sort((a, b) => (a[index].text < b[index].text ? 1 : -1))
}

export const onSortChatMessages = (arr: any[]) => {
  return arr.sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
}

export function parseDate(number: number) {
  if (moment(number * 1000).format("LL") === moment().format("LL")) {
    return "Today"
  } else if (
    moment(number * 1000)
      .add(1, "days")
      .format("LL") === moment().format("LL")
  ) {
    return "Yesterday"
  }
  return `${moment(number * 1000).format("MMM DD, YYYY")}`
}

export function parseNumber(number: number) {
  if (number >= 999999) {
    return `${Math.round(number / 100000) / 10}M`
  } else if (number >= 999) {
    return `${Math.round(number / 100) / 10}K`
  } else {
    return `${number}`
  }
}

export const parseTimeAgo = (date: number) => {
  return `${moment(date).fromNow(true)}`
}

export const parseList = (arr: string[], entry: string, limit?: number) => {
  arr = arr.filter((item: string) => item)

  if (arr.includes(entry)) {
    return limit
      ? arr.slice(0, limit)
      : arr.filter((item: string) => item !== entry)
  } else {
    const newArr = [entry, ...arr]
    return limit ? newArr.slice(0, limit - 1) : newArr
  }
}

export const parseListString = (arr: string[]) => {
  return arr.join(",")
}

export const parseListArray = (str: string) => {
  return str.split(",")
}

export const parseCoin = (coin: string) => {
  switch (coin) {
    case "BTC":
      return "Bitcoin"
    case "ETH":
      return "Ethereum"
    case "LTC":
      return "Litecoin"
    default:
      return ""
  }
}

export const addGameImages = (games: any) => {
  return games?.map((game: any) => ({
      ...game,
      image: `https://cdn.softswiss.net/i/s3/${game.identifier.replace(":", "/")}.png`
  }))
}

export const isCountryAllowed = (countryCode: string) => {
  return !forbiddenCountryCodes.includes(countryCode)
}
