import { getTournamentDates } from "api/tournament"
import Banner1 from "assets/img/launch-tournament.png"
import { ReactComponent as Trophy } from "assets/img/tournament.svg"
import Modal from "components/modals/Modal"
import ModalClose from "components/modals/ModalClose"
import { URL } from "libs/constants"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

const ModalTournament = ({
  open,
  handler
}: {
  open: boolean
  handler: any
}) => {
  const { t } = useTranslation(['landing'])
  const history = useHistory()
  const [endTournamentDate, setEndTournamentDate] = useState()

  useEffect(() => {
    if (open) {
      onLoad()
    }
  }, [open])

  const onLoad = async () => {
    const tournamentDates = await getTournamentDates()
    if (tournamentDates) {
      setEndTournamentDate(tournamentDates?.endTournamentDate)
    }
  }

  const onTournamentClick = () => {
    history.push(URL.TOURNAMENT)
    handler(false)
  }

  return (
    <>
      <Modal open={open} handler={handler}>
        <div className="bg-grey-light-3 dark:bg-grey-med rounded-10 p-24 grid grid-cols-1 gap-24 w-[750px] max-w-full relative">
          <div className="w-full grid grid-cols-1 gap-12">
            <div className="w-full text-16 sm:text-18 text-black dark:text-white flex items-center gap-8">
              <Trophy className="fill-current" />
              {t("tournaments")}
            </div>
            <div className="w-full text-16 text-grey-light">
              {t("tournaments_info")}
            </div>
            {endTournamentDate && (
              <>
                <div className="w-full text-16 sm:text-18 text-black dark:text-white flex items-center gap-8">
                  {t("latest_tournament")}
                </div>
                <button onClick={() => onTournamentClick()}>
                  <img src={Banner1} alt="" className="w-full rounded-24"/>
                </button>
                <div className="w-full text-16 text-grey-light">
                  {t("end_of_tournament", { value: endTournamentDate })}
                </div>
              </>
            )}
          </div>
          <ModalClose open={open} handler={handler} />
        </div>
      </Modal>
    </>
  )
}

export default ModalTournament
