import React from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { MdOutlineContentCopy } from "react-icons/md"
import { toast } from "react-toastify"

const InputFormCopy = ({ title, value }: { title: string; value: string }) => {
  return (
    <>
      <div className="w-full grid grid-cols-1 gap-8">
        <div className="w-full text-grey-light text-14 sm:text-16">{title}</div>
        <div className="w-full h-48 grid grid-cols-[1fr,auto] items-center bg-grey-light-2 dark:bg-grey-med-2 rounded-6 pl-16 pr-4 py-4">
          <input
            type="text"
            readOnly
            className="w-full placeholder-grey-light text-black dark:text-white text-16"
            value={value}
            placeholder={title}
          />
          <CopyToClipboard text={value} onCopy={() => toast.success("Copied!")}>
            <button className="w-40 h-40 rounded-6 flex items-center dark:hover:bg-grey-med justify-center">
              <MdOutlineContentCopy className="text-black dark:text-white text-18" />
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </>
  )
}

export default InputFormCopy
