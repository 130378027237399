import ModalSearchBarNavResults from "components/modals/ModalSearchBarNavResults"
import { debounce } from "lodash"
import React, { useCallback, useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { useTranslation } from "react-i18next"
import { MdSearch } from "react-icons/md"

const SearchBarNav = () => {
  const { t } = useTranslation(["landing"])
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState("")

  useEffect(() => {

    return () => {
      debouncedSearchHandler.cancel()
    }
  }, [])

  const handleSearchInput = useCallback(
    (text) => {
      setValue(text)
      if (text) {
        setOpen(true)
      }
    },
    [],
  )

  const debouncedSearchHandler = useCallback(
    debounce(handleSearchInput, 800),
    [],
  )

  return (
    <>
      {!isMobile ? (
        <div className="w-full flex justify-center items-center bg-grey-light-2 dark:bg-grey-dark rounded-6 dark:border-1 dark:border-grey-med-2">
            <button className={`w-42 h-42 rounded-l-6 flex items-center justify-center group bg-grey-light-2 dark:bg-grey-med-2`}>
                <MdSearch className="text-30 text-grey-light group-hover:text-black dark:group-hover:text-white" />
            </button>
            {!isMobile && <input
                type="text"
                className="w-full px-12 placeholder-grey-light text-black dark:text-white text-16 font-bold"
                placeholder={t("search_game")}
                onChange={(e) => debouncedSearchHandler(e.target.value)}
            />}
        </div>
      ) : (
          <button onClick={() => setOpen(!open)}>
            <MdSearch className="text-30 text-grey-light group-hover:text-black dark:group-hover:text-white" />
          </button>
      )}
      <ModalSearchBarNavResults open={open} handler={setOpen} mobileHandler={debouncedSearchHandler} search={value} />
    </>
  )
}

export default SearchBarNav
