import axios from "axios"
import { REQ } from "libs/constants"
import { addGameImages } from "libs/functions"

import { parseError } from "./error"

export const onGetGames = async (
  page: number,
  pageSize: number,
  category?: string,
  provider?: string,
  search?: string,
  isLive?: boolean,
  isGameShow?: boolean
) => {
    const newCategory = category && category === "poker" ? "poker, video_poker" : category
    const data = await axios({
      url: REQ.GAMES.SEARCH,
      method: "get",
      data: {},
      params: {
        page,
        pageSize,
        category: newCategory,
        provider,
        q: search,
        isLive,
        isGameShow
      },
      withCredentials: true
    })
      .then((res: any) => res.data)
      .catch((error) => {
        parseError(error)
      })

    let mapped = []
    if (data?.success) {
      if (data.response?.games?.length > 0) {
        mapped = addGameImages(data.response.games)
        return {
          ...data.response,
          games: mapped
        }
      }
    }
    return null
  }


export const onSoftswissReq = async (url: string, data: any) => {
  const result = await axios({
    url,
    method: "post",
    data,
    withCredentials: true
  })
  .then((res: any) => res?.data)
  .catch((error) => {
    parseError(error)
  })

  if (result?.success) {
    return result?.response
  }
  return null
}

export const onToggleFavoriteGame = async (slug: string) => {
  const result = await axios({
    url: REQ.SLOTS.ADD_FAVOURITE,
    method: "post",
    data: { identifier: slug },
    withCredentials: true
  })
  .then((res: any) => res?.data)
  .catch((error) => {
    parseError(error)
  })

  if (result?.success) {
    return true
  }
  return false
}
