import { parseError } from "api/error"
import axios from "axios"
import Modal from "components/modals/Modal"
import ModalClose from "components/modals/ModalClose"
import { useProps } from "contexts/PropsContext"
import { REQ, URL } from "libs/constants"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MdPeopleOutline } from "react-icons/md"
import NumberFormat from "react-number-format"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import InputFormCopy from "utils/inputs/InputFormCopy"

const ModalAffiliates = ({
  open,
  handler
}: {
  open: boolean
  handler: any
}) => {
  const { authenticated, coin } = useProps()
  const { t } = useTranslation(['landing'])

  const [balance, setBalance] = useState(0)
  const [code, setCode] = useState("")

  let affiliateSite
  if (typeof window !== 'undefined') {
    affiliateSite = location.protocol + '//' + location.host
  }

  const [site] = useState(affiliateSite || "https://gamblingapes.com")
  const [link, setLink] = useState("https://gamblingapes.com")
  const [totalEarned, setTotalEarned] = useState(0)

  const [mounted, setMounted] = useState(true)

  useEffect(() => {
    setMounted(true)

    onLoad()

    return () => {
      setMounted(false)
    }
  }, [])

  useEffect(() => {
    setLink(site + `?code=${code}`)
  }, [code])

  const onLoad = async () => {
    if (!authenticated) {
      return
    }

    const response = await axios({
      url: REQ.AFFILIATE.GET,
      method: "get",
      data: {},
      withCredentials: true
    })
      .then((res: any) => res.data.response)
      .catch((error) => {
        parseError(error)
      })

    if (mounted && response) {
      setBalance(response.balance)
      setCode(response.code)

      setTotalEarned(response.total_earned)
    }
  }

  const onClaim = () => {
    if (balance === 0) {
      toast.error("No earnings left to be claimed")
      return
    }

    axios({
      url: REQ.AFFILIATE.CLAIM.AFFILIATE,
      method: "post",
      data: { coin: coin },
      withCredentials: true
    })
      .then((res: any) => res.data.response)
      .catch((error) => {
        parseError(error)
      })

      onLoad()
  }

  return (
    <>
      <Modal open={open} handler={handler}>
        <div className="bg-grey-light-3 dark:bg-grey-med rounded-10 p-24 grid grid-cols-1 gap-24 w-[750px] max-w-full relative">
          <div className="w-full grid grid-cols-1 gap-12">
            <div className="w-full text-16 sm:text-18 text-black dark:text-white flex items-center gap-8">
              <MdPeopleOutline className="text-20 text-green" />
              {t("affiliates")}
            </div>
            <div className="w-full text-14 text-grey-light whitespace-pre-line">
              {t("share_affiliate_link")}
            </div>
          </div>
          <InputFormCopy title={"Affiliate link"} value={link} />
          <InputFormCopy title={"Affiliate code"} value={code} />
          <div className="w-full border-b-1 border-grey-border"></div>
          <div className="w-full grid grid-cols-1 gap-16">
            <div className="w-full text-16 sm:text-18 text-black dark:text-white">
              {t("statistics")}
            </div>
            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-16">
              <div className="w-full bg-grey-light-1 dark:bg-grey-med-3 rounded-6 p-16 md:p-24 grid grid-cols-1 gap-6 items-center">
                <div className="w-full text-14 text-grey-light">
                  {t("total_earned")}
                </div>
                <div className="w-full">
                  <NumberFormat
                    value={totalEarned / 100}
                    displayType={"text"}
                    prefix={"$"}
                    thousandSeparator={true}
                    className="text-black dark:text-white text-16 sm:text-18 font-bold"
                  />
                </div>
              </div>
              <div className="w-full bg-grey-light-1 dark:bg-grey-med-3 rounded-6 p-16 md:p-24 grid grid-cols-1 md:grid-cols-[1fr,auto] gap-12 items-center">
                <div className="w-full grid grid-cols-1 gap-4">
                  <div className="w-full text-14 text-grey-light">
                    {t("available_earnings")}
                  </div>
                  <div className="w-full">
                    <NumberFormat
                      value={Math.round(balance) / 100}
                      displayType={"text"}
                      prefix={"$"}
                      thousandSeparator={true}
                      className="text-green text-16 sm:text-18 font-bold"
                    />
                  </div>
                </div>
                <button
                  disabled={balance === 0}
                  onClick={() => onClaim()}
                  className="w-full px-16 h-48 rounded-6 bg-green flex items-center text-black dark:text-white text-14"
                >
                  {t("claim_earnings")}
                </button>
              </div>
            </div>
          </div>
          <div className="w-full border-b-1 border-grey-border"></div>
          <div className="w-full grid grid-cols-1">
            <div className="w-full text-16 sm:text-18 text-black dark:text-white mb-12">
              {t("how_it_works")}
            </div>
            <div className="w-full flex justify-start mb-16">
              <div className="max-w-full text-14 text-grey-light">
                {t("percent_on_the_house_earning")}
              </div>
            </div>
            <div className="w-full flex justify-start">
              <Link
                to={URL.AFFILIATES}
                onClick={() => handler(false)}
                className="h-48 rounded-6 px-16 text-grey-light bg-grey-light-1 dark:bg-grey-med-3 hover:text-black dark:hover:text-white flex items-center text-16"
              >
                {t("view_dashboard")}
              </Link>
            </div>
          </div>
          <ModalClose open={open} handler={handler} />
        </div>
      </Modal>
    </>
  )
}

export default ModalAffiliates
