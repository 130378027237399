import { parseError } from "api/error"
import axios from "axios"
import ModalTwoFactorBackup from "components/modals/ModalTwoFactorBackup"
import ModalTwoFactorCode from "components/modals/ModalTwoFactorCode"
import { REQ } from "libs/constants"
import { TwofactorInterface } from "libs/interfaces"
import React, { useEffect, useState } from "react"

const ModalTwoFactor = ({ open, handler }: { open: boolean; handler: any }) => {
  const [openBackup, setOpenBackup] = useState(false)

  const [tfa, setTfa] = useState<TwofactorInterface>()
  const [mounted, setMounted] = useState(true)

  const [isOpenBackup, setIsOpenBackup] = useState(false)

  useEffect(() => {
    setMounted(true)

    return () => {
      setMounted(false)
    }
  }, [])

  useEffect(() => {
    if (open) {
      onLoad()
    }
  }, [open])

  useEffect(() => {
    if (openBackup) {
      setIsOpenBackup(true)
    } else if (!openBackup && isOpenBackup) {
      setTimeout(() => {
        window.location.reload()
      }, 500)
    }
  }, [openBackup])

  const onLoad = async () => {
    const res: TwofactorInterface = await axios({
      url: REQ.USER.MFA.START,
      method: "get",
      data: {},
      withCredentials: true
    })
      .then((res: any) => res.data.response)
      .catch((error) => {
        parseError(error)
      })

    if (res && mounted) {
      setTfa(res)
    }
  }

  return (
    <>
      {tfa ? (
        <>
          <ModalTwoFactorCode
            open={open}
            handler={handler}
            handlerBackup={setOpenBackup}
            tfa={tfa}
          />
          <ModalTwoFactorBackup
            open={openBackup}
            handler={setOpenBackup}
            tfa={tfa}
          />
        </>
      ) : (
        ""
      )}
    </>
  )
}

export default ModalTwoFactor
