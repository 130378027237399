import { parseFiat } from "api/balance"
import ModalFunds from "components/modals/ModalFunds"
import Wrapper from "components/wrapper/Wrapper"
import { useProps } from "contexts/PropsContext"
import { WalletInterface } from "libs/interfaces"
import React from "react"
import { isMobile } from "react-device-detect"
import { FaBtc } from "react-icons/fa"
import { MdKeyboardArrowDown } from "react-icons/md"
import { SiEthereum, SiLitecoin } from "react-icons/si"
import NumberFormat from "react-number-format"
import Toggle from "utils/elements/Toggle"

import DepositIcon from "../../assets/img/deposit.svg"

const NavWallet = ({ game }: { game?: boolean }) => {
  const { crypto, fiat, wallet, wallets, onChangeCoin, setFiat, openDeposit, setOpenDeposit, openWallet, setOpenWallet } =
    useProps()

  const onWalletChange = async (coin: string) => {
    await onChangeCoin(coin)
    setOpenWallet(false)
  }

  return (
    <div className="flex items-center">
      <div className="flex relative">
        <button
          onClick={() => setOpenWallet(!openWallet)}
          className="px-12 md:px-16 h-42 rounded-l-6 bg-grey-light-2 dark:bg-grey-med-2 flex items-center gap-12"
        >
          {wallet ? (
            <>
              {
                {
                  ["ETH"]: <SiEthereum className="text-purple" />,
                  ["BTC"]: <FaBtc className="text-orange-2" />,
                  ["LTC"]: <SiLitecoin className="text-grey-light" />
                }[wallet.coin]
              }
              <div className="text-black dark:text-white text-14">
                {!game ? (
                  <NumberFormat
                    value={parseFiat(
                      crypto,
                      wallet.coin,
                      isMobile || fiat,
                      wallet.balance
                    )}
                    prefix={(isMobile || fiat) ? "$" : ""}
                    displayType={"text"}
                    thousandSeparator={true}
                    className="text-black dark:text-white text-14 font-bold"
                  />
                ) : (
                  <div className="text-14 sm:text-16 text-grey-light">
                    In-game
                  </div>
                )}
              </div>
            </>
          ) : (
            ""
          )}
          {!isMobile && <MdKeyboardArrowDown
            className={`transition text-20 text-grey-light hover:text-black dark:hover:text-white transform ${
              openWallet ? "rotate-[180deg]" : ""
            }`}
          />}
        </button>
        <Wrapper open={openWallet}>
          <div
            onClick={() => setOpenWallet(false)}
            className="z-20 fixed top-0 left-0 w-full h-full"
          ></div>
          <div className="rounded-6 absolute z-30 top-60 left-[75%] transform translate-x-[-50%] shadow dark:shadow-none bg-grey-light-1 dark:bg-grey-med-3 grid grid-cols-1 w-full min-w-[220px]">
            <div className="w-full p-8 grid grid-cols-1">
              {wallets.map((wallet: WalletInterface, key: number) => (
                <button
                  onClick={() => onWalletChange(wallet.coin)}
                  className="w-full group p-8 items-center grid grid-cols-[auto,1fr] gap-12"
                  key={key}
                >
                  <div className="flex items-center gap-12">
                    <div className="first:text-grey-light group-hover:first:text-black dark:group-hover:first:text-white">
                      {
                        {
                          ["ETH"]: <SiEthereum className="text-purple" />,
                          ["BTC"]: <FaBtc className="text-orange-2" />,
                          ["LTC"]: <SiLitecoin className="text-grey-light" />
                        }[wallet.coin]
                      }
                    </div>
                    <div className="text-black dark:text-white text-14 font-bold">
                      {wallet.coin}
                    </div>
                  </div>
                  <div className="w-full text-right">
                    <NumberFormat
                      value={parseFiat(
                        crypto,
                        wallet.coin,
                        fiat,
                        wallet.balance
                      )}
                      displayType={"text"}
                      prefix={fiat ? "$" : ""}
                      thousandSeparator={true}
                      className="text-grey-light group-hover:text-black dark:group-hover:text-white text-14"
                    />
                  </div>
                </button>
              ))}
            </div>
            <div className="w-full p-16 border-t-1 border-grey-med-2 flex items-center gap-12">
              <Toggle value={fiat} handler={setFiat} />
              <div className="text-14 text-black dark:text-white">Display in fiat</div>
            </div>
          </div>
        </Wrapper>
      </div>
      <button
        onClick={() => setOpenDeposit(true)}
        className="h-42 px-16 rounded-r-6 bg-purple flex items-center gap-12"
      >
        <img className={`${isMobile && "max-w-none"} w-26 h-26`} src={DepositIcon} alt='depositIcon' />
      </button>
      <ModalFunds open={openDeposit} handler={setOpenDeposit} />
    </div>
  )
}

export default NavWallet
