import React from "react"

const AccountToggle = ({
  title,
  text,
  value,
  handler
}: {
  title: string
  text: string
  value: boolean
  handler: any
}) => {
  return (
    <>
      <button
        onClick={() => handler(!value)}
        className="w-full grid grid-cols-1 gap-8"
      >
        <div className="w-full grid grid-cols-[auto,1fr] gap-8">
          <div
            className={`w-40 h-24 rounded-full relative transition-all duration-300 ${
              value ? "bg-purple" : "bg-grey-light-1 dark:bg-grey-border"
            }`}
          >
            <div
              className={`w-16 h-16 rounded-full absolute top-4 transition-all duration-300 ${
                value ? "bg-white left-20" : "bg-grey-light-0 left-4"
              }`}
            ></div>
          </div>
          <div className="w-full text-black dark:text-white text-16 text-left">{title}</div>
        </div>
        <div className="w-full text-14 text-grey text-left">{text}</div>
      </button>
    </>
  )
}

export default AccountToggle
