import VipCoin from "assets/img/vip-coin.png"
import { useProps } from "contexts/PropsContext"
import { NextLevelGoalInterface, UserBenefitsInterface } from "libs/interfaces"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const HomeVIPSystem = ({ moreInfo, handler }:{ moreInfo?:boolean, handler?: any }) => {
  const { userProgress, onGetUserLevelProgress } = useProps()
  const { t } = useTranslation(['landing'])

  const [levelProgress, setLevelProgress] = useState(userProgress?.progress || 0)
  const [currentLevel, setCurrentLevel] = useState(userProgress?.level || 1)
  const [nextGoal, setNextGoal] = useState<NextLevelGoalInterface>(userProgress?.nextGoal)
  const [benefits, setBenefits] = useState<UserBenefitsInterface>(userProgress?.currentBenefits)

  useEffect(() => {
    onGetUserLevelProgress()
  }, [])

  useEffect(() => {
    setLevelProgress(userProgress?.progress || 0)
    setCurrentLevel(userProgress?.level || 1)
    setNextGoal(userProgress?.nextGoal)
    setBenefits(userProgress?.currentBenefits)
  }, [userProgress])

  return (
    <div className="w-full grid grid-cols-1 gap-12">
      {moreInfo && (
        <div className="w-full grid grid-cols-1">
          {currentLevel > 0 ? <div className="w-full text-16 sm:text-20 text-grey-light font-bold">
          {t("level_complete", { value: currentLevel })}
          </div> : ""}
          <div className="mb-30 w-full text-16 text-grey-light">
            {t("level_goal", { value: currentLevel + 1 })}
            <span className="text-14 text-green">
             {nextGoal?.type} {nextGoal?.amount}
            </span>
          </div>
          <img src={VipCoin} alt="" className="w-full" />
        </div>
      )}
      <div className="w-full text-black dark:text-white text-20">
        {t("level_progress")}: {levelProgress}%
      </div>
      <div className="flex justify-center items-center relative">
        <div className="text-16 absolute left-0 h-40 w-40 bg-green rounded-full flex justify-center items-center text-white">{currentLevel}</div>
        <div className="w-full px-20">
          <div className="w-full h-20 bg-grey-light-1 dark:bg-grey-border rounded-10">
            <div
              style={{ width: `${(levelProgress)}%` }}
              className="h-20 rounded-10 bg-green transition-all duration-300"
              ></div>
          </div>
        </div>
        <div
          className={`text-16 absolute right-0 h-40 w-40 rounded-full flex justify-center items-center text-white
            ${levelProgress === 100 ? "bg-green" : "bg-grey-light-1 dark:bg-grey-border"}
        `}>
          {currentLevel + 1}
        </div>
      </div>
      <div className="w-full flex justify-between items-center">
        <div className="w-full text-left text-black dark:text-white text-20">
          {t("level")}
        </div>
        <div className="w-full text-right text-black dark:text-white text-20">
          {t("level")}
        </div>
      </div>
     {moreInfo && <div className="w-full mt-30 grid grid-cols-1 gap-12">
        <div className="w-full text-16 sm:text-20 text-grey-light font-bold">{t("level_benefits")}</div>
        <div className="w-full grid grid-cols-1 gap-12 px-12">
          {benefits?.rakebackPerc ? <div className="w-full flex justify-start items-center gap-8">
            <div className="w-8 h-8 bg-purple rounded-full" />
            <div className="w-full text-16 text-grey-light">{t("level_benefit_rakeback", { value: benefits?.rakebackPerc })}</div>
          </div> : ""}
          {benefits?.rewardMoney ? <div className="w-full flex justify-start items-center gap-8">
            <div className="w-8 h-8 bg-purple rounded-full" />
            <div className="w-full text-16 text-grey-light">{t("level_benefit_freemoney", { value: benefits?.rewardMoney })}</div>
          </div> : ""}
          {benefits?.rewardGame ? <div className="w-full flex justify-start items-center gap-8 text-16 text-grey-light">
            <div className="w-8 h-8 bg-purple rounded-full" />
            <button onClick={handler} className="w-full text-left underline text-16 text-grey-light">{t("level_benefit_freegames")}</button>
          </div> : ""}
        </div>
      </div>}
    </div>
  )
}

export default HomeVIPSystem
