import { URL } from "libs/constants"
import React from "react"
import { Route } from "react-router-dom"
import Favourite from "views/Favourite"
import Recent from "views/Recent"

export default [
  {
    component: (
      <Route exact path={URL.FAVOURITE} key={0}>
        <Favourite />
      </Route>
    )
  },
  {
    component: (
      <Route path={URL.RECENT} key={1}>
        <Recent />
      </Route>
    )
  }
]
