import AnimationLoading from "components/animations/AnimationLoading"
import React, { Suspense } from "react"
import { Route, Switch } from "react-router"

const WrapperRouter = ({ children }: { children: any }) => {
  return (
    <>
      <Suspense fallback={<AnimationLoading />}>
        <Route
          render={() => (
            <div>
              <Switch>{children}</Switch>
            </div>
          )}
        />
      </Suspense>
    </>
  )
}

export default WrapperRouter
