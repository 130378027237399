import { parseBalance } from "api/balance"
import {
  detectVPN,
  getSite,
  getUser,
  getUserCountry,
  getUserLevelProgress,
  getWallet,
  onChangeWalletCoin
} from "api/user"
import { createCtx } from "contexts/Context"
import { SOCKET } from "libs/constants"
import { siteSample, walletSample } from "libs/data"
import { OverlayType, RewardType } from "libs/enums"
import { isCountryAllowed } from "libs/functions"
import {
  getterCinemaMode,
  getterCoin,
  getterDarkMode,
  getterFiat,
  getterLanguage,
  getterMask,
  setterCinemaMode,
  setterCoin,
  setterDarkMode,
  setterFiat,
  setterLanguage,
  setterMask,
} from "libs/functions/cookies"
import i18n from "libs/i18n"
import {
  CryptoInterface,
  LinkInterface,
  UserInterface,
  WalletInterface
} from "libs/interfaces"
import React, { createContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { io } from "socket.io-client"

type PropsContextType = {
  authenticated: boolean
  coin: string
  crypto: CryptoInterface[]
  fiat: boolean
  language: string
  loading: boolean
  mask: boolean
  online: number
  overlay: OverlayType
  path: string
  route: boolean
  site: any
  socket: any
  totalWagers: number
  user?: UserInterface | null
  wallets: WalletInterface[]
  wallet: WalletInterface
  setCoin: any
  setFiat: any
  setLanguage: any
  setLoading: any
  setMask: any
  setOverlay: any
  setPath: any
  onWallets: any
  onLogout: any
  onGetUser: any
  openRewards: boolean,
  setOpenRewards: any
  openAffiliates: boolean,
  setOpenAffiliates: any
  links: LinkInterface[],
  darkMode: boolean,
  onDarkModeChange: any,
  cinemaMode: boolean,
  onCinemaModeChange: any,
  openTournament: boolean,
  setOpenTournament: any,
  rewardTab: RewardType,
  onRewardModalOpen: any,
  userProgress: any,
  onGetUserLevelProgress: any,
  userCountryCode: string,
  onChangeCoin: any,
  isCasino: boolean,
  setIsCasino: any,
  openLogin: boolean,
  setOpenLogin: any,
  openRegister: boolean,
  setOpenRegister: any,
  openDeposit: boolean,
  setOpenDeposit: any,
  openWallet: boolean,
  setOpenWallet: any
}

export const [useProps, CtxProvider] = createCtx<PropsContextType>()

export const PropsContext = createContext<PropsContextType | undefined>(
  undefined
)

export const PropsProvider = ({ children }: { children: React.ReactNode }) => {
  const [authenticated, setAuthenticated] = useState(false)
  const [coin, setCoin] = useState(getterCoin())
  const [crypto, setCrypto] = useState<CryptoInterface[]>([])
  const [fiat, setFiat] = useState(getterFiat())
  const [language, setLanguage] = useState(getterLanguage())
  const [loading, setLoading] = useState(true)
  const [mask, setMask] = useState(getterMask())
  const [overlay, setOverlay] = useState(OverlayType.None)
  const [online, setOnline] = useState(0)
  const [path, setPath] = useState(window.location.pathname)
  const [route] = useState(
    parseInt(process.env.REACT_APP_ADMIN ?? "") ? true : false
  )
  const [site, setSite] = useState<any>(siteSample)
  const [socket, setSocket] = useState<any>()
  const [totalWagers, setTotalWagers] = useState(0)
  const [user, setUser] = useState<UserInterface | null>(null)
  const [wallet, setWallet] = useState<WalletInterface>(walletSample)
  const [wallets, setWallets] = useState<WalletInterface[]>([])
  const [darkMode, setDarkMode] = useState(getterDarkMode())
  const [cinemaMode, setCinemaMode] = useState(getterCinemaMode())
  const [userProgress, setUserProgress] = useState()
  const [userCountryCode, setUserCountryCode] = useState<string>("")
  const [isCasino, setIsCasino] = useState(true)

  const [openRewards, setOpenRewards] = useState(false)
  const [rewardTab, setRewardTab] = useState(RewardType.Rakeback)
  const [openAffiliates, setOpenAffiliates] = useState(false)
  const [openTournament, setOpenTournament] = useState(false)
  const [openLogin, setOpenLogin] = useState(false)
  const [openRegister, setOpenRegister] = useState(false)
  const [openDeposit, setOpenDeposit] = useState(false)
  const [openWallet, setOpenWallet] = useState(false)

  const [links] = useState<LinkInterface[]>([
    { title: "rewards", link: setOpenRewards },
    { title: "affiliates", link: setOpenAffiliates },
    { title: "tournaments", link: setOpenTournament }
  ])

  useEffect(() => {
    if (authenticated) {
      onWallets()
    }
  }, [authenticated])

  useEffect(() => {
    if (coin) {
      setterCoin(coin)
    }
  }, [coin])

  useEffect(() => {
    setterFiat(fiat)
  }, [fiat])

  useEffect(() => {
    setterLanguage(language)
    i18n.changeLanguage(language)
  }, [language])

  useEffect(() => {
    if (loading) {
      onLoad()
    }
  }, [loading])

  useEffect(() => {
    setterMask(mask)
  }, [mask])

  useEffect(() => {
    if (socket) {
      onSocket()
    }
  }, [socket])

  useEffect(() => {
    setAuthenticated(user ? true : false)
  }, [user])

  useEffect(() => {
    if (authenticated && wallets.length > 0) {
      setWallet(
        wallets.filter((entry: WalletInterface) => entry.coin === coin)[0]
      )
    }
  }, [wallets, coin])

  const onGetUserCountry = async () => {
    const response = await getUserCountry()

    if (response) {
      setUserCountryCode(response?.country_code)
      return (response?.country_code)
    }

    return null
  }

  const onLoad = async () => {
    const detectVpn = await detectVPN()
    if (detectVpn === true) {
        setOverlay(OverlayType.ForbiddenVpn)
        return
    }
    const response = await onGetUserCountry()
    if (response) {
      if (!isCountryAllowed(response)) {
        setOverlay(OverlayType.Forbidden)
        return
      }
    }

    setAuthenticated(false)

    const newSite = await getSite()

    if (newSite && newSite.main && newSite.main.maintenance) {
      setOverlay(OverlayType.Maintenance)
      return
    }

    setSite(newSite)

    const newUser = await getUser()
    setUser(newUser)

    setSocket(
      io(SOCKET, {
        query: {},
        rejectUnauthorized: false,
        transports: ["websocket"],
        withCredentials: true,
        path: '/api/socket.io'
      })
    )

    setLoading(false)
  }

  const onWallets = async () => {
    const data = await getWallet()

    if (data && data.length > 0) {
      const newWallets: WalletInterface[] = [
        { balance: 0, coin: "BTC", address: "" },
        { balance: 0, coin: "ETH", address: "" },
        { balance: 0, coin: "LTC", address: "" }
      ]

      data.forEach((entry: WalletInterface) => {
        const found = newWallets
          .map((newEntry: WalletInterface, key: number) =>
            entry.coin === newEntry.coin ? key : -1
          )
          .filter((entry: number) => entry !== -1)

        if (found && found.length > 0) {
          entry.balance = parseBalance(entry.balance)
          newWallets[found[0]] = entry
        }
      })

      setWallets(newWallets)

      if (!coin && user) {
        setCoin(user.wallet_coin)
      }
    }
  }

  const onGetUser = async () => {
    const newUser = await getUser()

    setUser(newUser)
    setCoin(newUser?.wallet_coin)
  }

  const onSocket = () => {
    socket.on("connect", () => {
      // console.log("Socket connected")
    })

    socket.on("connect_error", (err: any) => {
      console.error(err)
    })

    onSocketGets()
  }

  const onSocketGets = () => {
    socket.on("crypto.prices", (res: any) => {
      const prices = res.map((entry: any) => ({
        value: parseInt(entry.value),
        ...entry
      }))

      setCrypto(prices)
    })

    socket.on("deposit.new", () => {
      toast.success("New deposit received, pending confirmation")
    })

    socket.on("deposit.complete", () => {
      onWallets()

      toast.success("Deposit complete, funds are added to balance")
    })

    socket.on("withdraw.sent", () => {
      onWallets()

      toast.success("Withdrawal confirmed, funds are sent")
    })

    socket.on("error", (err: any) => {
      console.error(err)
    })

    socket.on("site.stats", (res: any) => {
      setOnline(res.onlineUsers)
      setTotalWagers(res.totalWagers)
    })

    socket.on("user.balance", () => {
      onWallets()
    })
  }

  const onLogout = () => {
    setUser(null)
  }

  const onDarkModeChange = (value: boolean) => {
    setDarkMode(value)
    setterDarkMode(value)
  }

  const onCinemaModeChange = (value: boolean) => {
    setCinemaMode(value)
    setterCinemaMode(value)
  }

  const onRewardModalOpen = (value: boolean, type?: RewardType) => {
    setRewardTab(type === undefined ? RewardType.Rakeback : type)
    setOpenRewards(value)
  }

  const onGetUserLevelProgress = async () => {
    const userProgress = await getUserLevelProgress()
    if (userProgress) {
      setUserProgress(userProgress)
    }
  }

  const onChangeCoin = async (coin: string) => {
    await onChangeWalletCoin(coin)
    await onGetUser()
  }

  return (
    <>
      <CtxProvider
        value={{
          authenticated,
          coin,
          crypto,
          fiat,
          language,
          loading,
          mask,
          online,
          overlay,
          path,
          route,
          site,
          socket,
          totalWagers,
          user,
          wallets,
          wallet,
          setCoin,
          setFiat,
          setLanguage,
          setLoading,
          setMask,
          setOverlay,
          setPath,
          onWallets,
          onLogout,
          onGetUser,
          openRewards,
          setOpenRewards,
          openAffiliates,
          setOpenAffiliates,
          links,
          darkMode,
          onDarkModeChange,
          cinemaMode,
          onCinemaModeChange,
          openTournament,
          setOpenTournament,
          rewardTab,
          onRewardModalOpen,
          userProgress,
          onGetUserLevelProgress,
          userCountryCode,
          onChangeCoin,
          isCasino,
          setIsCasino,
          openLogin,
          setOpenLogin,
          openRegister,
          setOpenRegister,
          openDeposit,
          setOpenDeposit,
          openWallet,
          setOpenWallet
        }}
      >
        {children}
      </CtxProvider>
    </>
  )
}

export default PropsProvider
