import Overlays from "components/overlays/Overlays"
import { OverlayType } from "libs/enums"
import React from "react"

const NotFound = () => {
  return (
    <>
      <Overlays overlay={OverlayType.NotFound} />
    </>
  )
}

export default NotFound
