import HomeSection from "components/home/HomeSection"
import Modal from "components/modals/Modal"
import CategoriesPopup from "components/popups/CategoriesPopup"
import ProvidersPopup from "components/popups/ProvidersPopup"
import React, { useState } from "react"
import { isMobile } from "react-device-detect"
import { useTranslation } from "react-i18next"
import { MdSearch } from "react-icons/md"

const ModalSearchBarNavResults = ({
  open, handler, mobileHandler, search
}: {
  open: boolean; handler: any; mobileHandler: any; search?: string
}) => {
  const { t } = useTranslation(['landing'])

  const [providers, setProviders] = useState<string[]>([])
  const [category, setCategory] = useState<string>("")
  const [gameResults, setGameResults] = useState(0)

  return (
    <>
      <Modal open={open} handler={handler} showNavigation modalOnTop>
        <div className={`rounded-6 grid grid-cols-1 p-16 gap-12 bg-white dark:bg-grey-med-2 ${
          !isMobile ? "w-[800px] max-w-screen-lg" : "w-screen overflow-y-auto"
          } ${(isMobile && gameResults > 0) && "h-4/5"}`}>
            {isMobile && <div className="w-full p-12 rounded-6 dark:bg-grey-med">
              <input
                type="text"
                className="w-full placeholder-grey-light text-black dark:text-white text-16 font-bold"
                placeholder={t("search_game")}
                onChange={(e) => mobileHandler(e.target.value)}
              />
            </div>}
            <div className="w-full flex justify-start items-center gap-12">
              <ProvidersPopup providers={providers} setProviders={setProviders}/>
              <CategoriesPopup category={category} setCategory={setCategory}/>
            </div>
            <HomeSection
              title={t("search_results")}
              icon={<MdSearch className="text-grey-light text-24" />}
              category={category}
              provider={providers.join(", ")}
              search={search}
              sizeValue={isMobile ? 4 : undefined}
              setGameResults={setGameResults}
            />
        </div>
      </Modal>
    </>
  )
}

export default ModalSearchBarNavResults
