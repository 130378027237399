import AccountTitle from "components/account/elements/AccountTitle"
import ProvablyText from "components/provably/elements/ProvablyText"
import React from "react"

const ProvablyServer = () => {
  return (
    <>
      <div className="w-full">
        <AccountTitle title={"Server values"} />
        <ProvablyText
          text={
            <>
              Game ID - The identifier for the game
              <br />
              Server Seed - The server seed used for influencing the roll Server
              <br />
              Salt - Appended to the server seed when generating the server seed
              hash
              <br />
              <br />
              Server Seed Hash - The SHA256 hash of the server seed and server
              salt in the format; Server Seed-Server Salt
              <br />
              Entries Hash - The SHA256 hash of the game&apos;s ordered player
              list with user ids and corresponding bet values in the following
              example format (userid:amount:userid:amount):
              <br />
              e850222d-ec90-46e9-a69d-7d483ff8a2f9:2.00:ef6e64a9-8c8b-49f4-8304-fdd4dd030cbf:4.00
            </>
          }
        />
      </div>
    </>
  )
}

export default ProvablyServer
