import React from "react"

const AccountInput = ({
  title,
  value,
  handler,
  readonly
}: {
  title: string
  value?: string
  handler: any
  readonly?: boolean
}) => {
  return (
    <>
      <div className="w-full grid grid-cols-1 gap-8 mb-16">
        <div className="w-full text-grey-light text-semibold text-14 sm:text-16">
          {title}
        </div>
        <div className="w-full h-48 flex items-center bg-grey-light-1 dark:bg-grey-border rounded-6 px-16">
          <input
            type="text"
            readOnly={readonly ? true : false}
            className="w-full placeholder-grey-light text-black dark:text-white text-16"
            value={value ? value : ""}
            placeholder={title}
            onChange={(e) => handler(e.target.value)}
          />
        </div>
      </div>
    </>
  )
}

export default AccountInput
