import Wrapper from "components/wrapper/Wrapper"
import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { MdCheck, MdCloudUpload } from "react-icons/md"

const AccountDropZone = ({
  title,
  text,
  file,
  setFile,
  link
}: {
  title: string
  text: string
  file?: File
  setFile: any
  link: string
}) => {
  const onDrop = useCallback((files: File[]) => {
    if (files && files.length > 0) setFile(files[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png, application/pdf",
    multiple: false
  })

  return (
    <>
      <button
        className="w-full grid grid-cols-[1fr,auto] items-center gap-10 p-10 pl-16 bg-grey-light-2 dark:bg-grey-med-2 rounded-6"
        {...getRootProps()}
      >
        <Wrapper open={!link}>
          <input {...getInputProps()} />
        </Wrapper>
        <div className="w-full grid grid-cols-1">
          <div className="w-full text-black dark:text-white text-16 text-left">{title}</div>
          <div className="w-full text-grey-light text-14 text-left">{text}</div>
        </div>
        {file || link ? (
          <div className="h-48 rounded-6 bg-green px-16 flex items-center gap-8">
            <MdCheck className="text-black dark:text-white text-20" />
            <div className="text-black dark:text-white text-16">Uploaded</div>
          </div>
        ) : (
          <div className="h-48 rounded-6 bg-purple px-16 flex items-center gap-8">
            {isDragActive ? (
              <>
                <MdCloudUpload className="text-black dark:text-white text-20" />
                <div className="text-black dark:text-white text-16">Drop</div>
              </>
            ) : (
              <>
                <MdCloudUpload className="text-black dark:text-white text-20" />
                <div className="text-black dark:text-white text-16">Upload</div>
              </>
            )}
          </div>
        )}
      </button>
    </>
  )
}

export default AccountDropZone
