import AccountLine from "components/account/elements/AccountLine"
import AccountTitle from "components/account/elements/AccountTitle"
import ProvablyText from "components/provably/elements/ProvablyText"
import React from "react"

const ProvablyRandom = () => {
  return (
    <>
      <div className="w-full">
        <AccountTitle title={"RANDOM.ORG Signature Commitment"} />
        <ProvablyText
          text={
            <>
              We populate the User Data field when requesting the 3rd Party Seed
              with the Game ID and the Server Seed Hashto prove we specified
              this data before the server saw the random values generated.
              <br />
              <br />
              We publish every request we make to the RANDOM.ORG service so you
              can review that there are no duplicate values of our User Data
              field.
              <br />
              <br />
              You can read more about RANDOM.ORG&apos;s signatures at
              api.random.org/signatures.
            </>
          }
        />
        <AccountLine />
        <AccountTitle title={"RANDOM.ORG Signature Verification"} />
        <ProvablyText
          text={
            <>
              You can verify a game&apos;s RANDOM.ORG random and signature
              values by clicking the completed game&apos;s button.
              <br />
              <br /> Points of interest are the Random Values Generated
              corresponding with the 3rd Party Seed, the User Data for signature
              commitment, and the Hashed API Key to confirm we are not using
              multiple API keys for a single game mode.
            </>
          }
        />
      </div>
    </>
  )
}

export default ProvablyRandom
