import { onSoftswissReq, onToggleFavoriteGame } from "api/gamesApi"
import { ReactComponent as Fire } from "assets/img/fire.svg"
import Bets from "components/bets/Bets"
import CinemaModeButton from "components/buttons/CinemaModeButton"
import FullScreenButton from "components/buttons/FullScreenButton"
import StarButton from "components/buttons/StarButton"
import Footer from "components/footer/Footer"
import HomeSection from "components/home/HomeSection"
import Nav from "components/nav/Nav"
import { useProps } from "contexts/PropsContext"
import useWindowDimensions from "hooks/useWindowDimensions"
import { REQ, URL } from "libs/constants"
import { GameInterface } from "libs/interfaces"
import React, { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Toggle from "utils/elements/Toggle"
interface Params {
  slug: string,
  demo: string
}

const Game = () => {
  const { authenticated, coin, cinemaMode, onCinemaModeChange } = useProps()
  const { slug, demo } = useParams<Params>()
  const { height, width } = useWindowDimensions()
  const handle = useFullScreenHandle()
  const { t } = useTranslation(['landing'])

  const history = useHistory()

  const [starred, setStarred] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const [isLive] = useState(demo === "live")

  const [game, setGame] = useState<GameInterface>()
  const [url, setUrl] = useState<string>("")

  useEffect(() => {
    onLoad()
  }, [coin])

  useEffect(() => {
    setStarred(game?.is_favourite || false)
  }, [game])

  const onLoad = async () => {
    let status = null
    if (isLive) {
      if (!authenticated) {
        history.push("/")
        toast.error(t("logged_to_play"))
        return
      }

      status = await onSoftswissReq(REQ.SLOTS.START, { game: slug, coin: coin })
    } else {
      status = await onSoftswissReq(REQ.SLOTS.DEMO, { game: slug })
    }

    if (status) {
      setUrl(status.launch_options.game_url)
      setGame(status.game)
    }
  }

  const onToggleFavorite = async (value: boolean) => {
    if (game) {
      const result = await onToggleFavoriteGame(slug)
      if (result) {
        setStarred(value)
      }
    }
  }

  const onFullSCreenChange = (value: boolean) => {
    if (value) {
      handle.enter()
      return setFullScreen(value)
    }
    handle.exit()
    return setFullScreen(value)
  }

  const getHeightSize = () => {
    if (fullScreen) {
      return "w-full h-920"
    } else if (cinemaMode) {
      return "w-full h-850"
    }
  }

  const onToggleDemoGame = () => {
    history.push(URL.GAME.replace(":slug", slug).replace(":demo", "demo"))
    location.reload()
  }

  const onToggleLiveGame = () => {
    history.push(URL.GAME.replace(":slug", slug).replace(":demo", "live"))
    location.reload()
  }

  const onToggleDemoLiveGame = (value: boolean) => {
    if (authenticated) {
      if (value) {
        return onToggleLiveGame()
      }
      return onToggleDemoGame()
    }
    if (value) {
      return toast.error(t("logged_to_play"))
    }
    return onToggleDemoGame()
  }

  const onChangeFullScreen = (value: boolean) => {
    if (!value) {
      handle.exit()
      return setFullScreen(value)
    }
  }

  const onGetGameProvider = (value: string) => {
    switch (value) {
      case "pragmaticexternal":
        return "Pragmatic Play"
      case "softswiss":
        return "BGaming"
      default:
        return value
    }
  }

  return (
    <>
      {!isMobile ? (
        <div className="mt-70">
          <Nav game={true} />
          <div className={`w-full flex justify-center items-center`}>
            <div className={`w-full grid grid-cols-1 gap-24 ${!cinemaMode && !fullScreen && "py-16 max-w-screen-xl"}`}>
              <FullScreen handle={handle} onChange={onChangeFullScreen} className={`w-full p-16 bg-grey-light-3 dark:bg-grey-med rounded-10 grid grid-cols-1 gap-12`}>
                <div className={`w-full rounded-10 bg-grey-light-2 dark:bg-grey-med-2 ${!cinemaMode && !fullScreen && "aspect-w-16 aspect-h-9"}`}>
                    <iframe src={url} allowFullScreen className={`rounded-6 ${getHeightSize()}`}></iframe>
                </div>
                {game && (
                    <div className="w-full flex justify-between gap-12 items-center">
                        <div className="grid grid-cols-1">
                            <div className="w-full text-black dark:text-white text-16">{game.title}</div>
                            <div className="w-full text-grey-light text-14 capitalize">
                            {onGetGameProvider(game.provider)}
                            </div>
                        </div>
                        <div className="flex items-center justify-items-center gap-12">
                          <div className="text-16 text-black dark:text-grey-light bold">{t("demo")}</div>
                          <Toggle value={isLive} handler={onToggleDemoLiveGame} toggleColor={"bg-purple"}/>
                          <div className="text-16 text-black dark:text-grey-light bold">{t("live")}</div>
                          <StarButton value={starred} handler={onToggleFavorite}/>
                          {!fullScreen && <CinemaModeButton value={cinemaMode} handler={onCinemaModeChange}/>}
                          <FullScreenButton value={fullScreen} handler={onFullSCreenChange}/>
                        </div>
                    </div>
                )}
              </FullScreen>
              <div className="w-full flex justify-center p-24">
                  <div className="w-full grid grid-cols-1 gap-24 max-w-screen-xl">
                      <HomeSection
                          title={t("popular_games")}
                          icon={<Fire className="text-24" />}
                          category={"popular"}
                      />
                      <Bets />
                  </div>
              </div>
            </div>
          </div>
        <Footer />
        </div>
      ) : (
        <iframe src={url} allowFullScreen height={height} width={width}></iframe>
      )}
    </>
  )
}

export default Game
