import { parseBalance, parseFiat } from "api/balance"
import { parseError } from "api/error"
import axios from "axios"
import AccountTable from "components/account/AccountTable"
import { useProps } from "contexts/PropsContext"
import { DATE, REQ } from "libs/constants"
import { tableColumnsTransactions, tableSorting } from "libs/data"
import { parseCoin } from "libs/functions"
import {
  TableColumnInterface,
  TableDataInterface,
  TableSortingInterface,
  TransactionInterface
} from "libs/interfaces"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { FaBtc } from "react-icons/fa"
import { SiEthereum, SiLitecoin } from "react-icons/si"

const AccountTransactions = ({ type }: { type: string }) => {
  const { crypto, fiat } = useProps()

  const [count, setCount] = useState(10)

  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)

  const [data, setData] = useState<TableDataInterface[][]>([])

  const [columns] = useState<TableColumnInterface[]>(tableColumnsTransactions)

  useEffect(() => {
    onLoad(count, page)
  }, [page, fiat])

  const onCountChange = (countValue: number) => {
    setCount(countValue)
    setPage(1)
    onLoad(countValue, 1)
  }

  const onCryptoIcon = (coin: string) => {
    switch (coin) {
      case "ETH":
        return <SiEthereum className="text-purple text-16" />
      case "BTC":
        return <FaBtc className="text-orange-2 text-16" />
      case "LTC":
        return <SiLitecoin className="text-grey-light text-16" />
      default:
        return <></>
    }
  }

  const onLoad = async (countValue: number, pageValue: number) => {
    const res = await axios({
      url: REQ.USER.TRANSACTIONS + `?type=${type}&limit=${countValue}&page=${pageValue}`,
      method: "get",
      data: {},
      withCredentials: true
    })
      .then((res: any) => res.data.response)
      .catch((error) => {
        parseError(error)
      })

    if (res) {
      setPages(res.totalPages)

      const typed: TransactionInterface[] = res.data.map((bet: any) => ({
        ...bet,
        amount: parseBalance(
          type === "WITHDRAWAL"
            ? -1 * parseInt(bet.amount)
            : parseInt(bet.amount)
        )
      }))

      setData(
        typed.map((transaction: TransactionInterface) => [
          {
            text: parseCoin(transaction.coin),
            color: "text-black dark:text-white"
          },
          {
            text: `${fiat ? "$" : ""}${parseFiat(
              crypto,
              transaction.coin,
              fiat,
              transaction.amount
            )}`,
            icon: fiat ? <></> : onCryptoIcon(transaction.coin)
          },
          {
            text: `${transaction.status}`
          },
          {
            text: moment(transaction.created_at).format(DATE.DATETIME)
          }
        ])
      )
    }
  }

  const [sorted, setSorted] = useState<TableSortingInterface>()

  const [sorting] = useState<TableSortingInterface[]>(tableSorting)

  return (
    <>
      <AccountTable
        columns={columns}
        data={data}
        sorted={sorted}
        sorting={sorting}
        handlerSort={setSorted}
        count={count}
        handlerCount={onCountChange}
        page={page}
        handlerPage={setPage}
        pages={pages}
      />
    </>
  )
}

export default AccountTransactions
