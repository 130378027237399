import React from "react"
import { useTranslation } from "react-i18next"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"

const HomeSectionTop = ({
  title,
  icon,
  moreGames,
  slides,
  handleMoreGames,
  handlerNext,
  handlerPrevious,
  hideSeeMore
}: {
  title: string
  icon: JSX.Element
  moreGames: boolean
  slides?: number
  handleMoreGames: any
  handlerNext?: any
  handlerPrevious?: any
  hideSeeMore?: boolean
}) => {
  const { t } = useTranslation(['landing'])

  return (
    <>
      <div className={`w-full ${!hideSeeMore ? "grid grid-cols-1 md:" : ""}flex items-center justify-between gap-10 md:gap-24`}>
        <div className="w-full md:w-auto flex items-center gap-12">
          {icon}
          <div className="text-24 text-black dark:text-white font-bold">{title}</div>
        </div>
        <div
          className={`w-full md:w-auto items-center gap-12 ${
            slides && slides > 1 ? `${!hideSeeMore ? "grid grid-cols-[auto,auto,1fr]" : "flex justify-end items-center" }` : "grid-cols-1"
          }`}
        >
          {slides && slides > 1 && handlerPrevious ? (
            <button
              onClick={() => handlerPrevious()}
              className="w-40 h-40 rounded-6 flex items-center justify-center bg-grey-light-3 dark:bg-grey-med group"
            >
              <MdKeyboardArrowLeft className="text-grey-light group-hover:text-black dark:group-hover:text-white" />
            </button>
          ) : (
            ""
          )}
          {slides && slides > 1 && handlerNext ? (
            <button
              onClick={() => handlerNext()}
              className="w-40 h-40 rounded-6 flex items-center justify-center bg-grey-light-3 dark:bg-grey-med group"
            >
              <MdKeyboardArrowRight className="text-grey-light group-hover:text-black dark:group-hover:text-white" />
            </button>
          ) : (
            ""
          )}
          {!hideSeeMore && slides && slides > 1 && handleMoreGames ? (
            <button
              onClick={() => handleMoreGames(!moreGames)}
              className="w-full px-16 h-40 rounded-6 flex items-center justify-center bg-grey-light-3 dark:bg-grey-med text-14 font-bold text-grey-light hover:text-black dark:hover:text-white"
            >
              {t("see", {value: moreGames ? "less" : "more"})}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  )
}

export default HomeSectionTop
