import React from "react"
import { isMobile } from "react-device-detect"
import { MdInfo } from "react-icons/md"

const TextPopup = ({ value }: { value: string }) => {
  return (
    <div className="relative group">
        <MdInfo className="text-grey-light hover:text-black dark:hover:text-white text-18 relative" />
        <div className="p-12 group-hover:flex hidden transition-all duration-300 absolute left-24 bottom-6 group bg-grey-light-2 dark:bg-grey-med-2 rounded-6">
            <div className={`text-black dark:text-white ${isMobile ? "w-[150px]" : "w-[400px]"} text-14`}>{value}</div>
        </div>
    </div>
  )
}

export default TextPopup
