import AccountLine from "components/account/elements/AccountLine"
import AccountMenuLink from "components/account/elements/AccountMenuLink"
import AccountTitle from "components/account/elements/AccountTitle"
import { URL } from "libs/constants"
import { AccountTabInterface } from "libs/interfaces"
import React, { useState } from "react"
import {
  MdLock,
  MdOutlineDownload,
  MdOutlineGamepad,
  MdOutlineShield,
  MdOutlineUpload,
  MdShield
} from "react-icons/md"

const AccountMenu = () => {
  const [settings] = useState<AccountTabInterface[]>([
    {
      title: "General",
      link: URL.ACCOUNT.TAB.replace("/:tab", ""),
      icon: <MdOutlineShield />
    },
    {
      title: "Security",
      link: URL.ACCOUNT.TAB.replace(":tab", "security"),
      icon: <MdShield />
    },
    {
      title: "Privacy",
      link: URL.ACCOUNT.TAB.replace(":tab", "privacy"),
      icon: <MdLock />
    }
    // {
    //   title: "Verification",
    //   link: URL.ACCOUNT.TAB.replace(":tab", "verification"),
    //   icon: <MdOutlineCheckCircle />
    // }
  ])

  const [history] = useState<AccountTabInterface[]>([
    {
      title: "Bets",
      link: URL.ACCOUNT.TAB.replace(":tab", "bets"),
      icon: <MdOutlineGamepad />
    },
    {
      title: "Deposits",
      link: URL.ACCOUNT.TAB.replace(":tab", "deposits"),
      icon: <MdOutlineDownload />
    },
    {
      title: "Withdrawals",
      link: URL.ACCOUNT.TAB.replace(":tab", "withdrawals"),
      icon: <MdOutlineUpload />
    }
  ])

  return (
    <>
      <div className="w-full bg-grey-light-3 dark:bg-grey-med rounded-10 py-20 sm:py-24 grid-cols-1">
        <div className="w-full grid grid-cols-1">
          <div className="w-full px-20 sm:px-24">
            <AccountTitle title={"Settings"} />
          </div>
          {settings.map((item: AccountTabInterface, key: number) => (
            <AccountMenuLink link={item} key={key} />
          ))}
        </div>
        <div className="w-full grid grid-cols-1">
          <div className="w-full px-20 sm:px-24">
            <AccountLine />
            <AccountTitle title={"History"} />
          </div>
          {history.map((item: AccountTabInterface, key: number) => (
            <AccountMenuLink link={item} key={key} />
          ))}
        </div>
      </div>
    </>
  )
}

export default AccountMenu
