import Footer from "components/footer/Footer"
import Nav from "components/nav/Nav"
import WrapperPage from "components/wrapper/WrapperPage"
import { openLink } from "libs/functions"
import { AnnouncmentDataInterface } from "libs/interfaces"
import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"

const Announcement = () => {
  const history = useHistory()
  const location = useLocation<AnnouncmentDataInterface>()
  const [data, setData] = useState<AnnouncmentDataInterface>()

  useEffect(() => {
    if (location?.state !== undefined) {
      setData(location?.state)
    }
  }, [location])

  const onButtonClick = (e: any) => {
    if (data) {
      if (data?.handlerRedirect) {
        history.push(data?.handlerRedirect)
      } else {
        openLink(e, data?.handlerLink)
      }
    }
  }

  return (
    <>
      <Nav />
        <WrapperPage>
          {data ? <div className="w-full grid grid-cols-1 gap-24">
            <img src={data?.banner} alt="" className="w-full rounded-24"/>
            <div className="grid grid-cols-1 items-start gap-24 bg-grey-light-3 dark:bg-grey-med p-36 rounded-10">
              <div className="w-full text-18 text-black dark:text-white uppercase">{data?.header}</div>
              <div className="w-full text-16 text-black dark:text-white">{data?.text}</div>
              <div className="w-full">
                <button
                  onClick={(e) => onButtonClick(e)}
                  className="bg-purple rounded-6 px-12 text-black dark:text-white h-48 font-bold"
                  >
                  {data?.handlerText}
                </button>
              </div>
            </div>
          </div> : ""}
        </WrapperPage>
      <Footer />
    </>
  )
}

export default Announcement
