import { ReactComponent as Blackjack } from "assets/img/categories/blackjack.svg"
import { ReactComponent as Poker } from "assets/img/categories/poker.svg"
import { ReactComponent as Popular } from "assets/img/categories/popular.svg"
import { ReactComponent as Roulette } from "assets/img/categories/roulette.svg"
import { ReactComponent as Slot } from "assets/img/categories/slot.svg"
import { ReactComponent as New } from "assets/img/new.svg"
import { CategoryInterface } from "libs/interfaces"
import React, { useEffect, useState } from "react"

const HomeCategory = ({ tab }: { tab: string }) => {
  const [categoryStyles] = useState("fill-current text-grey-light")

  const [categories] = useState<CategoryInterface[]>([
    {
      identifier: "roulette",
      title: "Roulette",
      icon: <Roulette className={categoryStyles} />
    },
    {
      identifier: "slots",
      title: "Slots",
      icon: <Slot className={categoryStyles} />
    },
    {
      identifier: "card",
      title: "Blackjack",
      icon: <Blackjack className={categoryStyles} />
    },
    {
      identifier: "poker",
      title: "Poker",
      icon: <Poker className={categoryStyles} />
    },
    {
      identifier: "new",
      title: "New games",
      icon: <New className={categoryStyles} />
    },
    {
      identifier: "popular",
      title: "Popular games",
      icon: <Popular className={categoryStyles} />
    }
  ])

  const [category, setCategory] = useState<CategoryInterface>()

  useEffect(() => {
    setCategory(categories.filter((category) => category.identifier === tab)[0])
  }, [tab])

  return (
    <>
      {category ? (
        <div className="w-full md:w-auto flex items-center gap-12">
          {category.icon}
          <div className="text-24 text-black dark:text-white font-bold">{category.title}</div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default HomeCategory
