import { parseError } from "api/error"
import axios from "axios"
import Modal from "components/modals/Modal"
import ModalClose from "components/modals/ModalClose"
import { REQ } from "libs/constants"
import React, { useState } from "react"
import { MdOutlineVpnKey } from "react-icons/md"
import { toast } from "react-toastify"
import InputForm from "utils/inputs/InputForm"

const ModalReset = ({
  open,
  handler,
  handlerSent
}: {
  open: boolean
  handler: any
  handlerSent: any
}) => {
  const [email, setEmail] = useState("")

  const onEnter = (key: string) => {
    if (key === "Enter") {
      onReset()
    }
  }

  const onReset = async () => {
    if (!email) {
      toast.error("Enter a email")
      return
    }

    axios({
      url: REQ.AUTH.FORGOT,
      method: "post",
      data: { email: email },
      withCredentials: true
    })
      .then((res: any) => res.data)
      .then(() => {
        handler(false)
        handlerSent(true)
      })
      .catch((error) => {
        parseError(error)
      })
  }

  return (
    <>
      <Modal open={open} handler={handler}>
        <div className="bg-grey-light-3 dark:bg-grey-med rounded-10 p-24 grid grid-cols-1 gap-24 w-[450px] max-w-full relative">
          <div className="w-full flex justify-center">
            <div className="w-60 h-60 rounded-full bg-green-10 flex items-center justify-center">
              <MdOutlineVpnKey className="text-green text-24" />
            </div>
          </div>
          <div className="w-full grid grid-cols-1 gap-12">
            <div className="w-full text-16 sm:text-18 text-black dark:text-white text-center">
              Request password reset
            </div>
            <div className="w-full text-14 text-grey-light text-center">
              Enter your email below and we will send you a link to reset your
              password.
            </div>
          </div>
          <InputForm
            title={"Email"}
            placeholder={"Enter email"}
            value={email}
            handler={setEmail}
            onEnter={onEnter}
          />
          <button
            onClick={() => onReset()}
            className="w-full h-48 bg-green rounded-6 flex items-center justify-center text-black dark:text-white text-14 sm:text-16"
          >
            Request password reset
          </button>
          <ModalClose open={open} handler={handler} />
        </div>
      </Modal>
    </>
  )
}

export default ModalReset
