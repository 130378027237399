import Wrapper from "components/wrapper/Wrapper"
import { URL } from "libs/constants"
import React from "react"
import { Link } from "react-router-dom"

const InputForm = ({
  title,
  placeholder,
  value,
  handler,
  password,
  onEnter
}: {
  title: string
  placeholder: string
  value: string
  handler: any
  password?: string
  onEnter: any
}) => {
  return (
    <>
      <div className="w-full grid grid-cols-1 gap-10">
        <div className="w-full flex items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <span className="text-16 font-bold text-grey-light">{title}</span>
            <span className="text-red text-16 font-bold">*</span>
          </div>
          <Wrapper open={password ? true : false}>
            <Link
              to={URL.PASSWORD.EMAIL}
              className="text-16 font-bold text-grey-light hover:text-black dark:hover:text-white"
            >
              {password}
            </Link>
          </Wrapper>
        </div>
        <div className="w-full flex items-center rounded-6 bg-grey-light-2 dark:bg-grey-med-2 h-[52px]">
          <input
            type="text"
            className="w-full text-black dark:text-white px-16 rounded-6 leading-[52px] placeholder-grey-light text-16 font-bold"
            placeholder={placeholder}
            value={value}
            onChange={(e) => handler(e.target.value)}
            onKeyUp={(e) => (onEnter ? onEnter(e.key) : null)}
          />
        </div>
      </div>
    </>
  )
}

export default InputForm
