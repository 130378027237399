import React from "react"

type UserAvatar = {
  avatar_url?: string
  name?: string
}

const UserAvatarIcon = ({ user }: { user: UserAvatar }) => {
  return (
    <div className={`w-32 h-32 rounded-full overflow-hidden bg-grey-light-0 flex items-center justify-center`}>
      {user.avatar_url ? (
        <img src={user.avatar_url} />
      ) : (
        <div className={`text-black dark:text-white text-16 font-bold uppercase`}>
          {user?.name && user.name[0]}
        </div>
      )}
    </div>
  )
}

export default UserAvatarIcon
